import HttpClient from "../../utils/HttpClient";

export default class BeaconRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getAllBeaconsByTenant(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/beacons";
    return this.instance.get(path + (queryParams ?? ""));
  }

  getAllBeaconsFiltered(data: any) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/beacons";
    return this.instance.get(path + (data.queryParams ?? ""));
  }

  getAllBeaconsByTenantAndEntity(data: any) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/beacons?entityId=" +
      data.entityId +
      "&entityType=" +
      data.entityType;
    return this.instance.get(path + (data.queryParams ?? ""));
  }

  updateBeacons(data: any) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/beacons/entities/" +
      data.entityId +
      "/association";
    return this.instance.post(path, data.beaconAssociationListRequest);
  }
}
