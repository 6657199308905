import { Loader } from "@googlemaps/js-api-loader";
import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import SearchField from "../../ui/Forms/SearchField";
import { SingleDatePicker } from "../../ui/Forms/SingleDatePicker";
import TextField from "../../ui/Forms/TextField";
import { IconForward } from "../../ui/Icon/Line/Forward";
import { IconUpload } from "../../ui/Icon/Line/Upload";
import { DataCommon } from "../../utils/Common";
import { ToastNotification } from "../../utils/ToastNotification";
import { autoCompleteAddressFields } from "../../utils/Utils";
import { UploadImage } from "../components/UploadImage";
import {
  FleetView,
  fleetViewsEmptyState,
  fleetViewsSelectors,
  getFleetViewsAsync,
  selectFleetViewsSliceReasonCode,
  selectFleetViewsSliceStatus,
} from "../fleet/fleetViewsSlice";
import {
  Preferences,
  selectPreferencesSliceReasonCode,
  selectPreferencesSliceStatus,
} from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  getVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./AdminDriverManagement.css";

import { AddressInfo } from "./CreateDriver";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  getDriverDetailsAsync,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
  updateDriverAsync,
} from "./driversSlice";

interface EditDriverProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

const EditDriver: React.FC<EditDriverProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const driverRoute = useMatch("/admin/drivers/edit/:driverId");
  const [removedVehicle, setRemovedVehicle] = useState<boolean>(false);

  const [editDriver, setEditDriver] = useState<Driver>({} as Driver);
  const [openModalAvatar, setOpenModalAvatar] = useState(false);
  const [id, setId] = useState<number>(-1);
  const [disabledFields, setDisabledFields] = useState(true);
  const [errorList, setErrorList] = useState([]);
  const [addressInfoOnAutoComplete, setAddressInfoOnAutoComplete] =
    useState<AddressInfo>({} as AddressInfo);

  let driver: Driver =
    useAppSelector((state) => driversSelectors.selectById(state, id)) ??
    ({} as Driver);
  const vehicle: Vehicle =
    useAppSelector((state) =>
      vehiclesSelectors.selectById(
        state,
        editDriver?.vehicle ?? driver.vehicle ?? -1
      )
    ) ?? ({} as Vehicle);
  let vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
  let fleetViews: FleetView[] = useAppSelector(fleetViewsSelectors.selectAll);
  let fleetView: FleetView =
    useAppSelector((state) =>
      fleetViewsSelectors.selectById(state, editDriver?.fleet ?? driver.fleet)
    ) ?? ({} as FleetView);

  document.title = useMemo(() => {
    if (!!driver?.firstName && !!driver?.lastName) {
      return (
        `${driver?.firstName} ${driver?.lastName} - ` +
        t("navigation.adminMenu.drivers") +
        " - Admin"
      );
    } else {
      return t("navigation.adminMenu.drivers") + " - Admin";
    }
  }, [driver]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);
  const licenseTypeList: DataCommonProps[] =
    DataCommon()?.licenseTypeList ?? ([] as DataCommonProps[]);

  const driverSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const driverSliceStatus = useAppSelector(selectDriversSliceStatus);
  const fleetsSliceStatus = useAppSelector(selectFleetViewsSliceStatus);
  const fleetsSliceReasonCode = useAppSelector(selectFleetViewsSliceReasonCode);
  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);
  const preferencesSliceStatus = useAppSelector(selectPreferencesSliceStatus);
  const preferencesSliceReasonCode = useAppSelector(
    selectPreferencesSliceReasonCode
  );

  /**
   * This useEffect handles the updating of the address info once the user
   * select an address from the autocompletion functionality.
   * It solves a bug. The callback function of the autocompletion
   * does not keep memory of the state of the editing/creating driver.
   */
  useEffect(() => {
    if (!_.isEmpty(addressInfoOnAutoComplete)) {
      setEditDriver({
        ...editDriver,
        address: addressInfoOnAutoComplete.address,
        city: addressInfoOnAutoComplete.city,
        province: addressInfoOnAutoComplete.province,
        zip: addressInfoOnAutoComplete.zip,
        country: addressInfoOnAutoComplete.country,
      });
    }
  }, [addressInfoOnAutoComplete]);

  useEffect(() => {
    document.title = t("navigation.adminMenu.drivers") + " - Admin";
    const loader = new Loader({
      apiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      libraries: ["places", "drawing", "geometry"],
      version: "beta",
    });
    loader.load().then();
    return () => {
      loader.deleteScript();
      store.dispatch(driversEmptyState());
      store.dispatch(fleetViewsEmptyState());
      store.dispatch(vehiclesEmptyState());
    };
  }, []);

  useEffect(() => {
    // get vehicle id and fleet name
    if (driverRoute !== null && driverRoute.params.driverId) {
      setId(parseInt(driverRoute.params.driverId));
      store.dispatch(
        getDriverDetailsAsync(parseInt(driverRoute.params.driverId))
      );
      store.dispatch(getFleetViewsAsync());
      store.dispatch(getVehiclesDetailsAsync());
    } else {
      ToastNotification({
        toastId: "unauthorizedError",
        status: "error",
        description: t("common.unauthorizedError"),
      });
      navigate("/admin/drivers");
    }
  }, [navigate, driverRoute]);

  useEffect(() => {
    if (vehiclesSliceStatus === "failed" && vehiclesSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [navigate, vehiclesSliceStatus, vehiclesSliceReasonCode]);

  useEffect(() => {
    if (fleetsSliceStatus === "failed" && fleetsSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [fleetsSliceStatus, fleetsSliceReasonCode]);

  useEffect(() => {
    if (
      driverSliceStatus === "idle" &&
      driverSliceReasonCode === GTFleetSuccessCodes.PATCH
    ) {
      ToastNotification({
        toastId: "driverUpdateSuccess",
        status: "success",
        description: t("common.driverUpdateSuccess"),
      });
      navigate("/admin/drivers/view/" + id);
    } else if (driverSliceStatus === "failed") {
      switch (driverSliceReasonCode) {
        case GTFleetErrorCodes.DRIVER_NOT_FOUND:
          ToastNotification({
            toastId: "driverNotFoundError",
            status: "error",
            description: t("common.driverNotFoundError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/drivers");
    }
  }, [driverSliceStatus, driverSliceReasonCode, navigate, id]);

  useEffect(() => {
    if (
      preferencesSliceStatus === "failed" &&
      preferencesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [preferencesSliceStatus, preferencesSliceReasonCode]);

  return (
    <>
      {!!driver && !_.isEmpty(driver) && (
        <PageFilters>
          <div className="col col-16">
            <div className="drvr-subtopbar">
              <div className="drvr-subsection-name">
                {t("admin.driver.editViewCreate.editAccount")}
              </div>
              <div className="drvr-buttons">
                <Button
                  size="small"
                  aspect="secondary"
                  label={t("common.cancel")}
                  onClick={() => navigate(-1)}
                />
                <Button
                  size="small"
                  aspect="primary"
                  disabled={
                    errorList.length !== 0 ||
                    Object.values(editDriver).length === 0
                  }
                  label={t("common.save")}
                  onClick={() => {
                    Object.values(editDriver).length > 0 &&
                      store.dispatch(
                        updateDriverAsync({
                          id,
                          removedVehicle,
                          driver: editDriver,
                        })
                      );
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </PageFilters>
      )}
      <PageContent>
        <div className="drvr-page-container">
          {/* General */}
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.general")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <div className="drvr-left-forms-label">{t("common.name")} </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <TextField
                        id="Name"
                        validate="alphabetic"
                        placeholder={
                          editDriver?.firstName || driver.firstName
                            ? t("common.name")
                            : t("common.na")
                        }
                        value={
                          editDriver?.firstName ?? driver.firstName ?? null
                        }
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              firstName: data.value,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("common.surname")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="Surname"
                        validate="alphabetic"
                        placeholder={
                          editDriver?.lastName || driver.lastName
                            ? t("common.surname")
                            : t("common.na")
                        }
                        value={editDriver?.lastName ?? driver.lastName ?? null}
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              lastName: data.value,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">{t("common.address")}</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <SearchField
                      id="Address"
                      placeholder={
                        editDriver.address || driver.address
                          ? t("common.address")
                          : t("common.na")
                      }
                      onChange={(data) => {
                        setEditDriver({
                          ...editDriver,
                          address: data,
                        });
                      }}
                      value={editDriver.address ?? driver.address}
                      searchAddress={true}
                      hasSearchIcon={true}
                      onAddressClick={(data) => {
                        const addressInfo = autoCompleteAddressFields(
                          data,
                          setDisabledFields
                        );
                        setAddressInfoOnAutoComplete(addressInfo);
                      }}
                      autocompleteOptions={{
                        types: ["route"],
                        componentRestrictions: { country: "it" },
                      }}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <Form>
                  <div className="drvr-left-forms-label">
                    {t("common.city")}
                  </div>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="City"
                        validate=""
                        placeholder={t("common.city")}
                        value={editDriver.city ?? driver.city ?? null}
                        events={{
                          onChange: (data) => {
                            setEditDriver({
                              ...editDriver,
                              city: data.value ?? driver.city,
                            });
                          },
                        }}
                        errorList={setErrorList}
                        autoComplete="off"
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("common.province")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="Province"
                        validate="alphabetic"
                        placeholder={
                          editDriver.province || driver.province
                            ? t("common.province")
                            : t("common.na")
                        }
                        value={
                          (editDriver.province &&
                            editDriver.province.toUpperCase()) ??
                          (driver.province && driver.province.toUpperCase()) ??
                          ""
                        }
                        events={{
                          onChange: (data) => {
                            setEditDriver({
                              ...editDriver,
                              province: data.value ?? driver.province,
                            });
                          },
                        }}
                        errorList={setErrorList}
                        maxLength="2"
                        autoComplete="off"
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <div className="drvr-left-forms-label">{t("common.zip")}</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <TextField
                        id="Cap"
                        validate="numeric"
                        placeholder={
                          editDriver?.zip || driver.zip
                            ? t("common.zip")
                            : t("common.na")
                        }
                        value={editDriver.zip ?? driver.zip ?? null}
                        events={{
                          onChange: (data) => {
                            setEditDriver({
                              ...editDriver,
                              zip: data.value ?? driver.zip,
                            });
                          },
                        }}
                        errorList={setErrorList}
                        maxLength="5"
                        autoComplete="off"
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("common.country")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="Country"
                        validate="alphabetic"
                        placeholder={
                          editDriver.country ?? driver.country
                            ? t("common.country")
                            : t("common.na")
                        }
                        events={{
                          onChange: (data) => {
                            setEditDriver({
                              ...editDriver,
                              country: data.value ?? driver.country,
                            });
                          },
                        }}
                        value={
                          (editDriver.country &&
                            editDriver.country.toUpperCase()) ??
                          (driver.country && driver.country.toUpperCase()) ??
                          ""
                        }
                        errorList={setErrorList}
                        autoComplete="off"
                        disabled={disabledFields}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">{t("common.email")} *</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-text-field">
                    <TextField
                      id="driver-email"
                      validate="email|isNotEmpty"
                      type="email"
                      placeholder={
                        editDriver?.email || driver.email
                          ? t("common.email")
                          : t("common.na")
                      }
                      value={editDriver?.email ?? driver.email ?? null}
                      errorList={setErrorList}
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            email: data.value,
                          }),
                      }}
                    />
                  </div>
                  <div className="drvr-validate-button">
                    <Button
                      size="regular"
                      aspect="primary"
                      disabled={true}
                      label={t("common.validate")}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">{t("common.phone")}</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-phone">
                    <div className="drvr-drpdwn-small">
                      <Dropdown
                        isClearable={false}
                        placeholder={
                          editDriver.phoneNumberPrefix ??
                          driver.phoneNumberPrefix
                            ? t("common.phone")
                            : t("common.na")
                        }
                        value={{
                          label:
                            editDriver.phoneNumberPrefix ??
                            driver.phoneNumberPrefix ??
                            undefined,
                        }}
                        size="normal"
                        itemAttribute="label"
                        onChange={(val) => {
                          setEditDriver({
                            ...editDriver,
                            phoneNumberPrefix: val[0].label,
                          });
                        }}
                        options={prefixList}
                      />
                    </div>
                  </div>
                  <div className="drvr-right-form-phone">
                    <TextField
                      id="Number"
                      validate="numeric"
                      placeholder={
                        editDriver.phoneNumber || driver.phoneNumber
                          ? t("common.phone")
                          : t("common.na")
                      }
                      errorList={setErrorList}
                      value={
                        editDriver.phoneNumber ?? driver.phoneNumber ?? null
                      }
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            phoneNumber: data.value,
                          }),
                      }}
                      maxLength="10"
                      minLength="9"
                    />
                  </div>
                  <div className="drvr-validate-button">
                    <Button
                      size="regular"
                      aspect="primary"
                      disabled={true}
                      label={t("common.validate")}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {/* Personal info */}
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.personalInfo")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <div className="drvr-left-forms-label">
                  {t("common.dateOfBirth")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <SingleDatePicker
                        id="BirthDate"
                        validation=""
                        setErrorList={setErrorList}
                        errorList={errorList}
                        dateRange={[dayjs().subtract(100, "year"), dayjs()]}
                        oldDate={driver.dateOfBirth}
                        setDate={(data) => {
                          setEditDriver({
                            ...editDriver,
                            dateOfBirth: data ?? driver.dateOfBirth,
                          });
                        }}
                        placeholder={
                          driver.dateOfBirth || editDriver?.dateOfBirth
                            ? t("common.dateOfBirth")
                            : t("common.na")
                        }
                        localeFormat={
                          preferencesContext.localeFormat ?? "DD / MM / YYYY"
                        }
                        language={preferencesContext.language ?? "it"}
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("common.fiscalCode")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <TextField
                        id="fiscalCode"
                        validate="fiscalCode"
                        placeholder={
                          editDriver?.fiscalCode || driver.fiscalCode
                            ? t("common.fiscalCode")
                            : t("common.na")
                        }
                        value={
                          editDriver?.fiscalCode ?? driver.fiscalCode ?? null
                        }
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              fiscalCode: data.value,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">
                {t("admin.driver.editViewCreate.avatar")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="left-form">
                    <Button
                      aspect="secondary"
                      size="regular"
                      label={
                        editDriver?.avatar?.split("/").pop() ??
                        driver.avatar?.split("/").pop() ??
                        t("common.upload")
                      }
                      onClick={() => {
                        setOpenModalAvatar(true);
                      }}
                    >
                      <IconUpload className="" size={14} color="#687484" />
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {/* Driver License */}
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.driveLicense")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-half-form">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.licenseId")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item">
                      <TextField
                        id="licenseId"
                        validate="alphaNumeric"
                        placeholder={
                          editDriver?.licenseId || driver.licenseId
                            ? t("admin.driver.editViewCreate.licenseId")
                            : t("common.na")
                        }
                        value={
                          editDriver?.licenseId ?? driver.licenseId ?? null
                        }
                        errorList={setErrorList}
                        events={{
                          onChange: (data) =>
                            setEditDriver({
                              ...editDriver,
                              licenseId: data.value,
                            }),
                        }}
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-half-form">
                <div className="drvr-right-forms-label">
                  {t("admin.driver.editViewCreate.licenseExpDate")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-right-form-item">
                      <SingleDatePicker
                        id="ExpirationDate"
                        setErrorList={setErrorList}
                        errorList={errorList}
                        oldDate={driver.licenseExpirationDate}
                        dateRange={[
                          dayjs().subtract(10, "year"),
                          dayjs().add(25, "year"),
                        ]}
                        setDate={(data) => {
                          setEditDriver({
                            ...editDriver,
                            licenseExpirationDate:
                              data ?? driver.licenseExpirationDate,
                          });
                        }}
                        placeholder={
                          driver.licenseExpirationDate ||
                          editDriver?.licenseExpirationDate
                            ? t("admin.driver.editViewCreate.licenseExpDate")
                            : t("common.na")
                        }
                        localeFormat={
                          preferencesContext.localeFormat ?? "DD / MM / YYYY"
                        }
                        language={preferencesContext.language ?? "it"}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">
                {t("admin.driver.editViewCreate.licenseType")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <Dropdown
                      isClearable={false}
                      itemAttribute="label"
                      placeholder={
                        editDriver?.licenseType || driver.licenseType
                          ? t("admin.driver.editViewCreate.licenseType")
                          : t("common.na")
                      }
                      value={{
                        label:
                          editDriver?.licenseType ??
                          driver.licenseType ??
                          undefined,
                      }}
                      size="normal"
                      onChange={(val) => {
                        setEditDriver({
                          ...editDriver,
                          licenseType: val[0].label,
                        });
                      }}
                      options={licenseTypeList}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>

          {/* Identification / Vehicle */}
          <div className="drvr-info-form">
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.identification")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">
                {t("admin.driver.editViewCreate.driverId")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <TextField
                      id="driverId"
                      validate="numeric"
                      disabled={true}
                      placeholder={
                        editDriver?.id || driver.id
                          ? t("admin.driver.editViewCreate.driverId")
                          : t("common.na")
                      }
                      value={editDriver?.id ?? driver.id ?? null}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">
                {t("admin.driver.editViewCreate.linkedTachograph")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    <TextField
                      id="tachographCard"
                      validate="alphaNumericSpecialCharacter"
                      placeholder={
                        editDriver?.tachographCard || driver.tachographCard
                          ? t("admin.driver.editViewCreate.linkedTachograph")
                          : t("common.na")
                      }
                      value={
                        editDriver?.tachographCard ??
                        driver.tachographCard ??
                        null
                      }
                      errorList={setErrorList}
                      disabled={false}
                      events={{
                        onChange: (data) =>
                          setEditDriver({
                            ...editDriver,
                            tachographCard: data.value,
                          }),
                      }}
                    >
                      <div className="drvr-tacograph-claim">
                        {t("admin.driver.editViewCreate.tachographClaim")}
                      </div>
                    </TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="drvr-separator" />
            <div className="drvr-forms-title">
              {t("admin.driver.editViewCreate.associatedVehicle")}
            </div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">{t("common.vehicle")}</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form-item">
                    {vehicles.length > 0 && (
                      <Dropdown
                        isClearable={true}
                        itemAttribute="label"
                        placeholder={
                          editDriver?.vehicle || driver.vehicle
                            ? t("admin.driver.editViewCreate.selectVehicle")
                            : t("common.na")
                        }
                        value={{
                          label: removedVehicle
                            ? undefined
                            : vehicle.alias && vehicle.plate
                            ? vehicle.alias + "-" + vehicle.plate
                            : undefined,
                          id: removedVehicle
                            ? undefined
                            : editDriver?.vehicle ??
                              driver.vehicle ??
                              undefined,
                        }}
                        size="normal"
                        onChange={(val) => {
                          const vehicleId =
                            val && val.length > 0
                              ? val[0].id
                              : val && val.length === 0
                              ? null
                              : vehicle.driver;
                          if (val && val.length === 0) {
                            setRemovedVehicle(true);
                          } else if (val && val.length > 0) {
                            setRemovedVehicle(false);
                          }
                          setEditDriver({
                            ...editDriver,
                            vehicle: vehicleId,
                          });
                        }}
                        options={vehicles.map((y) => {
                          const newObject = {
                            id: y.id,
                            label: y.alias + "-" + y.plate,
                          };
                          return newObject;
                        })}
                      />
                    )}
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {/* Group */}
          <div className="drvr-info-form">
            <div className="drvr-forms-title">{t("common.group")}</div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">{t("common.group")}</div>
              <Form>
                <div className="drvr-forms">
                  <div className="drvr-left-form">
                    <Dropdown
                      isClearable={false}
                      itemAttribute="name"
                      placeholder={
                        fleetView.name
                          ? t("admin.driver.editViewCreate.selectGroup")
                          : t("common.na")
                      }
                      value={{
                        name: fleetView.name ?? undefined,
                      }}
                      size="normal"
                      onChange={(val) => {
                        setEditDriver({
                          ...editDriver,
                          fleet: val[0].id,
                        });
                      }}
                      options={fleetViews}
                    />
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {/* Account */}
          <div className="drvr-info-form">
            <div className="drvr-forms-title">{t("common.account")}</div>
            <div className="drvr-forms-container">
              <div className="drvr-left-forms-label">
                {t("common.username")}
              </div>
              <Form>
                <div className="drvr-forms">
                  <div className="left-form drvr-link">
                    <TextField
                      id="username"
                      placeholder={
                        editDriver?.username || driver.username
                          ? t("common.username")
                          : t("common.na")
                      }
                      disabled={true}
                      value={editDriver?.username ?? driver.username ?? null}
                    >
                      <IconForward
                        className={"drvr-username-button"}
                        size={14}
                        color="--global-colors-ink-light"
                        onClick={() => {
                          alert("go to account page");
                        }}
                      />
                    </TextField>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </PageContent>
      <UploadImage
        openModal={openModalAvatar}
        setOpenModal={() => {
          setOpenModalAvatar(false);
        }}
        imageAvatar={
          (editDriver?.avatar && editDriver?.avatar?.split("/").pop()) ??
          (driver.avatar && driver.avatar?.split("/").pop()) ??
          ""
        }
        setUploadNameFile={(name) => {
          setEditDriver({
            ...editDriver,
            avatar: process.env.REACT_APP_BUCKET_URL + "Images/" + name,
          });
          ToastNotification({
            toastId: "titleMessageUploadingAvatar",
            status: "success",
            description: t(
              "account.settings.edit.descriptionMessageUploadingAvatar"
            ),
            title: t("account.settings.edit.titleMessageUploadingAvatar"),
          });
        }}
      />
    </>
  );
};
export default EditDriver;
