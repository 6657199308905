import HttpClient from "../../utils/HttpClient";

import { BeaconThreshold } from "./beaconThresholdSlice";

export default class BeaconThresholdRepository extends HttpClient {
  constructor() {
    super(true);
  }

  getThresholds(queryParams?: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/beacons/thresholds";
    return this.instance.get(path + (queryParams ?? ""));
  }

  createThreshold(beaconThreshold: BeaconThreshold) {
    const tenantId = localStorage.getItem("tenantId");
    let path = "/tenants/" + tenantId + "/beacons/thresholds";
    return this.instance.post(path, beaconThreshold);
  }

  updateThreshold(beaconThreshold: BeaconThreshold) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" + tenantId + "/beacons/thresholds/" + beaconThreshold.id;
    return this.instance.patch(path, beaconThreshold);
  }

  deleteThreshold(thresholdId: number) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" + tenantId + "/beacons/thresholds/" + thresholdId.toString();
    return this.instance.delete(path);
  }
}
