import { Cluster, MarkerClusterer } from "@googlemaps/markerclusterer";
import { Easing, Tween } from "@tweenjs/tween.js";
import i18next from "i18next";
import _ from "lodash";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { store } from "../../app/store";
import { IconLockerClosed } from "../../ui/Icon/Solid/LockerClosed";
import { Actions } from "../../ui/Map/Actions";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { GeofenceCategory } from "../../ui/Map/GeofenceDataStructure";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { DriverInfo, VehicleStatusInfo } from "../../ui/Map/SearchActionMenu";
import { StatusFilterBadge } from "../../ui/Map/StatusFilterBadge";
import { Zoom } from "../../ui/Map/Zoom";
import { ClusterMarker } from "../../ui/Marker/ClusterMarker";
import { MapMarkerCustomIcon } from "../../ui/Marker/MapMarkerCustomIcon";
import { MapMarkerPin } from "../../ui/Marker/MapMarkerPin";
import { TypeOfVehicleType } from "../../ui/Vehicles/VehicleTypes";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import {
  getBoundsZoomLevel,
  getIconFromVehicleType,
  kmToMiles,
} from "../../utils/Utils";
import { Driver, driversSelectors } from "../driver/driversSlice";
import { Position } from "../geofence/geofenceSlice";
import { geofenceCategoriesSelectors } from "../geofenceCategory/geofenceCategoriesSlice";
import {
  Preferences,
  updatePreferencesAsync,
} from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import { Vehicle } from "../vehicle/vehiclesSlice";
import { FleetVehicleStatusSummary } from "../vehicle/vehiclesStatusAmountSlice";
import {
  VehicleStatus,
  statusVehicleType,
} from "../vehicle/vehiclesStatusSlice";
import "./FleetControlMap.css";
import { FleetPolling } from "./FleetPolling";

// #region types
interface VehicleInfo {
  id: number;
  plate: string;
  alias: string;
  status: string;
  lastUpdate: string;
  speed: number;
  type: TypeOfVehicleType;
}

interface MarkerData {
  id: string;
  latitude: number;
  longitude: number;
  address: string;
  vehicleInfo?: VehicleInfo;
  clusterVehiclesInfo?: VehicleInfo[];
  driver: string;
  showTooltip: boolean | undefined;
  component: any;
}

interface LatLng {
  lat: number;
  lng: number;
}
interface CenterOption {
  center: LatLng;
}

interface FleetControlMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  hasStreetView: boolean;
  latitude: number;
  longitude: number;
  vehicles: Vehicle[];
  vehiclesStatus: VehicleStatus[];
  drivers: Driver[];
  selectedVehicleStatus?: VehicleStatus;
  enableTracking?: boolean;
  isVehicleDetailsOpen?: boolean;
  geofencesProp: any;
  geofenceCategoriesProp: any;
  vehicleStatusAmount: FleetVehicleStatusSummary;
  pollingDate: FleetPolling;
  setExpanded: (a: any) => any;
  vehicleStatusFromBadge: (a: any) => any;
  pollingRefresh: () => any;
  streetViewEnabledFleetControl: (e: any) => any;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
    clusterShowed?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

interface SettingData {
  alias?: boolean;
  lock?: boolean;
  cluster?: boolean;
  mouseover?: boolean;
  traffic?: boolean;
  satellite?: boolean;
  open?: boolean;
}

let geofenceAreaForGeofenceAction: any = null;
const componentsToAdd: ComponentOnMap[] = [];
let geofenceBoundsMarker: any;
let infoWindow: google.maps.InfoWindow;
let geofenceAreaForSearchByGeofence: any = null;
let geofenceClusterMarkers: MarkerClusterer;
let geofenceMarkers: google.maps.Marker[] = [];
let dragListener: google.maps.MapsEventListener;
let zoomListener: google.maps.MapsEventListener;
// #endregion types

export const FleetControlMap: React.FC<FleetControlMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  hasStreetView,
  latitude,
  longitude,
  vehicles,
  vehiclesStatus,
  drivers,
  selectedVehicleStatus,
  enableTracking,
  isVehicleDetailsOpen,
  geofencesProp,
  geofenceCategoriesProp,
  vehicleStatusAmount,
  pollingDate,
  setExpanded,
  vehicleStatusFromBadge,
  pollingRefresh,
  streetViewEnabledFleetControl,
}) => {
  let googleMap = window.google;

  // List of markers as components
  const [markerAsComponentList, setMarkerAsComponentList] = useState<any[]>([]);
  const [boundsMarkers, setBoundsMarkers] = useState<google.maps.LatLngBounds>(
    {} as google.maps.LatLngBounds
  );
  const [map, setMap] = useState<any>();
  const [initialZoom, setInitialZoom] = useState<number>(3);
  const [markersData, setMarkersData] = useState<MarkerData[]>([]);
  const [isMapIdle, setIsMapIdle] = useState(false);
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);
  const [markerClusterer, setMarkerClusterer] = useState<any>(null);
  const navigate = useNavigate();
  const [userSelectedMapType, setUserSelectedMapType] = useState<string>("");
  const [preferencesContext, setPreferencesContext]: [
    Preferences,
    Dispatch<SetStateAction<Preferences>>
  ] = useContext(UserContext);
  const [streetViewEnabled, setStreetViewEnabled] = useState(false);

  const [settingData, setSettingData] = useState<SettingData>({
    alias: undefined,
    lock: false,
    cluster: undefined,
    mouseover: undefined,
    traffic: undefined,
    satellite: undefined,
    open: false,
  });
  const [tooltipPropertyOnClick, setTooltipPropertyOnClick] = useState(-1);
  const [clickedOnTooltipAction, setClickedOnTooltipAction] =
    useState<"OPEN_DETAILS" | "OPEN_TRACKING" | "MARKER_CLICK" | "NONE">(
      "NONE"
    );

  /**
   * If map is in idle state (map tiles are loaded) or not
   */
  const trafficLayer = useRef<any>(null);
  const $mapDiv = document.getElementById("map0");
  const mapDim = { height: $mapDiv?.clientHeight, width: $mapDiv?.clientWidth };
  const closeModalTween = useRef<any>({ tween: undefined });
  const openModalTween = useRef<any>({ tween: undefined, vehicle: undefined });

  useEffect(() => {
    streetViewEnabledFleetControl(streetViewEnabled);
  }, [streetViewEnabled]);

  if (isMapIdle && googleMap) {
    infoWindow = new googleMap.maps.InfoWindow({
      content: "",
      pixelOffset: new googleMap.maps.Size(0, -40),
    });
  }

  //#region METHODS
  // This method is used to open vehicle details modal and center marker, zoom and animation
  function openVehicleModal() {
    const centerOption: CenterOption = {
      center: { lat: map.getCenter().lat(), lng: map.getCenter().lng() },
    };
    const zoomOption = {
      zoom: map.getZoom(),
    };

    openModalTween.current.vehicle = selectedVehicleStatus?.id;
    if (
      selectedVehicleStatus?.dynamicFields?.latitude &&
      selectedVehicleStatus?.dynamicFields?.longitude
    ) {
      openModalTween.current.tween = new Tween(centerOption) // Create a new tween that modifies 'centerOption'.
        .to(
          {
            center: {
              lat: selectedVehicleStatus?.dynamicFields?.latitude,
              lng: selectedVehicleStatus?.dynamicFields?.longitude,
            },
          },
          1000
        ) // Move to destination in 1 second.
        .easing(Easing.Linear.None) // Use an easing function to make the animation smooth.
        .onUpdate(() => {
          map?.moveCamera(centerOption);
        })
        .start()
        .onComplete(() => {
          const zoomTween = new Tween(zoomOption) // Create a new tween that modifies 'zoomOption'.
            .to(
              {
                zoom: 22,
              },
              1700
            ) // Move to destination in 2 second.
            .easing(Easing.Linear.None) // Use an easing function to make the animation smooth.
            .onUpdate(() => {
              map?.moveCamera(zoomOption);
            })
            .start()
            .onComplete(() => {
              openModalTween.current.tween = undefined;
            });
          function animateZoom(time: any) {
            requestAnimationFrame(animateZoom);
            zoomTween.update(time);
          }
          requestAnimationFrame(animateZoom);
        });
    }
    // Start the tween immediately.

    // Setup the animation loop.
    function animateCenter(time: any) {
      if (
        closeModalTween.current.tween !== undefined &&
        openModalTween.current.tween !== undefined
      ) {
        openModalTween.current.tween.stop();
      } else if (openModalTween.current.tween !== undefined) {
        openModalTween.current.tween.update(time);
        requestAnimationFrame(animateCenter);
      }
    }

    requestAnimationFrame(animateCenter);
  }

  // This method is used to close vehicle details modal and center marker, zoom and animation
  function closeVehicleModal(reopen = false) {
    const zoomOption = {
      zoom: map && map.getZoom(),
    };
    let zoomOut = initialZoom;
    if (reopen) {
      let previousVehicle: VehicleStatus =
        vehiclesStatus.find(
          (vehicleStatus) => vehicleStatus.id === openModalTween.current.vehicle
        ) ?? ({} as VehicleStatus);
      if (
        previousVehicle?.dynamicFields?.latitude &&
        previousVehicle?.dynamicFields?.longitude &&
        selectedVehicleStatus?.dynamicFields?.latitude &&
        selectedVehicleStatus?.dynamicFields?.latitude
      ) {
        let bounds = new googleMap.maps.LatLngBounds();
        bounds.extend(
          new googleMap.maps.LatLng(
            previousVehicle.dynamicFields.latitude,
            previousVehicle.dynamicFields.longitude
          )
        );
        bounds.extend(
          new googleMap.maps.LatLng(
            selectedVehicleStatus.dynamicFields.latitude,
            selectedVehicleStatus.dynamicFields.longitude
          )
        );
        let zoomActual = map.getZoom();
        zoomOut = getBoundsZoomLevel(bounds, mapDim);
        zoomOut = zoomOut < zoomActual ? zoomOut - 1 : zoomActual;
      }
    }
    closeModalTween.current.tween = new Tween(zoomOption) // Create a new tween that modifies 'centerOption'.
      .to(
        {
          zoom: zoomOut,
        },
        1700
      ) // Move to destination in 1 second.
      .easing(Easing.Linear.None) // Use an easing function to make the animation smooth.
      .onUpdate(() => {
        map?.moveCamera(zoomOption);
      })
      .start()
      .onComplete(() => {
        if (reopen) {
          openVehicleModal();
        } else {
          updateUIFromSettings();
          map?.fitBounds(boundsMarkers, 100);
          openModalTween.current.vehicle = undefined;
        }
        closeModalTween.current.tween = undefined;
      });
    // Start the tween immediately.

    // Setup the animation loop.
    function animateZoom(time: any) {
      if (closeModalTween.current.tween !== undefined) {
        closeModalTween.current.tween.update(time);
        requestAnimationFrame(animateZoom);
      }
    }

    requestAnimationFrame(animateZoom);
  }

  // updates checkbox values of setting menu
  const settingActionChange = (name: string, setting: boolean) => {
    setSettingData((prev) => ({
      ...prev,
      [name]: setting,
    }));
  };

  // this method is in charge to choose the correct color for each state
  function handleStatusColor(status: string) {
    switch (status) {
      case "MOVING":
        return "var(--global-colors-feedback-success)";

      case "PARKING":
        return "var(--global-colors-feedback-danger)";

      case "OFFLINE":
        return "var(--global-colors-ink-light)";

      case "UNKNOWN":
        return "var(--global-colors-ink-lighter)";

      case "STOP":
        return "var(--global-colors-ui-secondary)";

      default:
    }
  }

  /**
   * This method is in charge of adding custom cluster component for googleMarkerClusterer library.
   */
  const renderer = {
    render(__namedParameters: Cluster) {
      const clusterData = _.countBy(__namedParameters.markers, "status");

      let extractedFields = __namedParameters?.markers?.map((item) => ({
        vehicleInfo: markersData.find((e: MarkerData) => e.id === item.id)
          ?.vehicleInfo,
        status: item.status,
      }));

      const clusterDataVehiclesInfo = _.groupBy(extractedFields, "status");
      return MarkerAsComponent({
        id: "markerCluster",
        googleMap: googleMap,
        lat: __namedParameters.position.lat(),
        lng: __namedParameters.position.lng(),
        map: map,
        onClick: () => {
          map.fitBounds(__namedParameters.bounds, 100);
        },
        navigate: (e) => {
          window.open(
            window.location.origin + "/dashboard/fleet-control/vehicle/" + e
          );
        },

        fleetControlClusterInfo: clusterDataVehiclesInfo,
        showTooltip: true,
        infoWindow: infoWindow,
        component: (
          <ClusterMarker
            data={Object.keys(clusterData).map((key) => ({
              status: key ?? "UNKNOWN",
              numbers: clusterData[key],
              color:
                handleStatusColor(key) ?? "var(--global-colors-ui-secondary)",
            }))}
            hasChart={true}
            text={`${__namedParameters.markers?.length ?? ""}`}
          />
        ),
      });
    },
  };

  function createMarker(properties: MarkerData) {
    return MarkerAsComponent({
      id: "customMarker-" + properties.vehicleInfo?.id,
      googleMap: googleMap,
      lat: properties.latitude,
      lng: properties.longitude,
      address: properties.address,
      show: true,
      map: map,
      status: properties.vehicleInfo?.status,
      infoWindow: infoWindow,
      component: properties.component,
      vehicleInfo: properties.vehicleInfo,
      driver: properties.driver,
      showTooltip: properties.showTooltip,
      onClick: () => {
        setTooltipPropertyOnClick(properties.vehicleInfo?.id ?? -1);
        setClickedOnTooltipAction("MARKER_CLICK");
      },
      toolTipDetailsClick: () => {
        setTooltipPropertyOnClick(properties.vehicleInfo?.id ?? -1);
        setClickedOnTooltipAction("OPEN_DETAILS");
      },
      toolTipTrackingClick: () => {
        setTooltipPropertyOnClick(properties.vehicleInfo?.id ?? -1);
        setClickedOnTooltipAction("OPEN_TRACKING");
      },
    }) as google.maps.Marker;
  }

  function updateMarkerBounds() {
    let bounds = new googleMap.maps.LatLngBounds();
    if (markersData && markersData.length) {
      markersData.forEach((location: MarkerData) => {
        bounds.extend(
          new googleMap.maps.LatLng(location.latitude, location.longitude)
        );
      });
    } else {
      bounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(50.4419, 10.1419),
        new google.maps.LatLng(36.4419, 14.1419)
      );
    }
    if (
      openModalTween.current.tween === undefined &&
      openModalTween.current.vehicle === undefined &&
      closeModalTween.current.tween === undefined &&
      _.isEmpty(selectedVehicleStatus) &&
      !settingData.lock
    ) {
      map?.fitBounds(bounds, 100);
    }
    setBoundsMarkers(bounds);
    setInitialZoom(getBoundsZoomLevel(bounds, mapDim));
    geofenceBoundsMarker = bounds;
  }

  function updateMarker(marker: any, properties: any) {
    marker.setPosition(properties.latitude, properties.longitude);
    if (marker?.status !== properties?.vehicleInfo?.status) {
      marker.status = properties?.vehicleInfo?.status;
    }
    if (properties?.vehicleInfo) {
      marker.vehicleInfo = properties?.vehicleInfo;
    }
    if (properties?.driver) {
      marker.driver = properties?.driver;
    }
    marker.setMarkerToolTip();
    marker.setComponent(properties.component);
    if (markerClusterer.markers.length === 0) {
      marker.setMap(map);
    }
  }

  /**
   * This function helps to update the UI components on map when some setting changes.
   */
  const updateUIFromSettings = () => {
    // cluster
    if (settingData.cluster) {
      if (
        !!markerClusterer &&
        markerClusterer.markers.length !== markerAsComponentList.length
      ) {
        markerClusterer && markerClusterer.clearMarkers();
        markerClusterer &&
          markerClusterer.addMarkers(
            markerAsComponentList as google.maps.Marker[]
          );
      }
    } else {
      // Removing markers from cluster and re-assigning them to the map
      markerClusterer && markerClusterer.clearMarkers();
      if (markerAsComponentList && markerAsComponentList.length > 0) {
        markerAsComponentList.forEach((markerAsComponent) =>
          markerAsComponent.setMap(map)
        );
      }
    }
    // id
    let labelId: any = document.getElementsByClassName("labelId");
    for (let component of labelId) {
      component.style.display = settingData.alias ? "block" : "none";
    }
    // mouseover
    markerAsComponentList.forEach((item: any) => {
      item.showTooltip = settingData.mouseover;
    });

    // traffic
    if (settingData.traffic) {
      map && trafficLayer.current && trafficLayer.current.setMap(map);
    } else {
      trafficLayer.current?.setMap(null);
    }
  };

  function isFullscreen(element: any) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }

  function requestFullscreen(element: any) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  const fullsc = () => {
    const elementToSendFullscreen = map?.getDiv().firstChild;
    if (isFullscreen(elementToSendFullscreen)) {
      exitFullscreen();
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  };

  const plusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp + 1);
      }
    }
  };

  const minusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp - 1);
      }
    }
  };

  const updateRemotePrefs = (editPreferences: Preferences) => {
    if (!!preferencesContext?.id) {
      if (Object.values(editPreferences).length > 0) {
        store.dispatch(
          updatePreferencesAsync({
            id: preferencesContext.id,
            preferences: editPreferences,
          })
        );

        setPreferencesContext({
          ...preferencesContext,
          listOnFleetCont:
            editPreferences.listOnFleetCont ??
            preferencesContext.listOnFleetCont,
          clusterOnMap:
            editPreferences.clusterOnMap ?? preferencesContext.clusterOnMap,
          vehIdOnMap:
            editPreferences.vehIdOnMap ?? preferencesContext.vehIdOnMap,
          tooltipOnMap:
            editPreferences.tooltipOnMap ?? preferencesContext.tooltipOnMap,
          trafficInfoOnMap:
            editPreferences.trafficInfoOnMap ??
            preferencesContext.trafficInfoOnMap,
          satelliteOnMap:
            editPreferences.satelliteOnMap ?? preferencesContext.satelliteOnMap,
        });
      }
    }
  };

  // this method is used to activate street map view from mapType button
  const mapClick = () => {
    setUserSelectedMapType("roadmap");
    map.setMapTypeId("roadmap");
  };

  // this method is used to activate satellite view from mapType button
  const satelliteClick = () => {
    setUserSelectedMapType("hybrid");
    map.setMapTypeId("hybrid");
  };
  //#endregion METHODS

  //#region USE EFFECTS
  /**
   * This use effect handles VehicleBox actions.
   */
  useEffect(() => {
    if (!_.isEmpty(selectedVehicleStatus) && isMapIdle && !enableTracking) {
      if (
        openModalTween.current.vehicle === undefined &&
        openModalTween.current.tween === undefined &&
        closeModalTween.current.tween === undefined
      ) {
        openVehicleModal();
      } else if (
        openModalTween.current.vehicle !== undefined &&
        openModalTween.current.vehicle !== selectedVehicleStatus?.id &&
        closeModalTween.current.tween === undefined
      ) {
        if (openModalTween.current.tween !== undefined) {
          openModalTween.current.tween.stop();
        }
        closeVehicleModal(true);
      }
    } else if (
      openModalTween.current.vehicle !== undefined &&
      closeModalTween.current.tween === undefined &&
      !isVehicleDetailsOpen
    ) {
      if (openModalTween.current.tween !== undefined) {
        openModalTween.current.tween.stop();
      }
      closeVehicleModal();
    }
  }, [selectedVehicleStatus, isVehicleDetailsOpen, isMapIdle]);

  /**
   *  this method uses MarkerClusterer library to create cluster based on render method
   */
  useEffect(() => {
    if (isMapIdle) {
      map.setOptions({ minZoom: 3, maxZoom: 30 });
      if (markerClusterer) {
        // cluster
        if (settingData.cluster) {
          const sameCustomMarkers = _.isEqual(
            markerClusterer.markers,
            markerAsComponentList
          );
          if (!!markerClusterer && !sameCustomMarkers) {
            markerClusterer && markerClusterer.clearMarkers();
            markerClusterer &&
              markerClusterer.addMarkers(
                markerAsComponentList as google.maps.Marker[]
              );
          }
        } else {
          markerClusterer && markerClusterer.clearMarkers();
        }
      } else if (markerAsComponentList.length > 0) {
        setMarkerClusterer(
          new MarkerClusterer({
            map: map,
            markers: markerAsComponentList as google.maps.Marker[],
            renderer: renderer,
          })
        );
      }
    }
  }, [isMapIdle, settingData.cluster, markerAsComponentList]);

  /**
   * This use effect is in charge of handling the several behavior related to the tooltip when something is clicked.
   */
  useEffect(() => {
    switch (clickedOnTooltipAction) {
      case "MARKER_CLICK":
        if (!settingData.mouseover && tooltipPropertyOnClick !== -1) {
          if (tooltipPropertyOnClick !== selectedVehicleStatus?.id) {
            navigate(
              "/dashboard/fleet-control/vehicle/" + tooltipPropertyOnClick
            );
          } else {
            navigate("/dashboard/fleet-control");
          }
        }
        break;
      case "OPEN_DETAILS":
        infoWindow.close();
        if (
          tooltipPropertyOnClick !== selectedVehicleStatus?.id &&
          tooltipPropertyOnClick !== -1
        ) {
          navigate(
            "/dashboard/fleet-control/vehicle/" + tooltipPropertyOnClick
          );
        }
        break;
      case "OPEN_TRACKING":
        infoWindow.close();
        if (tooltipPropertyOnClick !== -1) {
          window
            .open(
              `/dashboard/fleet-control/vehicle/${tooltipPropertyOnClick}/tracking`,
              "_blank"
            )
            ?.focus();
        }
        break;
      default:
        break;
    }
    setClickedOnTooltipAction("NONE");
    setTooltipPropertyOnClick(-1);
  }, [clickedOnTooltipAction]);

  /**
   * This use effect is in charge of updating markers and map when marker data changes.
   * @param markersData
   * @param isMapIdle
   * @returns markerAsComponentList
   */

  useEffect(() => {
    if (isMapIdle) {
      const currentMarkerIds = _.sortBy(markerAsComponentList.map((x) => x.id));
      const newMarkerIds = _.sortBy(markersData.map((x) => x.id));
      const sameMarkerIds = _.isEqual(currentMarkerIds, newMarkerIds);
      if (markerAsComponentList.length && !sameMarkerIds) {
        markerAsComponentList.forEach((mProps: any) => {
          let currentMarker: any[] = markersData.filter(
            (x: any) => x.id === mProps.id
          );
          if (currentMarker.length === 0) {
            mProps.onRemove();
          }
        });
      }
      let newMarkersList = new Array(0);
      markersData.forEach((mProps: MarkerData) => {
        let currentMarker: any[] = markerAsComponentList.filter(
          (x: any) => x.id === mProps.id
        );
        if (currentMarker.length > 0) {
          // marker already exists, update location and status
          updateMarker(currentMarker[0], mProps);
        } else {
          currentMarker[0] = createMarker(mProps);
        }
        newMarkersList.push(currentMarker[0]);
      });
      setMarkerAsComponentList(newMarkersList);
      updateMarkerBounds();
    }
  }, [markersData, isMapIdle]);

  /**
   * This use effect handles marker update and animation.
   * @param markersData
   */
  useEffect(() => {
    if (!_.isEmpty(selectedVehicleStatus) && isVehicleDetailsOpen) {
      if (
        openModalTween.current.vehicle !== undefined &&
        openModalTween.current.tween === undefined &&
        closeModalTween.current.tween === undefined &&
        selectedVehicleStatus?.id === openModalTween.current.vehicle
      ) {
        if (
          selectedVehicleStatus?.dynamicFields?.latitude &&
          selectedVehicleStatus?.dynamicFields?.longitude
        ) {
          map.setCenter({
            lat: selectedVehicleStatus?.dynamicFields?.latitude,
            lng: selectedVehicleStatus?.dynamicFields?.longitude,
          });
        }
      } else if (openModalTween.current.tween !== undefined) {
        openModalTween.current.tween.stop();
        openVehicleModal();
      }
    }
  }, [markersData]);

  /**
   * This use effect updates component style on map when setting data changes
   */
  useEffect(() => {
    if (isMapIdle) {
      updateUIFromSettings();
    }
  }, [settingData, isMapIdle]);

  /**
   * This use effect is in charge of constructing the markers info given the vehicles details and statuses
   */
  useEffect(() => {
    const markersDataList: MarkerData[] = [];
    if (!_.isEmpty(vehicles) && !_.isEmpty(vehiclesStatus)) {
      vehicles.forEach((vehicle) => {
        const associatedDriver: Driver =
          drivers.find((driver) => driver.id === vehicle.driver) ??
          ({} as Driver);

        const markerData = {} as MarkerData;
        const vehicleStatus = vehiclesStatus.filter(
          (status) => status.id === vehicle.id
        )[0];
        if (!_.isEmpty(vehicleStatus) && settingData) {
          markerData.id = "customMarker-" + vehicle.id;
          markerData.latitude = vehicleStatus.dynamicFields?.latitude ?? 0;
          markerData.longitude = vehicleStatus.dynamicFields?.longitude ?? 0;
          markerData.driver =
            associatedDriver?.firstName && associatedDriver.lastName
              ? associatedDriver.firstName + " " + associatedDriver.lastName
              : i18next.t("common.noDriver");
          markerData.address = vehicleStatus?.dynamicFields?.address
            ? vehicleStatus?.dynamicFields?.address
            : "";
          markerData.vehicleInfo = {} as VehicleInfo;
          markerData.vehicleInfo.id = vehicle.id;
          markerData.vehicleInfo.plate = vehicle.plate;
          markerData.vehicleInfo.alias = vehicle.alias;
          markerData.vehicleInfo.type = vehicle.type;
          markerData.vehicleInfo.status = vehicleStatus.dynamicFields
            ? vehicleStatus.dynamicFields?.vehicleStatus
            : "UNKNOWN";
          markerData.vehicleInfo.speed = vehicleStatus.dynamicFields
            ? vehicleStatus.dynamicFields?.speed
            : 0;
          markerData.vehicleInfo.lastUpdate = ConvertTimeZone(
            vehicleStatus?.dynamicFields?.lastUpdate,
            preferencesContext.timeZone,
            preferencesContext.localeFormat
          );
          markerData.showTooltip = settingData.mouseover;
          markerData.component = (
            <>
              <MapMarkerCustomIcon
                status={
                  vehicleStatus.dynamicFields
                    ? vehicleStatus.dynamicFields?.vehicleStatus
                    : "UNKNOWN"
                }
                iconSelected={getIconFromVehicleType(
                  vehicle?.type,
                  vehicle?.type === "HUMAN_BEING" ? 16 : 17,
                  "--global-colors-ui-white"
                )}
              />
              {vehicle.alias && settingData.alias && (
                <label className="labelId">{vehicle.alias}</label>
              )}
            </>
          );
          markersDataList.push(markerData);
        }
      });
      setComponentsOnMap((prev: ComponentOnMap[]) => {
        prev.forEach((item: any) => {
          if (item.selectorName === ".action-control") {
            item.component.props.children.props.searchMenuProps.vehicles =
              vehicles;
            item.component.props.children.props.searchMenuProps.vehiclesStatus =
              vehiclesStatus;
            item.component.props.children.props.searchMenuProps.drivers =
              driversSelectors.selectAll(store.getState());
          }
        });
        return prev;
      });
    }
    setMarkersData(markersDataList);
  }, [vehicles, vehiclesStatus]);

  /**
   * This use effect is in charge of update settings data from preferences
   */
  useEffect(() => {
    if (preferencesContext && !_.isEmpty(preferencesContext)) {
      const updatedSettingData: SettingData = {};
      updatedSettingData.alias = preferencesContext.vehIdOnMap;
      updatedSettingData.lock = settingData.lock;
      updatedSettingData.cluster = preferencesContext.clusterOnMap;
      updatedSettingData.mouseover = preferencesContext.tooltipOnMap;
      updatedSettingData.traffic = preferencesContext.trafficInfoOnMap;
      updatedSettingData.satellite = preferencesContext.satelliteOnMap;

      if (!_.isEqual(settingData, updatedSettingData)) {
        setSettingData(updatedSettingData);
      }
      setExpanded(preferencesContext.listOnFleetCont);
    }
  }, [preferencesContext, settingData.lock]);

  /**
   * This use effect is in charge to add components on map
   */
  useEffect(() => {
    if (isMapIdle) {
      // update map type on change from liveTrackingMap
      if (userSelectedMapType === "") {
        settingData.satellite
          ? map.setMapTypeId("hybrid")
          : map.setMapTypeId("roadmap");
      }

      componentsToAdd.length = 0;
      componentsToAdd.push({
        selectorName: ".fleetPolling",
        selectorPosition: googleMap.maps.ControlPosition.BOTTOM_RIGHT,
        component: (
          <div
            className="fleetPolling"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
            }}
          >
            <FleetPolling pollingDate={pollingDate} />
          </div>
        ),
      });
      // zoom control
      componentsToAdd.push({
        selectorName: ".zoom-control-container",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
        component: (
          <div
            className="zoom-control-container"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
              marginBottom: "-21px",
            }}
          >
            <Zoom
              handleMinus={() => minusClick()}
              handlePlus={() => plusClick()}
            />
          </div>
        ),
      });
      // action control
      componentsToAdd.push({
        selectorName: ".action-control",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <div
            className="action-control"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
            }}
          >
            <Actions
              isFull={true}
              hasRefresh={true}
              handleFullScreen={() => fullsc()}
              handleScaleDown={() => fullsc()}
              enabledTracking={false}
              handleRefresh={() => pollingRefresh()}
              searchMenuProps={{
                vehicles: vehicles,
                vehiclesStatus: vehiclesStatus as VehicleStatusInfo[],
                drivers: driversSelectors.selectAll(
                  store.getState()
                ) as DriverInfo[],
                drawCircle: drawCircle,
                removeCircle: removeMarkersAndCircles,
                onRadiusChange: onRadiusChange,
                isMetric: preferencesContext.isMetric,
                locationSliderLimits: { min: "0", max: "100" },
                geofenceSliderLimits: { min: "0", max: "100" },
              }}
              settingsMenuProps={{
                settingActionChange: settingActionChange,
                settingData: settingData,
              }}
              geofencesProp={{
                geofences: geofencesProp,
                geofenceCategories: geofenceCategoriesProp,
                setGeofences: setGeofences,
              }}
            />
          </div>
        ),
      });
      // map type control
      componentsToAdd.push({
        selectorName: ".maptype-control",
        selectorPosition: googleMap.maps.ControlPosition.BOTTOM_RIGHT,
        component: (
          <MapType
            handleMap={() => mapClick()}
            handleSatellite={() => satelliteClick()}
            activeSatellite={
              settingData.satellite ? settingData.satellite : false
            }
          />
        ),
      });

      componentsToAdd.push({
        selectorName: ".status-filter-control",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_CENTER,
        component: (
          <div
            className="status-filter-control"
            style={{
              zIndex: 1,
              position: "absolute",
            }}
          >
            <StatusFilterBadge
              statusDetails={{
                statusCount: vehicleStatusAmount?.vehicleStatusCounts?.filter(
                  (el) => el.status !== statusVehicleType?.UNKNOWN
                ),
              }}
              onItemSelected={vehicleStatusFromBadge}
            />
          </div>
        ),
      });

      if (settingData.lock) {
        // lock
        componentsToAdd.push({
          selectorName: ".alert-lock-view",
          selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
          component: (
            <div
              className="alert-lock-view"
              onClick={() => {
                settingActionChange("open", true);
              }}
            >
              <IconLockerClosed size={16} />
              <label>{i18next.t("map.actions.lockView")}</label>
            </div>
          ),
        });
      } else if (document.querySelector(".alert-lock-view") as HTMLElement) {
        (
          document.querySelector(".alert-lock-view") as HTMLElement
        ).style.display = "none";
      }
      if (!_.isEqual(componentsToAdd, componentsOnMap)) {
        setComponentsOnMap(componentsToAdd);
      }
    }
  }, [settingData, vehicleStatusAmount, isMapIdle]);

  /**
   * This use effect updates components on map on setting data changes
   */
  useEffect(() => {
    if (preferencesContext) {
      let editPreferences = {} as Preferences;
      if (
        settingData.cluster !== undefined &&
        preferencesContext.clusterOnMap !== settingData.cluster
      )
        editPreferences.clusterOnMap = settingData.cluster;
      if (
        settingData.alias !== undefined &&
        preferencesContext.vehIdOnMap !== settingData.alias
      )
        editPreferences.vehIdOnMap = settingData.alias;
      if (
        settingData.mouseover !== undefined &&
        preferencesContext.tooltipOnMap !== settingData.mouseover
      ) {
        editPreferences.tooltipOnMap = settingData.mouseover;
      }
      if (
        settingData.traffic !== undefined &&
        preferencesContext.trafficInfoOnMap !== settingData.traffic
      )
        editPreferences.trafficInfoOnMap = settingData.traffic;
      if (
        settingData.satellite !== undefined &&
        preferencesContext.satelliteOnMap !== settingData.satellite
      ) {
        editPreferences.satelliteOnMap = settingData.satellite;
        setUserSelectedMapType("");
      }
      updateRemotePrefs(editPreferences);
    }
    setComponentsOnMap((prev: ComponentOnMap[]) => {
      prev.forEach((item: any) => {
        if (item.selectorName === ".action-control") {
          item.component.props.children.props.settingsMenuProps.settingData =
            settingData;
        }
      });
      return prev;
    });
  }, [settingData]);

  /**
   *  This use effect updates components on map on geofences data changes
   */
  useEffect(() => {
    if (!_.isEmpty(geofencesProp) && !_.isEmpty(geofenceCategoriesProp)) {
      setComponentsOnMap((prev: ComponentOnMap[]) => {
        prev.forEach((item: any) => {
          if (item.selectorName === ".action-control") {
            item.component.props.children.props.geofencesProp.geofences =
              geofencesProp;
            item.component.props.children.props.geofencesProp.geofenceCategories =
              geofenceCategoriesProp;
          }
        });
        return prev;
      });
    }
  }, [geofencesProp, geofenceCategoriesProp]);

  /**
   * This use effect updates FleetPolling props when props changes
   */
  useEffect(() => {
    setComponentsOnMap((prev: ComponentOnMap[]) => {
      prev.forEach((item: any) => {
        if (item.selectorName === ".fleetPolling") {
          item.component.props.children.props.pollingDate.date =
            pollingDate.date;
        }
      });
      return prev;
    });
  }, [pollingDate]);

  /**
   * This use effect updates SearchActionMenu props when isMetric changes
   */
  useEffect(() => {
    if (preferencesContext && !_.isEmpty(preferencesContext)) {
      setComponentsOnMap((prev: ComponentOnMap[]) => {
        prev.forEach((item: any) => {
          if (item.selectorName === ".action-control") {
            item.component.props.children.props.searchMenuProps.isMetric =
              preferencesContext.isMetric;
          }
        });
        return prev;
      });
    }
  }, [preferencesContext.isMetric]);
  //#endregion

  //#region geofence methods
  /**
   * Draw circle on map with marker, given a center and radius
   * @param center
   * @param markerColor
   * @param radius
   */
  async function drawCircle(
    center: Position,
    name: string,
    radius: string,
    markerColor?: string
  ) {
    removeMarkersAndCircles();
    map.panTo(center);
    geofenceMarkers.push(
      MarkerAsComponent({
        googleMap: googleMap,
        lat: center?.lat,
        lng: center?.lng,
        map: map,
        component: (
          <>
            <MapMarkerPin color={markerColor ?? "#0052BD"} />
            {name && <label className="labelId">{name}</label>}
          </>
        ),
      })
    );

    const circle: google.maps.Circle = new googleMap.maps.Circle({
      strokeColor: "#00AAFF",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#00aaff",
      fillOpacity: 0.3,
      map,
      center: center,
      radius: preferencesContext.isMetric
        ? parseInt(radius) * 1000
        : kmToMiles(parseInt(radius) * 1000),
    });

    geofenceAreaForSearchByGeofence = [circle];
    map.fitBounds(circle.getBounds(), 150);
  }

  function onRadiusChange(radius: string) {
    if (geofenceAreaForSearchByGeofence[0]) {
      geofenceAreaForSearchByGeofence[0].setRadius(parseInt(radius) * 1000);
      map.fitBounds(geofenceAreaForSearchByGeofence[0].getBounds(), 150);
    }
  }

  function removeMarkersAndCircles() {
    geofenceMarkers.forEach((geofence: any) => geofence.setMap(null));
    !!geofenceAreaForSearchByGeofence &&
      geofenceAreaForSearchByGeofence.forEach((geofence: any) =>
        geofence.setMap(null)
      );
    geofenceAreaForSearchByGeofence = [];
    geofenceMarkers = [];
    if (dragListener && zoomListener) {
      googleMap.maps.event.removeListener(dragListener);
      googleMap.maps.event.removeListener(zoomListener);
    }
  }

  // Geofence Action Menu methods
  // marker cluster for geofences
  const geofenceRenderer = {
    render(__namedParameters: Cluster) {
      const clusterData = _.countBy(__namedParameters.markers, "status");
      return MarkerAsComponent({
        id: "geofenceCluster",
        googleMap: googleMap,
        lat: __namedParameters.position.lat(),
        lng: __namedParameters.position.lng(),
        map: map,
        onClick: () => {
          map.fitBounds(__namedParameters.bounds, 100);
        },
        component: (
          <ClusterMarker
            data={Object.keys(clusterData).map((key) => ({
              status: key ?? "UNKNOWN",
              numbers: clusterData[key],
              color:
                geofenceCategoriesProp.find(
                  (item: GeofenceCategory) => item.name === key
                )?.color ?? "#0052BD",
            }))}
            hasChart={true}
            text={`${__namedParameters.markers?.length ?? ""}`}
          />
        ),
      });
    },
  };

  function setGeofences(geofenceOptions: any) {
    if (geofenceMarkers.length !== 0) {
      geofenceMarkers.forEach((geofence: any) => geofence.setMap(null));
    }
    if (geofenceAreaForGeofenceAction) {
      geofenceAreaForGeofenceAction.setMap(null);
    }
    if (!_.isEmpty(geofenceClusterMarkers)) {
      geofenceClusterMarkers.setMap(null);
      geofenceClusterMarkers = {} as MarkerClusterer;
    }
    geofenceMarkers = [];
    let markerArray: google.maps.Marker[] = [];
    let bounds = new googleMap.maps.LatLngBounds();
    geofenceOptions.forEach((option: any) => {
      if (option.checked) {
        option.geofences.forEach((geofence: any) => {
          const category = geofenceCategoriesSelectors.selectById(
            store.getState(),
            geofence.geofenceCategory
          );
          markerArray.push(
            MarkerAsComponent({
              googleMap: googleMap,
              id: geofence.id,
              lat: geofence.shape.center.lat,
              lng: geofence.shape.center.lng,
              map: map,
              geofence: geofence.name,
              isGeofence: true,
              status: category?.name,
              onClick: () => clickOnGeofence(geofence, option.color),
              component: (
                <>
                  <MapMarkerPin color={option.color ?? "#0052BD"} />
                  {option.geofences.find((item: any) => item.id === geofence.id)
                    .name && (
                    <label className="labelId">
                      {
                        option.geofences.find(
                          (item: any) => item.id === geofence.id
                        ).name
                      }
                    </label>
                  )}
                </>
              ),
            })
          );
          bounds.extend(
            new googleMap.maps.LatLng(
              geofence.shape.center.lat,
              geofence.shape.center.lng
            )
          );
        });
      }
    });

    if (map && markerArray.length !== 0) {
      map.fitBounds(bounds, 300);
    } else {
      if (
        geofenceAreaForSearchByGeofence &&
        geofenceAreaForSearchByGeofence.length !== 0
      ) {
        geofenceAreaForSearchByGeofence.forEach((geofence: any) => {
          geofence.setMap(null);
        });
        geofenceAreaForSearchByGeofence = [];
      }
      map.fitBounds(geofenceBoundsMarker, 100);
    }
    geofenceMarkers.push(...markerArray);
    geofenceClusterMarkers = new MarkerClusterer({
      map: map,
      markers: geofenceMarkers,
      renderer: geofenceRenderer,
    });
  }

  // #region progressive markers loader
  const updateMapData = useCallback(() => {
    const visibleMarkers: any[] = []; // Array of visible markers

    if (geofenceMarkers.length > 0) {
      geofenceMarkers.forEach((marker: any) => {
        if (map.getBounds().contains(marker.getPosition())) {
          visibleMarkers.push(marker); // Add marker to array of visible markers
          if (!marker.getMap()) {
            marker.setMap(map);
          }
        } else {
          marker.setMap(null);
        }
      });

      if (geofenceClusterMarkers) {
        geofenceClusterMarkers.clearMarkers(); // Remove marker from cluster
        geofenceClusterMarkers.addMarkers(visibleMarkers); // Add markers to cluster
      }
    }
  }, [map, geofenceMarkers, geofenceClusterMarkers]);

  useEffect(() => {
    // Create event listeners for map drag and zoom events
    if (googleMap && map) {
      dragListener && googleMap.maps.event.removeListener(dragListener);
      zoomListener && googleMap.maps.event.removeListener(zoomListener);
      dragListener = googleMap.maps.event.addListener(
        map,
        "dragend",
        updateMapData
      );
      zoomListener = googleMap.maps.event.addListener(
        map,
        "zoom_changed",
        updateMapData
      );

      // Cleanup function to remove event listeners
      return function cleanListeners() {
        googleMap.maps.event.removeListener(dragListener);
        googleMap.maps.event.removeListener(zoomListener);
      };
    }
  }, [map, geofenceMarkers]);
  // #endregion progressive markers loader

  function clickOnGeofence(geofence: any, color: string) {
    if (geofenceAreaForGeofenceAction)
      geofenceAreaForGeofenceAction.setMap(null);
    map.panTo(geofence.shape.center);

    if (geofence.geofenceShapeEnum === "CIRCLE") {
      geofenceAreaForGeofenceAction = new googleMap.maps.Circle({
        strokeColor: color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: color,
        fillOpacity: 0.3,
        map,
        center: geofence.shape.center,
        radius: geofence.shape.radius,
      });

      map.fitBounds(geofenceAreaForGeofenceAction.getBounds(), 100);
    } else {
      if (geofence.geofenceShapeEnum === "POLYGON") {
        geofenceAreaForGeofenceAction = new googleMap.maps.Polygon({
          paths: geofence.shape.points,
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: color,
          fillOpacity: 0.35,
          map,
        });
      }
      let bounds = new googleMap.maps.LatLngBounds();
      geofence.shape.points.forEach((point: any) => {
        bounds.extend(new googleMap.maps.LatLng(point.lat, point.lng));
      });
      map.fitBounds(bounds, 100);
    }
    geofenceAreaForSearchByGeofence = [
      ...geofenceAreaForSearchByGeofence,
      geofenceAreaForGeofenceAction,
    ];
  }
  //#endregion geofence methods

  return (
    <>
      <BaseMap
        id={id}
        googleMapsApiKey={googleMapsApiKey}
        zoom={zoom}
        hasStreetView={hasStreetView}
        latitude={latitude}
        longitude={longitude}
        getMap={setMap}
        trafficLayer={trafficLayer}
        getIsMapIdle={setIsMapIdle}
        setStreetViewEnabled={setStreetViewEnabled}
      >
        {componentsOnMap}
      </BaseMap>
    </>
  );
};
