import HttpClient from "../../../utils/HttpClient";

export default class AnalogicReportRepository extends HttpClient {
  constructor() {
    super(true);
  }
  getSensorData(beaconNamespace: string, entityId: string, queryParam: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/sensors/" +
      beaconNamespace +
      "/entities/" +
      entityId +
      "/sensor-data" +
      queryParam;
    return this.instance.get(path);
  }
  getReportSummary(
    beaconNamespace: string,
    entityId: string,
    queryParam: string
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/sensors/" +
      beaconNamespace +
      "/entities/" +
      entityId +
      "/sensors-summary" +
      queryParam;
    return this.instance.get(path);
  }

  getReportMacroSummary(entityId: string, queryParam: string) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/sensors/entities/" +
      entityId +
      "/macro-summary" +
      queryParam;
    return this.instance.get(path);
  }

  getReportChartData(
    beaconNamespace: string,
    entityId: string,
    queryParam: string
  ) {
    const tenantId = localStorage.getItem("tenantId");
    let path =
      "/tenants/" +
      tenantId +
      "/sensors/" +
      beaconNamespace +
      "/entities/" +
      entityId +
      "/sensor-chart-data" +
      queryParam;
    return this.instance.get(path);
  }
}
