import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import {
  AuthenticationType,
  TypeOfVehicleType,
  VehicleFuelType,
  VehicleStatusType,
  VehicleUnitOfMeasurementType,
} from "../../ui/Vehicles/VehicleTypes";
import { getErrorCodes } from "../../utils/Utils";
import { Contract } from "../contract/contractsSlice";
import { Device } from "../device/devicesSlice";
import { getDriverDetailsAsync } from "../driver/driversSlice";
import {
  getDriversStatusesAsync,
  getDriverStatusAsync,
  getFilteredDriversStatusAndDetailsAsync,
  getFilteredDriversStatusAndDetailsPaginationAsync,
} from "../driver/driversStatusSlice";
import { getFleetAsync } from "../fleet/fleetsSlice";
import { getDeadlinesAsync } from "../maintenance-deadline/deadlinesSlice";
import { getGeofencesReportsAsync } from "../report/geofence/GeofencesReportsSlice";
import { getRoutesHistoryAsync } from "../route/routesHistorySlice";
import { vehicleSchema, vehiclesSchema } from "./vehicleNormalization";
import VehiclesRepository from "./vehiclesRepository";
import {
  getFilteredVehiclesStatusAsync,
  getFilteredVehiclesStatusPaginationAsync,
  getVehicleAsync,
  getVehiclesAsync,
  getVehiclesStatusByFleetAsync,
} from "./vehiclesStatusSlice";

//#region Type
export interface Vehicle {
  id: number;
  alias: string;
  brand: string;
  plate: string;
  registrationDate: string;
  model: string;
  unitOfMeasurement: VehicleUnitOfMeasurementType;
  fuelType: VehicleFuelType;
  consumption: number;
  emissions: number;
  maxSpeed: number | string;
  fuelPrice: number;
  device: number;
  contract: number;
  name: string;
  fuelCapacity: number;
  imageUrl: string;
  initialKms: number;
  type: TypeOfVehicleType;
  status: VehicleStatusType;
  driver: number;
  tenant: number;
  fleet: number;
  vehicleStatus: number;
  city: string;
  maintenance: boolean;
  asset: number[];
  driverIdentifiedBy: AuthenticationType;
  stopTimeThreshold: number;
}

//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehiclesDetailsAsync = createAsyncThunk(
  "vehicles/getVehiclesDetails",
  async (_data, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getVehiclesDetails();
      // The value we return becomes the `fulfilled` action payload
      const vehicles = _.get(response, Config.VEHICLE_DETAILS_RESPONSE_PATH);
      const normalizedData = normalize(vehicles ?? [], vehiclesSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getVehiclesDetailsPaginationAsync = createAsyncThunk(
  "vehicles/getVehiclesDetailsPagination",
  async (data: { queryParams?: string }, { rejectWithValue, dispatch }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getFilteredVehiclesDetails(
        data.queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const vehicles = _.get(response, Config.VEHICLE_DETAILS_RESPONSE_PATH);
      const totalPages = _.get(
        response,
        Config.VEHICLES_ADMIN_TOTAL_PAGES_RESPONSE_PATH
      );
      const totalElements = _.get(
        response,
        Config.VEHICLES_ADMIN_TOTAL_ELEMENTS_RESPONSE_PATH
      );
      if (totalPages) {
        dispatch(setNumberOfPages(totalPages));
      }
      if (totalElements) {
        dispatch(setNumberOfElements(totalElements));
      }
      const normalizedData = normalize(vehicles ?? [], vehiclesSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getVehicleDetailsAsync = createAsyncThunk(
  "vehicles/getVehicleDetails",
  async (data: { id: number; fleetId: number }, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getVehicleDetails(
        data.fleetId,
        data.id
      );
      // The value we return becomes the `fulfilled` action payload
      const vehicle = _.get(response, Config.VEHICLE_STATUS_RESPONSE_PATH);
      const normalizedData = normalize(vehicle, vehicleSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const deactivateVehicleAsync = createAsyncThunk(
  "vehicles/deactivateVehicle",
  async (data: { id: number; fleetId: number }, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.partiallyUpdateVehicle(
        data.fleetId,
        data.id,
        {
          status: "INACTIVE",
        },
        false
      );
      const vehicle = _.get(response, Config.VEHICLE_STATUS_RESPONSE_PATH);
      const normalizedData = normalize(vehicle, vehicleSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const activateVehicleAsync = createAsyncThunk(
  "vehicles/activateVehicle",
  async (data: { id: number; fleetId: number }, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.partiallyUpdateVehicle(
        data.fleetId,
        data.id,
        {
          status: "ACTIVE",
        },
        false
      );
      const vehicle = _.get(response, Config.VEHICLE_STATUS_RESPONSE_PATH);
      const normalizedData = normalize(vehicle, vehicleSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const updateVehicleAsync = createAsyncThunk(
  "vehicles/updateVehicle",
  async (
    data: {
      fleetId: number;
      id: number;
      vehicle: Vehicle;
      removeDriver: boolean;
      contract: Contract | null;
      device: Device | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.partiallyUpdateVehicle(
        data.fleetId,
        data.id,
        {
          ...data.vehicle,
          driver: data.vehicle.driver ? { id: data.vehicle.driver } : null,
          contract: data.contract ?? null,
          device: data.device ?? null,
        },
        data.removeDriver
      );
      const vehicle = _.get(response, Config.VEHICLE_STATUS_RESPONSE_PATH);
      const normalizedData = normalize(vehicle, vehicleSchema);
      return normalizedData.entities;
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getFilteredVehiclesDetailsAsync = createAsyncThunk(
  "vehicles/getFilteredVehiclesDetails",
  async (queryParams: string, { rejectWithValue }) => {
    try {
      const vehiclesRepository = new VehiclesRepository();
      const response = await vehiclesRepository.getFilteredVehiclesDetails(
        queryParams
      );
      const vehicles = _.get(response, Config.VEHICLE_DETAILS_RESPONSE_PATH);
      if (vehicles) {
        const normalizedData = normalize(vehicles, vehiclesSchema);
        return normalizedData.entities;
      } else {
        return [];
      }
    } catch (err: any) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data.message);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload.vehicle
    ? vehiclesAdapter.setAll(state, action.payload.vehicle)
    : vehiclesAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const vehiclesAdapter = createEntityAdapter<Vehicle>({
  selectId: (vehicle) => vehicle.id,
  sortComparer: (a, b) => a.id - b.id,
});

export const vehicleSlice = createSlice({
  name: "vehicles",
  initialState: vehiclesAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    totalPages: 0,
    totalElements: 0,
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setVehicles: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      vehiclesAdapter.setMany(state, action.payload.vehicle);
    },
    setVehicle: (state, action: PayloadAction<any>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      vehiclesAdapter.setOne(state, action.payload.vehicle);
    },
    setNumberOfPages: (state, action: PayloadAction<number>) => {
      state.totalPages = action.payload;
    },
    setNumberOfElements: (state, action: PayloadAction<number>) => {
      state.totalElements = action.payload;
    },
    vehiclesEmptyState: (state) => {
      vehiclesAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // get vehicles details async extra reducers
      .addCase(
        getVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehiclesDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getVehiclesDetailsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getVehiclesDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehiclesDetailsPaginationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getVehiclesDetailsPaginationAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getVehicleDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getVehicleDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getVehicleDetailsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        updateVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        updateVehicleAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(updateVehicleAsync.pending, (state: any) => {
        state.status = "loading";
      })
      // extra reducers when activate vehicle API is called
      .addCase(activateVehicleAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        activateVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        activateVehicleAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      // extra reducers when deactivate vehicle API is called
      .addCase(deactivateVehicleAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        deactivateVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PATCH;
        }
      )
      .addCase(
        deactivateVehicleAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(
        getFilteredVehiclesDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(
        getFilteredVehiclesDetailsAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload);
        }
      )
      .addCase(getFilteredVehiclesDetailsAsync.pending, (state: any) => {
        state.status = "loading";
      })
      //#endregion Entity Reducers

      //#region External Entity Reducers
      // get vehicle async extra reducers
      .addCase(
        getVehicleAsync.fulfilled,
        (state, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      // get vehicles async extra reducers
      .addCase(
        getVehiclesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      .addCase(
        getFilteredVehiclesStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      // extra reducers when get filtered drivers details API is called
      // get drivers details async extra reducer
      .addCase(
        getDriverDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      // get drivers statuses async extra reducer
      .addCase(
        getDriversStatusesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      .addCase(
        getDriverStatusAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      .addCase(
        getFilteredDriversStatusAndDetailsPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
        }
      )
      // getRoutesHistoryAsync extra reducers
      .addCase(
        getRoutesHistoryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload?.vehicle) {
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          }
        }
      )
      // getVehicleTravelsAsync extra reducers
      // getDeadlinesAsync extra reducers
      .addCase(
        getDeadlinesAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload?.vehicle) {
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          }
        }
      )
      // getGeofencesReportsAsync extra reducers
      .addCase(
        getGeofencesReportsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          if (action.payload.vehicle) {
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
          }
        }
      )
      // getFilteredVehiclesStatusPaginationAsync extra reducers
      .addCase(
        getFilteredVehiclesStatusPaginationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicle
            ? vehiclesAdapter.setAll(state, action.payload.vehicle)
            : vehiclesAdapter.setAll(state, []);
        }
      )
      // getFleetAsync
      .addCase(
        getFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      )
      // getFleetAsync
      .addCase(
        getVehiclesStatusByFleetAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload?.vehicle &&
            vehiclesAdapter.upsertMany(state, action.payload.vehicle);
        }
      );
    //#endregion External Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const vehiclesSelectors = vehiclesAdapter.getSelectors<RootState>(
  (state) => state.vehicles
);

export const selectVehiclesSliceStatus = (state: any) => state.vehicles.status;
export const selectVehiclesSliceReasonCode = (state: any) =>
  state.vehicles.reasonCode;
export const selectVehiclesSlicePage = (state: any) =>
  state.vehicles.totalPages;
export const selectVehiclesSliceTotalElements = (state: any) =>
  state.vehicles.totalElements;
export const { vehiclesEmptyState, setNumberOfPages, setNumberOfElements } =
  vehicleSlice.actions;
//#endregion Status
export default vehicleSlice.reducer;
