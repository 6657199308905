import dayjs from "dayjs";
import { t } from "i18next";
import { useContext } from "react";
import { NavigateFunction } from "react-router-dom";
import { IconExternalLink } from "../../../ui/Icon/Line/ExternalLink";
import { IconTemperature } from "../../../ui/Icon/Line/Temperature";
import { IconUser } from "../../../ui/Icon/Line/User";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { getQueryString } from "../../../utils/Utils";
import { Driver } from "../../driver/driversSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import { SensorData } from "./analogicReportSensorDataSlice";

export class AnalogicReportSensorDataTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      SensorData?: SensorData;
    },
    navigate: NavigateFunction,
    threSholdMax: number,
    threSholdMin: number,
    vehicle: Vehicle,
    driver: Driver
  ) => {
    let row: Row = { render: {} as Row };

    let sensorSummary: SensorData = entities.SensorData ?? ({} as SensorData);
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    function renderThresholdAlert(temperature: number) {
      if (temperature > threSholdMax || temperature < threSholdMin) {
        return (
          <>
            <span
              style={{
                color: "#FF4F48",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {" "}
              <IconTemperature size={14} color={"#FF4F48"} />
              {temperature + " °"}
            </span>
          </>
        );
      } else {
        return (
          <>
            <span
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <IconTemperature size={14} color={"#687484"} />
              {temperature + " °"}
            </span>
          </>
        );
      }
    }

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "vehicle":
            row.render.vehicle = (
              <>
                <div className="table-report-archive-row-element">
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <p className="reportMarked">{vehicle.alias}</p>
                      <p className="reportNormal">{vehicle.model}</p>
                    </div>
                  </>
                </div>
              </>
            );
            break;
          case "driver":
            row.render.driver = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <IconUser size={14} color={"#687484"} />
                    {driver.firstName
                      ? driver.firstName + " " + driver.lastName
                      : t("common.na")}
                  </div>
                </div>
              </>
            );
            break;
          case "position":
            row.render.gpsDataAddress = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      maxWidth: "250px",
                    }}
                  >
                    {sensorSummary?.address}
                  </div>
                </div>
              </>
            );
            break;
          case "time":
            row.render.date = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {sensorSummary?.date && (
                      <>
                        {" "}
                        <p className="reportMarked">
                          {
                            dayjs(sensorSummary?.date)
                              .format(
                                preferencesContext.localeFormat?.replace(
                                  /\s/g,
                                  ""
                                ) + " HH:mm"
                              )
                              ?.split(" ")[1]
                          }
                        </p>
                        <p className="reportNormal">
                          {new Date(sensorSummary?.date).getDate() +
                            "/" +
                            (new Date(sensorSummary?.date).getMonth() + 1) +
                            "/" +
                            new Date(sensorSummary?.date).getFullYear()}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </>
            );
            break;
          case "key":
            row.render.ignitionKey = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.ignitionKey ? "ON" : "OFF"}
                  </div>
                </div>
              </>
            );
            break;
          case "batteryLevel":
            row.render.batteryLevel = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.batteryLevel ?? "N/A"}
                  </div>
                </div>
              </>
            );
            break;
          case "temperature":
            row.render.temperature = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.temperature
                      ? renderThresholdAlert(sensorSummary?.temperature)
                      : "N/A"}
                  </div>
                </div>
              </>
            );
            break;
          case "humidity":
            row.render.humidity = (
              <>
                <div
                  className="table-report-archive-row-element"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginRight: "30px",
                    }}
                  >
                    {sensorSummary?.humidity
                      ? sensorSummary?.humidity + " %"
                      : "N/A"}
                  </div>
                  <div
                    onClick={() => {
                      window.open(
                        sensorSummary?.routeId !== null
                          ? `/dashboard/vehicles/location-history/${
                              sensorSummary.routeType === "TRACK"
                                ? "track"
                                : "stop"
                            }/${sensorSummary?.routeId}` +
                              getQueryString({
                                firstPointDate: [
                                  dayjs(
                                    new Date(sensorSummary.date).setDate(
                                      new Date(sensorSummary.date).getDate() - 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                  dayjs(
                                    new Date(sensorSummary.date).setDate(
                                      new Date(sensorSummary.date).getDate() + 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                ],
                                routeStateTypeEnum: "TRACK",
                                "vehicle.id": sensorSummary.vehicleId,
                                currentPos: sensorSummary.date,
                              })
                          : ""
                      );
                    }}
                  >
                    <IconExternalLink size={14} color={"#35465B"} />
                  </div>
                </div>
              </>
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
