import { store } from "../app/store";
import { driversSelectors } from "../features/driver/driversSlice";
import { fleetViewsSelectors } from "../features/fleet/fleetViewsSlice";
import { vehiclesSelectors } from "../features/vehicle/vehiclesSlice";

export const excludePaths = [
  "/",
  "dashboard",
  "dashboard/fleet-control/vehicle/:id",
  "dashboard/fleet-control/vehicle",
  "dashboard/drivers/details",
  "dashboard/drivers/location-history/summary",
  "dashboard/drivers/location-history/tracks",
  "dashboard/drivers/location-history/track",
  "dashboard/drivers/location-history/stop",
  "dashboard/drivers/location-history/track-details",
  "dashboard/drivers/location-history/stop-details",
  "dashboard/drivers/location-history/track/:id",
  "dashboard/drivers/location-history/stop/:id",
  "dashboard/vehicles/details",
  "dashboard/vehicles/location-history/summary",
  "dashboard/vehicles/location-history/tracks",
  "dashboard/vehicles/location-history/track",
  "dashboard/vehicles/location-history/stop",
  "dashboard/vehicles/location-history/track-details",
  "dashboard/vehicles/location-history/stop-details",
  "dashboard/vehicles/location-history/track/:id",
  "dashboard/vehicles/location-history/stop/:id",
  "dashboard/maintenance/new-maintenance",
  "dashboard/maintenance/new-deadline",
  "dashboard/maintenance/view-edit",
  "admin",
  "admin/vehicles/edit",
  "admin/vehicles/edit/:fleetId",
  "admin/vehicles/view",
  "admin/vehicles/view/:fleetId",
  "admin/drivers/view",
  "admin/drivers/edit",
  "admin/geofences/edit-geofence/:geofenceId",
  "account-settings/edit-account",
  "account-settings/edit-account/view",
  "account-settings/edit-account/edit",
  "reports/driver-travel-report/:driverId",
  "reports/vehicle-travel-report/:vehicleId",
  "reports/analogic-report/:reportId",
  "reports/analogic-report/:reportId/details/:summaryId",
  "admin/users/view/:id",
  "admin/users/edit/:id",
  "dashboard/fleets/:fleetId/vehicles/:id",
];

export const routes = [
  {
    path: "dashboard/drivers/details/:id",
    breadcrumb: ({ match }) =>
      driversSelectors.selectById(store.getState(), match.params.id) !==
      undefined
        ? driversSelectors.selectById(store.getState(), match.params.id)
            .firstName +
          " " +
          driversSelectors.selectById(store.getState(), match.params.id)
            .lastName
        : "",
  },
  {
    path: "dashboard/fleets/:fleetId/vehicles/:id/details",
    breadcrumb: ({ match }) =>
      vehiclesSelectors.selectById(store.getState(), match.params.id) !==
      undefined
        ? vehiclesSelectors.selectById(store.getState(), match.params.id).alias
        : "",
  },
  {
    path: "dashboard/fleets/:fleetId",
    breadcrumb: ({ match }) =>
      fleetViewsSelectors.selectById(store.getState(), match.params.fleetId) !==
      undefined
        ? fleetViewsSelectors.selectById(store.getState(), match.params.fleetId)
            .name
        : "",
  },
  {
    path: "admin/vehicles/view/:fleetId/:id",
    breadcrumb: ({ match }) =>
      vehiclesSelectors.selectById(store.getState(), match.params.id) !==
      undefined
        ? vehiclesSelectors.selectById(store.getState(), match.params.id)
            .alias +
          " - " +
          vehiclesSelectors.selectById(store.getState(), match.params.id).plate
        : "",
  },
  {
    path: "admin/drivers/view/:id",
    breadcrumb: ({ match }) =>
      driversSelectors.selectById(store.getState(), match.params.id) !==
      undefined
        ? driversSelectors.selectById(store.getState(), match.params.id)
            .firstName +
          " " +
          driversSelectors.selectById(store.getState(), match.params.id)
            .lastName
        : "",
  },
  {
    path: "admin/vehicles/edit/:fleetId/:id",
    breadcrumb: ({ match }) =>
      vehiclesSelectors.selectById(store.getState(), match.params.id) !==
      undefined
        ? vehiclesSelectors.selectById(store.getState(), match.params.id)
            .alias +
          " - " +
          vehiclesSelectors.selectById(store.getState(), match.params.id).plate
        : "",
  },
  {
    path: "admin/drivers/edit/:id",
    breadcrumb: ({ match }) =>
      driversSelectors.selectById(store.getState(), match.params.id) !==
      undefined
        ? driversSelectors.selectById(store.getState(), match.params.id)
            .firstName +
          " " +
          driversSelectors.selectById(store.getState(), match.params.id)
            .lastName
        : "",
  },
];
