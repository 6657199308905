import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import { Switch } from "../../ui/Forms/Switch";
import TextField from "../../ui/Forms/TextField";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { ElementType } from "../../ui/Group/ElementTypeEnum";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { Check } from "../../ui/Indicators/Check";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { TabSection } from "../../ui/Tabs/TabSection";
import { Tabs } from "../../ui/Tabs/Tabs";
import { Tag } from "../../ui/Tags/Tag";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { DataCommon } from "../../utils/Common";
import { getFullDate } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  getIllustrationFromVehicleType,
  getQueryString,
} from "../../utils/Utils";
import {
  ValidationChannelEnum,
  ValidationType,
  emptyStatusAndReasonCode,
  selectValidationsSliceReasonCode,
  selectValidationsSliceStatus,
  sendValidationAsync,
  validationsEmptyState,
  validationsSelectors,
} from "../../validation/validationSlice";
import { AdminPrivilegesResourceTab } from "../components/AdminPrivilegesResourceTab";
import { Driver, driversSelectors } from "../driver/driversSlice";
import {
  DriverStatus,
  driversStatusSelectors,
  getFilteredDriversStatusAndDetailsAsync,
  selectDriverStatusSliceReasonCode,
  selectDriverStatusSliceStatus,
} from "../driver/driversStatusSlice";
import { ResourceProps } from "../fleet/AdminFleetsResourceTab";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
  selectGeofencesSliceReasonCode,
  selectGeofencesSliceStatus,
} from "../geofence/geofenceSlice";
import {
  GeofenceCategory,
  geofenceCategoriesSelectors,
  getGeofenceCategoriesAsync,
  selectGeofenceCategoriesSliceReasonCode,
  selectGeofenceCategoriesSliceStatus,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import {
  VehicleStatus,
  getVehiclesAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusSelectors,
} from "../vehicle/vehiclesStatusSlice";
import "./AdminUserManagement.css";
import { Preferences } from "./preference/preferencesSlice";
import {
  PrivilegesDefault,
  getPrivilegesDefaultAsync,
  privilegesDefaultSelectors,
} from "./privilege/privilegesDefaultSlice";
import {
  UserPermissions,
  privilegesEmptyState,
  selectPrivilegesSliceReasonCode,
  selectPrivilegesSliceStatus,
} from "./privilege/privilegesSlice";
import { securityProfilesEmptyState } from "./securityProfile/securityProfilesSlice";
import UserContext from "./userContext";
import {
  UserInfo,
  getUserInfoAsync,
  selectUsersInfoSliceReasonCode,
  selectUsersInfoSliceStatus,
  userInfoSelectors,
  usersInfoEmptyState,
} from "./usersInfoSlice";

interface ViewUserProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

let fetchUsers: (viewUserRoute: any) => any;
let userPolling: any;
const ViewUser: React.FC<ViewUserProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const viewUserRoute = useMatch("/admin/users/view/:userId");

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);

  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [isNumberValidated, setIsNumberValidated] = useState(false);

  const [numberCountdown, setNumberCountdown] = useState<number>(0);
  const [emailCountdown, setEmailCountdown] = useState<number>(0);
  const [isNumberExpired, setIsNumberExpired] = useState<boolean>(false);
  const [isEmailExpired, setIsEmailExpired] = useState<boolean>(false);

  const [id, setId] = useState<number>(-1);
  const phonePrefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);

  const userInfo: UserInfo =
    useAppSelector((state) => userInfoSelectors.selectById(state, id)) ??
    ({} as UserInfo);
  const userSliceStatus = useAppSelector(selectUsersInfoSliceStatus);
  const userSliceReasonCode = useAppSelector(selectUsersInfoSliceReasonCode);

  const validation: ValidationType[] = useAppSelector((state: any) =>
    validationsSelectors.selectAll(state)
  );

  const validationSliceStatus = useAppSelector(selectValidationsSliceStatus);
  const validationSliceReasonCode = useAppSelector(
    selectValidationsSliceReasonCode
  );

  let privilegesDefault: PrivilegesDefault[] =
    useAppSelector((state: any) =>
      privilegesDefaultSelectors.selectAll(state)
    ) ?? ({} as PrivilegesDefault);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const privilegesSliceStatus = useAppSelector(selectPrivilegesSliceStatus);
  const privilegesSliceReasonCode = useAppSelector(
    selectPrivilegesSliceReasonCode
  );
  const [vehiclesCanSee, setVehiclesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );
  const [driversCanSee, setDriversCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );
  const [geofencesCanSee, setGeofencesCanSee] = useState<ResourceProps[]>(
    [] as ResourceProps[]
  );

  document.title = useMemo(() => {
    if (userInfo?.username) {
      return (
        `${userInfo?.username} - ` +
        t("admin.user.editViewCreate.editAccount") +
        " - Admin"
      );
    } else {
      return t("admin.user.editViewCreate.editAccount") + " - Admin";
    }
  }, [userInfo]);

  //#region vehicles variables
  const [vehicesCannotSee, setVehiclesCannotSee] = useState(
    [] as ResourceProps[]
  );

  let vehicles: Vehicle[] = useAppSelector((state) =>
    vehiclesSelectors.selectAll(state)
  );

  let vehiclesStatus: VehicleStatus[] = useAppSelector((state) =>
    vehiclesStatusSelectors.selectAll(state)
  );
  const vehiclesStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehiclesStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded vehicles
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      vehicles.length > 0 &&
      vehiclesStatus.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const vehiclesForPrivilegesTab = vehicles.map((vehicle) => {
        const vehicleStatus = vehiclesStatus.find((x) => x.id === vehicle.id);
        return {
          key: vehicle.id,
          id: vehicle.id,
          firstTitle: vehicle.alias,
          secondTitle: vehicle.plate,
          firstSubtitle: vehicle.status,
          secondSubtitle: getFullDate(
            vehicleStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.vehicle,
          icon: getIllustrationFromVehicleType(vehicle),
        } as ResourceProps;
      });
      if (vehiclesForPrivilegesTab.length > 0 && permissions.vehicles.write) {
        const vehiclesPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Vehicles")
          .map((x) => x.entityId);
        const vehiclesCannotSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
          return !vehiclesPrivileges.includes(el.id);
        });

        setVehiclesCannotSee(
          vehiclesCannotSeeTemp.length > 0
            ? vehiclesCannotSeeTemp
            : vehiclesForPrivilegesTab
        );

        let vehiclesCanSeeTemp = vehiclesForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Vehicles" &&
              el.id === privilege.entityId
          );
        });

        setVehiclesCanSee(vehiclesCanSeeTemp);
      }
    }
  }, [vehicles, vehiclesStatus, userInfo]);
  //#endregion

  //#region drivers variables
  const [driversCannotSee, setDriversCannotSee] = useState(
    [] as ResourceProps[]
  );
  let drivers: Driver[] = useAppSelector((state) =>
    driversSelectors.selectAll(state)
  );

  let driversStatus: DriverStatus[] = useAppSelector((state) =>
    driversStatusSelectors.selectAll(state)
  );
  const driversStatusSliceStatus = useAppSelector(
    selectDriverStatusSliceStatus
  );
  const driversStatusSliceReasonCode = useAppSelector(
    selectDriverStatusSliceReasonCode
  );

  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded drivers
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      drivers.length > 0 &&
      driversStatus.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const driversForPrivilegesTab = drivers.map((driver) => {
        const driverStatus = driversStatus.find((x) => x.id === driver.id);
        return {
          key: driver.id,
          id: driver.id,
          firstTitle: `${driver.firstName} ${driver.lastName}`,
          firstSubtitle: driver.status,
          secondSubtitle: getFullDate(
            driverStatus?.dynamicFields?.lastUpdate,
            false
          ),
          type: ElementType.driver,
          icon: (
            <ThumbProfile
              size="small"
              alt="Driver Thumbnail"
              imgUrl={driver.avatar}
            />
          ),
        } as ResourceProps;
      });
      if (driversForPrivilegesTab.length > 0 && permissions.drivers.write) {
        const driversPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Drivers")
          .map((x) => x.entityId);
        const driversCannotSeeTemp = driversForPrivilegesTab.filter((el) => {
          return !driversPrivileges.includes(el.id);
        });

        setDriversCannotSee(
          driversCannotSeeTemp.length > 0
            ? driversCannotSeeTemp
            : driversForPrivilegesTab
        );

        let driverCanSeeTemp = driversForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Drivers" && el.id === privilege.entityId
          );
        });

        setDriversCanSee(driverCanSeeTemp);
      }
    }
  }, [drivers, driversStatus, userInfo]);
  //#endregion

  //#region geofences variables
  const [geofencesCannotSee, setGeofencesCannotSee] = useState(
    [] as ResourceProps[]
  );
  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );
  const geofenceCategoriesSliceStatus = useAppSelector(
    selectGeofenceCategoriesSliceStatus
  );
  const geofenceCategoriesSliceReasonCode = useAppSelector(
    selectGeofenceCategoriesSliceReasonCode
  );

  const geofencesSliceStatus = useAppSelector(selectGeofencesSliceStatus);
  const geofencesSliceReasonCode = useAppSelector(
    selectGeofencesSliceReasonCode
  );
  /**
   * This useEffect separates into two list (can see and cannot se) the downloaded geofences
   * on the basis of the current editing user privileges.
   */
  useEffect(() => {
    if (
      geofences.length > 0 &&
      geofenceCategories.length > 0 &&
      !_.isEmpty(userInfo)
    ) {
      const geofencesForPrivilegesTab = geofences.map((geofence) => {
        const geofenceCategory = geofenceCategories.find(
          (x) => x.id === geofence.geofenceCategory
        );
        const resourceProp = {
          key: geofence.id,
          id: geofence.id,
          firstTitle: geofence.name,
          secondTitle: geofenceCategory?.name,
          type: ElementType.geofence,
          icon: getIllustrationFromVehicleType(geofence),
          categoryColor: geofenceCategory?.color,
        } as ResourceProps;
        return resourceProp;
      });
      if (geofencesForPrivilegesTab.length > 0 && permissions.geofences.write) {
        const geofencesPrivileges = userInfo.privileges
          .filter((x) => x.entityType === "Geofences")
          .map((x) => x.entityId);
        const geofencesCannotSeeTemp = geofencesForPrivilegesTab.filter(
          (el) => {
            return !geofencesPrivileges.includes(el.id);
          }
        );

        setGeofencesCannotSee(
          geofencesCannotSeeTemp.length > 0
            ? geofencesCannotSeeTemp
            : geofencesForPrivilegesTab
        );
        let geofencesCanSeeTemp = geofencesForPrivilegesTab.filter((el) => {
          return userInfo.privileges.some(
            (privilege) =>
              privilege.entityType === "Geofences" &&
              el.id === privilege.entityId
          );
        });

        setGeofencesCanSee(geofencesCanSeeTemp);
      }
    }
  }, [geofences, geofenceCategories, userInfo]);
  //#endregion

  //#region Toast notifications
  useEffect(() => {
    if (userSliceStatus === "failed") {
      switch (userSliceReasonCode) {
        case GTFleetErrorCodes.USERNAME_NOT_FOUND:
          ToastNotification({
            toastId: "userNotFoundError",
            status: "error",
            description: t("common.userNotFoundError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/users");
    }
  }, [userSliceStatus, userSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      privilegesSliceStatus === "failed" &&
      privilegesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [privilegesSliceStatus, privilegesSliceReasonCode]);

  useEffect(() => {
    if (
      driversStatusSliceStatus === "failed" &&
      driversStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [driversStatusSliceStatus, driversStatusSliceReasonCode]);

  useEffect(() => {
    if (
      vehiclesStatusSliceStatus === "failed" &&
      vehiclesStatusSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehiclesStatusSliceStatus, vehiclesStatusSliceReasonCode]);

  useEffect(() => {
    if (geofenceCategoriesSliceStatus === "failed") {
      if (geofenceCategoriesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofenceCategoriesSliceStatus, geofenceCategoriesSliceReasonCode]);

  useEffect(() => {
    if (geofencesSliceStatus === "failed") {
      if (geofencesSliceReasonCode === "") {
        ToastNotification({
          toastId: "networkError",
          status: "error",
          description: t("common.networkError"),
        });
      }
    }
  }, [geofencesSliceStatus, geofencesSliceReasonCode]);
  useEffect(() => {
    if (
      validationSliceStatus === "idle" &&
      validationSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "userValidationSendSuccess",
        status: "success",
        description: t("validation.validationSendSuccess"),
      });
    }
    if (
      validationSliceStatus === "failed" &&
      validationSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    store.dispatch(emptyStatusAndReasonCode());
  }, [validationSliceStatus, validationSliceReasonCode]);
  //#endregion toast notifications

  //#region Upload Data
  useEffect(() => {
    if (viewUserRoute !== null) {
      if (viewUserRoute.params.userId) {
        setId(parseInt(viewUserRoute.params.userId));
        store.dispatch(
          getUserInfoAsync({
            id: Number(viewUserRoute.params.userId),
          })
        );
        return;
      }
    }
  }, [navigate, viewUserRoute]);

  useEffect(() => {
    if (
      privilegesDefault.some((x) => x.entityType === "Vehicles") &&
      permissions.vehicles.read
    ) {
      store.dispatch(getVehiclesAsync());
    }
    if (
      privilegesDefault.some((x) => x.entityType === "Drivers") &&
      permissions.drivers.read
    ) {
      store.dispatch(getFilteredDriversStatusAndDetailsAsync(""));
    }
    if (
      privilegesDefault.some((x) => x.entityType === "Geofences") &&
      permissions.geofences.read
    ) {
      store.dispatch(
        getGeofencesAsync({ queryParams: getQueryString({ status: "ACTIVE" }) })
      );
      store.dispatch(getGeofenceCategoriesAsync());
    }
  }, [privilegesDefault]);

  //#endregion

  //#region security profile - fuction
  let write: string[] = [];
  let read: string[] = [];

  useEffect(() => {
    document.title = t("admin.user.editViewCreate.viewAccount") + " - Admin";
    return function cleanUp() {
      store.dispatch(privilegesEmptyState());
      store.dispatch(usersInfoEmptyState());
      store.dispatch(securityProfilesEmptyState());
    };
  }, []);

  useEffect(() => {
    if (userInfo.securityProfile?.id !== undefined) {
      store.dispatch(
        getPrivilegesDefaultAsync({
          id: userInfo.securityProfile?.id,
        })
      );
    }
  }, [userInfo.securityProfile?.id]);

  privilegesDefault.forEach((privilegeDefault) => {
    if (
      privilegeDefault.permission === "WRITE" &&
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.write
    ) {
      write.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    } else if (
      permissions[
        privilegeDefault.entityType.toLowerCase() as keyof UserPermissions
      ]?.read
    ) {
      read.push(t("admin.user.editViewCreate." + privilegeDefault.entityType));
    }
  });
  //#endregion

  //#region Render

  const translateSecurityProfiles = (securityProfile: string) => {
    switch (securityProfile) {
      case "FLEET_MANAGER":
        return t("admin.user.profile.fleet");
      case "MAINTENANCE_MANAGER":
        return t("admin.user.profile.maintenance");
      case "DRIVER":
        return t("admin.user.profile.driver");
      case "BASIC":
        return t("admin.user.profile.basic");
      default:
        return t("admin.user.profile.unknown");
    }
  };
  //#endregion

  //#region validation
  /**
   * This function dispacth a call to get all status message from id
   * and set timer for polling
   */
  fetchUsers = (viewUserRoute: any) => {
    store.dispatch(
      getUserInfoAsync({
        id: Number(viewUserRoute.params.userId),
      })
    );

    userPolling = setTimeout(() => fetchUsers(viewUserRoute), 60000);
  };

  useEffect(() => {
    fetchUsers(viewUserRoute);
    return function cleanup() {
      clearTimeout(userPolling);
      store.dispatch(validationsEmptyState());
    };
  }, []);

  //#region tooltip for code validation
  const [emailTooltip, setEmailTooltip] = useState("");
  const [numberTooltip, setNumberTooltip] = useState("");

  const formatCountdown = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    setIsEmailValidated(userInfo.emailValidated);
    setIsNumberValidated(userInfo.numberValidated);

    const emailExpirationDate =
      validation[0]?.channelType === ValidationChannelEnum.EMAIL &&
      validation[0]?.validationDate
        ? new Date(validation[0].validationDate)
        : userInfo.emailValidationExpirationDate &&
          new Date(userInfo.emailValidationExpirationDate);
    const emailTimer = setInterval(() => {
      const currentEmailTime = new Date();
      const timeEmailDifference =
        emailExpirationDate?.getTime() - currentEmailTime?.getTime();
      if (timeEmailDifference > 0) {
        setEmailCountdown(Math.floor(timeEmailDifference / 1000));
      } else {
        setIsEmailExpired(true);
        clearInterval(emailTimer);
      }
    }, 1000);

    const numberExpirationDate =
      validation[0]?.channelType === ValidationChannelEnum.PHONE &&
      validation[0]?.validationDate
        ? new Date(validation[0].validationDate)
        : userInfo.emailValidationExpirationDate &&
          new Date(userInfo.numberValidationExpirationDate);
    const numberTimer = setInterval(() => {
      const currentNumberTime = new Date();
      const timeNumberDifference =
        numberExpirationDate?.getTime() - currentNumberTime?.getTime();
      if (timeNumberDifference > 0) {
        setNumberCountdown(Math.floor(timeNumberDifference / 1000));
      } else {
        setIsNumberExpired(true);
        clearInterval(numberTimer);
      }
    }, 1000);

    return () => {
      clearInterval(emailTimer);
      clearInterval(numberTimer);
    };
  }, [userInfo, validation]);

  useEffect(() => {
    setEmailTooltip(
      `${t("validation.progressValidation")} ${formatCountdown(
        emailCountdown
      )} min`
    );
  }, [emailCountdown]);

  useEffect(() => {
    setNumberTooltip(
      `${t("validation.progressValidation")} ${formatCountdown(
        numberCountdown
      )} min`
    );
  }, [numberCountdown]);
  //#endregion

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <div className="admin-user-subtopbar">
            <div className="admin-user-subsection-name">
              <div className="admin-user-profile-icon">
                <ThumbProfile
                  size="mediumsmall"
                  alt="User Thumbnail"
                  imgUrl={userInfo.profilePicture}
                />
                <span className="admin-user-profile-text">
                  {userInfo.firstName + " " + userInfo.secondName}
                </span>
              </div>
            </div>
            <div className="admin-user-buttons">
              <Button
                size="small"
                aspect="secondary"
                label={t("admin.user.editViewCreate.allUser")}
                onClick={() => navigate("/admin/users")}
              />
              {permissions.users.write && (
                <Button
                  size="small"
                  aspect="primary"
                  label={t("common.edit")}
                  onClick={() => {
                    navigate("/admin/users/edit/" + id);
                  }}
                >
                  <IconEdit size={14} color="--global-colors-ui-white" />
                </Button>
              )}
            </div>
          </div>
        </div>
      </PageFilters>
      <PageContent>
        <div className="admin-user-page-container">
          {
            //#region General
          }
          <div className="admin-user-info-form">
            <div className="admin-user-forms-title">
              {t("admin.driver.editViewCreate.general")}
            </div>

            <div className="admin-user-forms-container">
              <div className="admin-user-half-form">
                <div className="admin-user-left-forms-label">
                  {t("common.name")} *
                </div>
                <Form>
                  <div className="admin-user-forms">
                    <div className="admin-user-left-form-item admin-user-only-view">
                      <TextField
                        id="Name"
                        validate="alphabetic|isNotEmpty"
                        placeholder={
                          userInfo.firstName ? t("common.name") : t("common.na")
                        }
                        value={userInfo.firstName ?? null}
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="admin-user-half-form">
                <div className="admin-user-right-forms-label">
                  {t("common.surname")} *
                </div>
                <Form>
                  <div className="admin-user-forms">
                    <div className="admin-user-right-form-item admin-user-only-view">
                      <TextField
                        id="Surname"
                        validate="alphabetic|isNotEmpty"
                        placeholder={
                          userInfo.secondName
                            ? t("common.surname")
                            : t("common.na")
                        }
                        value={userInfo.secondName ?? null}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="admin-user-forms-container">
              <div className="admin-user-left-forms-label">
                {t("common.username")} *
              </div>
              <Form>
                <div className="admin-user-forms">
                  <div className="left-form admin-user-only-view">
                    <TextField
                      id="Username"
                      placeholder={
                        userInfo.username
                          ? t("common.username")
                          : t("common.na")
                      }
                      validate="isNotEmpty"
                      value={userInfo.username ?? null}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="admin-user-forms-container">
              <div className="admin-user-left-forms-label">
                {t("common.email")} *
              </div>
              <Form>
                <div className="admin-user-forms">
                  <div className="admin-user-left-form-text-field admin-user-only-view">
                    <TextField
                      id="driver-email"
                      validate="email|isNotEmpty"
                      type="email"
                      placeholder={
                        userInfo.email ? t("common.email") : t("common.na")
                      }
                      value={userInfo.email ?? null}
                    >
                      {isEmailExpired && !isEmailValidated && (
                        <>
                          <Tooltip />
                          <span
                            data-for="tooltip"
                            data-tooltip-delay-hide={1000}
                            data-tip={t("validation.expiredValidation")}
                          >
                            <Check status="failed" size="small" />
                          </span>
                        </>
                      )}
                      {isEmailValidated && (
                        <Check status="success" size="small" />
                      )}
                    </TextField>
                  </div>
                  <div className="admin-user-validate-button">
                    {!isEmailValidated && emailCountdown !== 0 && (
                      <Tooltip id="emailTooltip" />
                    )}
                    <span
                      data-for={
                        !isEmailValidated && emailCountdown !== 0
                          ? "emailTooltip"
                          : ""
                      }
                      data-tooltip-delay-hide={1000}
                      data-tip={emailTooltip}
                    >
                      <Button
                        size="regular"
                        aspect="primary"
                        disabled={
                          userInfo.email === "" ||
                          !isEmailExpired ||
                          isEmailValidated
                        }
                        label={t("common.validate")}
                        onClick={() => {
                          setOpenEmailModal(true);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Form>
            </div>
            <div className="admin-user-forms-container">
              <div className="admin-user-left-forms-label">
                {t("common.phone")}
              </div>
              <Form>
                <div className="admin-user-forms">
                  <div className="admin-user-left-form-phone">
                    <div className="admin-user-drpdwn-small admin-user-only-view">
                      <Dropdown
                        isClearable={false}
                        placeholder={
                          userInfo.prefix ? t("common.phone") : t("common.na")
                        }
                        value={{
                          label: userInfo.prefix ?? undefined,
                        }}
                        size="normal"
                        itemAttribute="label"
                        onChange={(val) => {
                          console.log(val);
                        }}
                        options={phonePrefixList}
                      />
                    </div>
                  </div>
                  <div className="admin-user-right-form-phone admin-user-only-view">
                    <TextField
                      id="Number"
                      validate="phoneNumber"
                      placeholder={
                        userInfo.telephone ? t("common.phone") : t("common.na")
                      }
                      value={userInfo.telephone ?? null}
                    >
                      {isNumberExpired && !isNumberValidated && (
                        <>
                          <Tooltip id="numberTooltip" />
                          <span
                            data-for="numberTooltip"
                            data-tooltip-delay-hide={1000}
                            data-tip={t("validation.expiredValidation")}
                          >
                            <Check status="failed" size="small" />
                          </span>
                        </>
                      )}
                      {isNumberValidated && (
                        <Check status="success" size="small" />
                      )}
                    </TextField>
                  </div>
                  <div className="admin-user-validate-button">
                    {!isNumberValidated && numberCountdown !== 0 && <Tooltip />}
                    <span
                      data-for={
                        !isNumberValidated && numberCountdown !== 0
                          ? "tooltip"
                          : ""
                      }
                      data-tooltip-delay-hide={1000}
                      data-tip={numberTooltip}
                    >
                      <Button
                        size="regular"
                        aspect="primary"
                        disabled={
                          userInfo.telephone === "" ||
                          !isNumberExpired ||
                          isNumberValidated
                        }
                        label={t("common.validate")}
                        onClick={() => {
                          setOpenSmsModal(true);
                        }}
                      />
                    </span>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Security
          }
          <div className="admin-user-info-form">
            <div className="admin-user-forms-title">
              {t("admin.user.editViewCreate.security")}
            </div>

            <div className="admin-user-forms-container">
              <div className="admin-user-half-form">
                <div className="admin-user-left-forms-label">
                  {t("common.password")} *
                </div>
                <Form>
                  <div className="admin-user-forms">
                    <div className="admin-user-left-form-item admin-user-only-view">
                      <TextField
                        id="Password"
                        placeholder={t("common.password")}
                        value="********"
                        type="password"
                      />
                    </div>
                  </div>
                </Form>
              </div>
              <div className="admin-user-half-form">
                <div className="admin-user-right-forms-label">
                  {t("common.repeatPassword")} *
                </div>
                <Form>
                  <div className="admin-user-forms">
                    <div className="admin-user-right-form-item admin-user-only-view">
                      <TextField
                        id="Repeatpassword"
                        placeholder={t(
                          "admin.user.editViewCreate.repeatPassword"
                        )}
                        value="********"
                        type="password"
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="admin-user-forms-container">
              <Tooltip />
              <span
                data-for="tooltip"
                data-tip={t("common.warningActivationDate")}
              >
                <div className="admin-user-left-forms-label">
                  {t("admin.user.editViewCreate.activationDate")}
                </div>
              </span>
              <Form>
                <div className="admin-user-forms">
                  <div className="admin-user-left-form-item admin-user-only-view">
                    <TextField
                      id="activationDate"
                      placeholder={
                        userInfo.activationDate
                          ? t("common.phone")
                          : t("common.na")
                      }
                      value={
                        dayjs(userInfo.activationDate).format(
                          preferencesContext.localeFormat
                        ) ?? null
                      }
                    >
                      <IconCalendar
                        size={14}
                        color="--global-colors-stuserInfoatus-stopped"
                      />
                    </TextField>
                  </div>
                </div>
              </Form>
            </div>
            <div className="admin-user-forms-container">
              <div className="admin-user-left-forms-label">
                {t("admin.user.editViewCreate.expirationDate")}
              </div>
              <Form>
                <div className="admin-user-forms">
                  <div className="admin-user-left-form-item admin-user-only-view">
                    <TextField
                      id="expirationDate"
                      placeholder={
                        userInfo.expirationDate
                          ? t("admin.user.editViewCreate.expirationDate")
                          : t("common.na")
                      }
                      value={
                        userInfo.expirationDate
                          ? dayjs(userInfo.expirationDate).format(
                              preferencesContext.localeFormat
                            )
                          : `${t("admin.user.editViewCreate.never")}`
                      }
                    >
                      <IconCalendar
                        size={14}
                        color="--global-colors-status-stopped"
                      />
                    </TextField>
                  </div>
                </div>
              </Form>
            </div>
          </div>
          {
            //#endregion
          }

          {
            //#region Profile
          }
          <div className="admin-user-info-form">
            <div className="admin-user-forms-title">
              {t("admin.user.editViewCreate.profile")}
            </div>

            <div className="admin-user-forms-container">
              <div className="admin-user-left-forms-label">
                {t("admin.user.editViewCreate.role")}
              </div>
              <Form>
                <div className="admin-user-forms">
                  <div className="admin-user-left-form-item admin-user-only-view">
                    <Dropdown
                      isClearable={false}
                      placeholder={
                        userInfo.securityProfile?.name &&
                        translateSecurityProfiles(
                          userInfo.securityProfile?.name
                        )
                          ? t("admin.user.editViewCreate.role")
                          : t("common.na")
                      }
                      value={{
                        label:
                          (userInfo.securityProfile?.name &&
                            translateSecurityProfiles(
                              userInfo.securityProfile?.name
                            )) ??
                          undefined,
                      }}
                      size="normal"
                      itemAttribute="label"
                      onChange={(val) => {
                        console.log(val);
                      }}
                      options={[]}
                    />
                  </div>
                </div>
              </Form>
            </div>
            <div className="admin-user-forms-container admin-user-forms-function">
              <div className="admin-user-left-forms-label">
                {t("admin.user.editViewCreate.functions")}
              </div>
              <Form className="form-function">
                <div className="admin-user-forms">
                  <div className="admin-user-left-form-item admin-user-user">
                    {write.length > 0 ? (
                      <div className="admin-user-onblock">
                        <div
                          className="mn-label-text__text mn-label-text__text-function"
                          key="write"
                        >
                          {t("admin.user.editViewCreate.read&Write")}
                        </div>
                        <div className="admin-user-user-fuction admin-user-user-fuction-first">
                          {write.map((privilege) => {
                            return (
                              <Tag
                                key={"write_" + privilege}
                                text={privilege}
                                mode="outline"
                                type="neutro"
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                    {read.length > 0 ? (
                      <div className="admin-user-onblock">
                        <div
                          className="mn-label-text__text mn-label-text__text-function"
                          key="read"
                        >
                          {t("admin.user.editViewCreate.read")}
                        </div>
                        <div className="admin-user-user-fuction admin-user-user-fuction-second">
                          {read.map((privilege) => {
                            return (
                              <Tag
                                key={"read_" + privilege}
                                text={privilege}
                                mode="outline"
                                type="neutro"
                              />
                            );
                          })}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Form>
            </div>
            {privilegesDefault.length > 0 && (
              <div className="admin-user-forms-container admin-user-forms-function">
                <div className="admin-user-left-forms-label">
                  {t("admin.user.editViewCreate.managePermissions")}
                </div>
                <Form className="form-function">
                  <div className="admin-user-forms">
                    <div className="admin-user-left-form-item admin-user-user">
                      <Switch
                        checked={
                          userInfo.privileges?.length
                            ? userInfo.privileges?.length > 0
                            : false
                        }
                        disabled={true}
                        label=""
                      />
                    </div>
                  </div>
                </Form>
              </div>
            )}
            {userInfo.privileges?.length > 0
              ? (vehicesCannotSee.length > 0 ||
                  driversCannotSee.length > 0 ||
                  geofencesCannotSee.length > 0) && (
                  <div className="privileges-tabs-container">
                    <Tabs enableTab="Vehicles">
                      {privilegesDefault.some(
                        (privilegeDefault) =>
                          privilegeDefault.entityType === "Vehicles"
                      ) && permissions.vehicles.read ? (
                        <TabSection label="Vehicles">
                          <AdminPrivilegesResourceTab
                            tabType="vehicles"
                            customTitleFirstGroup={t(
                              "admin.user.editViewCreate.customTitleFirstGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            customTitleSecondGroup={t(
                              "admin.user.editViewCreate.customTitleSecondGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            rightGroupProp={vehicesCannotSee}
                            leftGroupProp={vehiclesCanSee}
                            leftButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelRemove"
                            )}
                            rightButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelAdd"
                            )}
                            output={() => {}}
                            disabled={true}
                          />
                        </TabSection>
                      ) : null}
                      {privilegesDefault.some(
                        (privilegeDefault) =>
                          privilegeDefault.entityType === "Drivers"
                      ) && permissions.drivers.read ? (
                        <TabSection label="Drivers">
                          {
                            <AdminPrivilegesResourceTab
                              tabType="drivers"
                              customTitleFirstGroup={t(
                                "admin.user.editViewCreate.customTitleFirstGroup"
                              ).replace(
                                "$$",
                                userInfo.username ?? t("common.user")
                              )}
                              customTitleSecondGroup={t(
                                "admin.user.editViewCreate.customTitleSecondGroup"
                              ).replace(
                                "$$",
                                userInfo.username ?? t("common.user")
                              )}
                              rightGroupProp={driversCannotSee}
                              leftGroupProp={driversCanSee}
                              leftButtonText={t(
                                "admin.groups.editGroups.widgetButtonLabelRemove"
                              )}
                              rightButtonText={t(
                                "admin.groups.editGroups.widgetButtonLabelAdd"
                              )}
                              output={() => {}}
                              disabled={true}
                            />
                          }
                        </TabSection>
                      ) : null}
                      {privilegesDefault.some(
                        (privilegeDefault) =>
                          privilegeDefault.entityType === "Geofences"
                      ) && permissions.geofences.read ? (
                        <TabSection label="Geofences">
                          <AdminPrivilegesResourceTab
                            customTitleFirstGroup={t(
                              "admin.user.editViewCreate.customTitleFirstGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            customTitleSecondGroup={t(
                              "admin.user.editViewCreate.customTitleSecondGroup"
                            ).replace(
                              "$$",
                              userInfo.username ?? t("common.user")
                            )}
                            tabType="geofences"
                            rightGroupProp={geofencesCannotSee}
                            leftGroupProp={geofencesCanSee}
                            leftButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelRemove"
                            )}
                            rightButtonText={t(
                              "admin.groups.editGroups.widgetButtonLabelAdd"
                            )}
                            output={() => {}}
                            disabled={true}
                          />
                        </TabSection>
                      ) : null}
                    </Tabs>
                  </div>
                )
              : null}
          </div>
          {
            //#endregion
          }
        </div>
        <FeedbackModal
          open={openEmailModal}
          title={`${t("pages.validation.emailTitle")}`}
          desc={`${t("pages.validation.emailDesc").replace(
            "*email",
            userInfo.email
          )}`}
          primaryLabel={`${t("common.confirm")}`}
          primaryAction={() => {
            store.dispatch(
              sendValidationAsync({
                channel: "EMAIL",
                entity: "USER",
                entityId: userInfo.id.toString() ?? "",
              })
            );
            setOpenEmailModal(false);
            setIsEmailExpired(false);
          }}
          secondaryLabel={`${t("common.cancel")}`}
          secondaryAction={() => {
            setOpenEmailModal(false);
          }}
          closeAction={() => {
            setOpenEmailModal(false);
          }}
        />
        <FeedbackModal
          open={openSmsModal}
          title={`${t("pages.validation.smsTitle")}`}
          desc={`${t("pages.validation.smsDesc").replace(
            "*phone",
            userInfo.telephone
          )}`}
          primaryLabel={`${t("common.confirm")}`}
          primaryAction={() => {
            store.dispatch(
              sendValidationAsync({
                channel: "PHONE",
                entity: "USER",
                entityId: userInfo.id.toString() ?? "",
              })
            );
            setOpenSmsModal(false);
            setIsNumberExpired(false);
          }}
          secondaryLabel={`${t("common.cancel")}`}
          secondaryAction={() => {
            setOpenSmsModal(false);
          }}
          closeAction={() => {
            setOpenSmsModal(false);
          }}
        />
      </PageContent>
    </>
  );
};
export default ViewUser;
