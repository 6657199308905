import { MarkerClusterer } from "@googlemaps/markerclusterer";

export class AccessibleMarkerClusterer extends MarkerClusterer {
  public getClusters() {
    return this.clusters;
  }
  public getMarkers() {
    return this.markers;
  }
}
