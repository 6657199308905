import { Loader } from "@googlemaps/js-api-loader";
import { t } from "i18next";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { Button } from "../../ui/Button/Button";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import Form from "../../ui/Forms/Form";
import TextField from "../../ui/Forms/TextField";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { IconEdit } from "../../ui/Icon/Line/Edit";
import { IconForward } from "../../ui/Icon/Line/Forward";
import { Check } from "../../ui/Indicators/Check";
import { FeedbackModal } from "../../ui/Modal/CustomModals/FeedbackModal";
import { ThumbProfile } from "../../ui/ThumbProfile/ThumbProfile";
import { DataCommon } from "../../utils/Common";
import { datetoLocaleFormat } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  ValidationChannelEnum,
  ValidationType,
  emptyStatusAndReasonCode,
  selectValidationsSliceReasonCode,
  selectValidationsSliceStatus,
  sendValidationAsync,
  validationsEmptyState,
  validationsSelectors,
} from "../../validation/validationSlice";
import {
  FleetView,
  fleetViewsEmptyState,
  fleetViewsSelectors,
} from "../fleet/fleetViewsSlice";
import {
  Preferences,
  getPreferencesAsync,
  selectPreferencesSliceReasonCode,
  selectPreferencesSliceStatus,
} from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import {
  Vehicle,
  getVehiclesDetailsAsync,
  selectVehiclesSliceReasonCode,
  selectVehiclesSliceStatus,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "../vehicle/vehiclesSlice";
import "./AdminDriverManagement.css";
import {
  Driver,
  driversEmptyState,
  driversSelectors,
  getDriverDetailsAsync,
  selectDriversSliceReasonCode,
  selectDriversSliceStatus,
} from "./driversSlice";

interface ViewDriverProps {
  permissions: UserPermissions;
}

interface DataCommonProps {
  label: string;
}

let fetchUsers: (viewUserRoute: any) => any;
let userPolling: any;

const ViewDriver: React.FC<ViewDriverProps> = ({ permissions }) => {
  const navigate = useNavigate();

  const driverSliceStatus = useAppSelector(selectDriversSliceStatus);
  const driverSliceReasonCode = useAppSelector(selectDriversSliceReasonCode);
  const vehiclesSliceStatus = useAppSelector(selectVehiclesSliceStatus);
  const vehiclesSliceReasonCode = useAppSelector(selectVehiclesSliceReasonCode);
  const preferencesSliceStatus = useAppSelector(selectPreferencesSliceStatus);
  const preferencesSliceReasonCode = useAppSelector(
    selectPreferencesSliceReasonCode
  );

  const driverRoute = useMatch("/admin/drivers/view/:driverId");

  const [openEmailModal, setOpenEmailModal] = useState(false);
  const [openSmsModal, setOpenSmsModal] = useState(false);

  const [isEmailValidated, setIsEmailValidated] = useState(false);
  const [isNumberValidated, setIsNumberValidated] = useState(false);

  const [numberCountdown, setNumberCountdown] = useState<number>(0);
  const [emailCountdown, setEmailCountdown] = useState<number>(0);
  const [isNumberExpired, setIsNumberExpired] = useState<boolean>(false);
  const [isEmailExpired, setIsEmailExpired] = useState<boolean>(false);

  const [id, setId] = useState<number>(-1);

  let driver: Driver =
    useAppSelector((state) => driversSelectors.selectById(state, id ?? -1)) ??
    ({} as Driver);
  const vehicle: Vehicle =
    useAppSelector((state) =>
      vehiclesSelectors.selectById(state, driver?.vehicle ?? -1)
    ) ?? ({} as Vehicle);
  let fleetView: FleetView =
    useAppSelector((state) =>
      fleetViewsSelectors.selectById(state, driver.fleet ?? -1)
    ) ?? ({} as FleetView);

  const prefixList: DataCommonProps[] =
    DataCommon()?.prefixList ?? ([] as DataCommonProps[]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const validationSliceStatus = useAppSelector(selectValidationsSliceStatus);
  const validationSliceReasonCode = useAppSelector(
    selectValidationsSliceReasonCode
  );

  const validation: ValidationType[] = useAppSelector((state: any) =>
    validationsSelectors.selectAll(state)
  );

  document.title = useMemo(() => {
    if (!!driver?.firstName && !!driver?.lastName) {
      return (
        `${driver?.firstName} ${driver?.lastName} - ` +
        t("navigation.adminMenu.drivers") +
        " - Admin"
      );
    } else {
      return t("navigation.adminMenu.drivers") + " - Admin";
    }
  }, [driver]);

  useEffect(() => {
    document.title = t("navigation.adminMenu.drivers") + " - Admin";

    const loader = new Loader({
      apiKey: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      libraries: ["places", "drawing", "geometry"],
      version: "beta",
    });
    loader.load().then();
    return () => {
      loader.deleteScript();
      store.dispatch(driversEmptyState());
      store.dispatch(fleetViewsEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(validationsEmptyState());
    };
  }, []);

  //#region toast notifications
  useEffect(() => {
    // get driver id and fleet name
    if (driverRoute !== null && driverRoute.params.driverId) {
      setId(parseInt(driverRoute.params.driverId));
      store.dispatch(
        getDriverDetailsAsync(parseInt(driverRoute.params.driverId))
      );
      store.dispatch(getVehiclesDetailsAsync());
      store.dispatch(getPreferencesAsync());
    } else {
      ToastNotification({
        toastId: "unauthorizedError",
        status: "error",
        description: t("common.unauthorizedError"),
      });
      navigate(-1);
    }
  }, [navigate, driverRoute]);

  useEffect(() => {
    if (vehiclesSliceStatus === "failed" && vehiclesSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [navigate, vehiclesSliceStatus, vehiclesSliceReasonCode]);

  useEffect(() => {
    if (driverSliceStatus === "idle") {
      document.title =
        `${driver?.firstName} ${driver?.lastName} - ` +
        t("navigation.adminMenu.drivers") +
        " - Admin";
    } else if (driverSliceStatus === "failed") {
      switch (driverSliceReasonCode) {
        case GTFleetErrorCodes.DRIVER_NOT_FOUND:
          ToastNotification({
            toastId: "driverNotFoundError",
            status: "error",
            description: t("common.driverNotFoundError"),
          });
          break;
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/admin/drivers");
    }
  }, [driverSliceStatus, driverSliceReasonCode, navigate]);

  useEffect(() => {
    if (
      preferencesSliceStatus === "failed" &&
      preferencesSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [preferencesSliceStatus, preferencesSliceReasonCode]);

  useEffect(() => {
    if (
      validationSliceStatus === "idle" &&
      validationSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "driverValidationSendSuccess",
        status: "success",
        description: t("validation.validationSendSuccess"),
      });
    }
    if (
      validationSliceStatus === "failed" &&
      validationSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    store.dispatch(emptyStatusAndReasonCode());
  }, [validationSliceStatus, validationSliceReasonCode]);
  //#endregion toast notifications

  /**
   * This function dispacth a call to get all status message from id
   * and set timer for polling
   */
  fetchUsers = (driverRoute: any) => {
    store.dispatch(
      getDriverDetailsAsync(parseInt(driverRoute.params.driverId))
    );

    userPolling = setTimeout(() => fetchUsers(driverRoute), 60000);
  };

  useEffect(() => {
    fetchUsers(driverRoute);
    return function cleanup() {
      clearTimeout(userPolling);
    };
  }, []);

  //#region tooltip for code validation
  const [emailTooltip, setEmailTooltip] = useState("");
  const [numberTooltip, setNumberTooltip] = useState("");

  const formatCountdown = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  useEffect(() => {
    setIsEmailValidated(driver.emailValidated);
    setIsNumberValidated(driver.numberValidated);

    const emailExpirationDate =
      validation[0]?.channelType === ValidationChannelEnum.EMAIL &&
      validation[0]?.validationDate
        ? new Date(validation[0].validationDate)
        : driver.emailValidationExpirationDate &&
          new Date(driver.emailValidationExpirationDate);
    const emailTimer = setInterval(() => {
      const currentEmailTime = new Date();
      const timeEmailDifference =
        emailExpirationDate?.getTime() - currentEmailTime?.getTime();
      if (timeEmailDifference > 0) {
        setEmailCountdown(Math.floor(timeEmailDifference / 1000));
      } else {
        setIsEmailExpired(true);
        clearInterval(emailTimer);
      }
    }, 1000);

    const numberExpirationDate =
      validation[0]?.channelType === ValidationChannelEnum.PHONE &&
      validation[0]?.validationDate
        ? new Date(validation[0].validationDate)
        : driver.emailValidationExpirationDate &&
          new Date(driver.numberValidationExpirationDate);
    const numberTimer = setInterval(() => {
      const currentNumberTime = new Date();
      const timeNumberDifference =
        numberExpirationDate?.getTime() - currentNumberTime?.getTime();
      if (timeNumberDifference > 0) {
        setNumberCountdown(Math.floor(timeNumberDifference / 1000));
      } else {
        setIsNumberExpired(true);
        clearInterval(numberTimer);
      }
    }, 1000);

    return () => {
      clearInterval(emailTimer);
      clearInterval(numberTimer);
    };
  }, [driver, validation]);

  useEffect(() => {
    setEmailTooltip(
      `${t("validation.progressValidation")} ${formatCountdown(
        emailCountdown
      )} min`
    );
  }, [emailCountdown]);

  useEffect(() => {
    setNumberTooltip(
      `${t("validation.progressValidation")} ${formatCountdown(
        numberCountdown
      )} min`
    );
  }, [numberCountdown]);
  //#endregion

  return (
    <>
      {!!driver && !_.isEmpty(driver) && (
        <PageFilters>
          <div className="col col-16">
            <div className="drvr-subtopbar">
              <div className="drvr-subsection-name">
                <div className="drvr-profile-icon">
                  <ThumbProfile
                    size="mediumsmall"
                    alt="Driver Thumbnail"
                    imgUrl={driver.avatar}
                  />
                  <span className="drvr-profile-text">
                    {driver.firstName + " " + driver.lastName}
                  </span>
                </div>
              </div>
              <div className="drvr-buttons">
                <Button
                  size="small"
                  aspect="secondary"
                  label={t("admin.driver.editViewCreate.allDrivers")}
                  onClick={() => navigate("/admin/drivers")}
                />
                <Button
                  size="small"
                  aspect="primary"
                  label={t("common.edit")}
                  onClick={() => navigate("/admin/drivers/edit/" + driver.id)}
                >
                  <IconEdit className="" color="" size={14} />
                </Button>
              </div>
            </div>
          </div>
        </PageFilters>
      )}
      {driverSliceStatus === "idle" && (
        <PageContent>
          <div className="drvr-page-container">
            {/* General */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.general")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("common.name")} *
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-item drvr-only-view">
                        <TextField
                          id="Name"
                          validate="alphabetic|isNotEmpty"
                          placeholder={
                            driver.firstName ? t("common.name") : t("common.na")
                          }
                          value={driver.firstName ?? null}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.surname")} *
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="Surname"
                          validate="alphabetic|isNotEmpty"
                          placeholder={
                            driver.lastName
                              ? t("common.surname")
                              : t("common.na")
                          }
                          value={driver.lastName ?? null}
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.address")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    {" "}
                    <div className="drvr-left-form-item drvr-only-view">
                      <TextField
                        id="Address"
                        placeholder={
                          driver.address ? t("common.address") : t("common.na")
                        }
                        value={driver.address ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>{" "}
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("common.city")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-item drvr-only-view">
                        <TextField
                          id="City"
                          placeholder={
                            driver.city ? t("common.city") : t("common.na")
                          }
                          value={driver.city ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <Form>
                    <div className="drvr-right-forms-label">
                      {t("common.province")}
                    </div>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="Province"
                          placeholder={
                            driver.province
                              ? t("common.province")
                              : t("common.na")
                          }
                          value={driver.province ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">{t("common.zip")}</div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-item drvr-only-view">
                        <TextField
                          id="Cap"
                          placeholder={
                            driver.zip ? t("common.zip") : t("common.na")
                          }
                          value={driver.zip ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <Form>
                    <div className="drvr-right-forms-label">
                      {t("common.country")}
                    </div>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="Country"
                          placeholder={
                            driver.country
                              ? t("common.country")
                              : t("common.na")
                          }
                          value={driver.country ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.email")} *
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-text-field drvr-only-view">
                      <TextField
                        id="driver-mail"
                        type="email"
                        placeholder={
                          driver.email ? t("common.email") : t("common.na")
                        }
                        value={driver.email ?? null}
                      >
                        {isEmailExpired && !isEmailValidated && (
                          <>
                            <Tooltip />
                            <span
                              data-for="tooltip"
                              data-tooltip-delay-hide={1000}
                              data-tip={t("validation.expiredValidation")}
                            >
                              <Check status="failed" size="small" />
                            </span>
                          </>
                        )}
                        {isEmailValidated && (
                          <Check status="success" size="small" />
                        )}
                      </TextField>
                    </div>
                    <div className="drvr-validate-button">
                      {!isEmailValidated && emailCountdown !== 0 && (
                        <Tooltip id="emailTooltip" />
                      )}
                      <span
                        data-for={
                          !isEmailValidated && emailCountdown !== 0
                            ? "emailTooltip"
                            : ""
                        }
                        data-tooltip-delay-hide={1000}
                        data-tip={emailTooltip}
                      >
                        <Button
                          size="regular"
                          aspect="primary"
                          disabled={
                            driver.email === "" ||
                            !isEmailExpired ||
                            isEmailValidated
                          }
                          label={t("common.validate")}
                          onClick={() => {
                            setOpenEmailModal(true);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">{t("common.phone")}</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-phone drvr-only-view">
                      <div className="drvr-drpdwn-small drvr-only-view">
                        <Dropdown
                          placeholder={
                            driver.phoneNumberPrefix
                              ? t("common.phone")
                              : t("common.na")
                          }
                          value={{
                            label: driver?.phoneNumberPrefix ?? "+39",
                          }}
                          size="normal"
                          itemAttribute="label"
                          onChange={(val) => {
                            console.log(val);
                          }}
                          options={prefixList}
                        />
                      </div>
                    </div>
                    <div className="drvr-right-form-phone drvr-only-view">
                      <TextField
                        id="Number"
                        placeholder={
                          driver.phoneNumber
                            ? t("common.phone")
                            : t("common.na")
                        }
                        value={driver.phoneNumber ?? null}
                      >
                        {isNumberExpired && !isNumberValidated && (
                          <>
                            <Tooltip id="numberTooltip" />
                            <span
                              data-for="numberTooltip"
                              data-tooltip-delay-hide={1000}
                              data-tip={t("validation.expiredValidation")}
                            >
                              <Check status="failed" size="small" />
                            </span>
                          </>
                        )}
                        {isNumberValidated && (
                          <Check status="success" size="small" />
                        )}
                      </TextField>{" "}
                    </div>
                    <div className="drvr-validate-button">
                      {!isNumberValidated && numberCountdown !== 0 && (
                        <Tooltip />
                      )}
                      <span
                        data-for={
                          !isNumberValidated && numberCountdown !== 0
                            ? "tooltip"
                            : ""
                        }
                        data-tooltip-delay-hide={1000}
                        data-tip={numberTooltip}
                      >
                        <Button
                          size="regular"
                          aspect="primary"
                          disabled={
                            driver.phoneNumber === "" ||
                            !isNumberExpired ||
                            isNumberValidated
                          }
                          label={t("common.validate")}
                          onClick={() => {
                            setOpenSmsModal(true);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Personal info */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.personalInfo")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("common.dateOfBirth")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-item drvr-only-view">
                        <TextField
                          id="dateOfBirth"
                          placeholder={
                            driver.dateOfBirth
                              ? t("common.dateOfBirth")
                              : t("common.na")
                          }
                          value={
                            driver.dateOfBirth
                              ? datetoLocaleFormat(
                                  driver.dateOfBirth,
                                  preferencesContext.localeFormat ?? undefined
                                )
                              : null
                          }
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("common.fiscalCode")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="fiscalCode"
                          placeholder={
                            driver.fiscalCode
                              ? t("common.fiscalCode")
                              : t("common.na")
                          }
                          value={driver.fiscalCode ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            {/* Driver License */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.driveLicense")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-half-form">
                  <div className="drvr-left-forms-label">
                    {t("admin.driver.editViewCreate.licenseId")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-item drvr-only-view">
                        <TextField
                          id="licenseId"
                          placeholder={
                            driver.licenseId
                              ? t("admin.driver.editViewCreate.licenseId")
                              : t("common.na")
                          }
                          value={driver.licenseId ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
                <div className="drvr-half-form">
                  <div className="drvr-right-forms-label">
                    {t("admin.driver.editViewCreate.licenseExpDate")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-right-form-item drvr-only-view">
                        <TextField
                          id="licenseExpDate"
                          placeholder={
                            driver.licenseExpirationDate
                              ? t("admin.driver.editViewCreate.licenseExpDate")
                              : t("common.na")
                          }
                          value={
                            driver.licenseExpirationDate
                              ? datetoLocaleFormat(
                                  driver.licenseExpirationDate,
                                  preferencesContext.localeFormat ?? undefined
                                )
                              : null
                          }
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.licenseType")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item drvr-only-view">
                      <TextField
                        id="licenseType"
                        placeholder={
                          driver.licenseType
                            ? t("admin.driver.editViewCreate.licenseType")
                            : t("common.na")
                        }
                        value={driver.licenseType ?? null}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Identification / Vehicle */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.identification")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.driverId")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item drvr-only-view">
                      <TextField
                        id="driverId"
                        placeholder={
                          driver.id
                            ? t("admin.driver.editViewCreate.driverId")
                            : t("common.na")
                        }
                        value={driver.id ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("admin.driver.editViewCreate.linkedTachograph")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item drvr-only-view">
                      <TextField
                        id="tachographCard"
                        placeholder={
                          driver.tachographCard
                            ? t("admin.driver.editViewCreate.linkedTachograph")
                            : t("common.na")
                        }
                        value={driver.tachographCard ?? null}
                      ></TextField>
                    </div>
                  </div>
                </Form>
              </div>
              {driver?.mac ? (
                <div className="drvr-forms-container">
                  <div className="drvr-left-forms-label">
                    {t("admin.driver.editViewCreate.beaconMac")}
                  </div>
                  <Form>
                    <div className="drvr-forms">
                      <div className="drvr-left-form-item drvr-only-view">
                        <TextField
                          id="mac"
                          placeholder={
                            driver.mac
                              ? t("admin.driver.editViewCreate.beaconMac")
                              : t("common.na")
                          }
                          value={driver.mac ?? null}
                        ></TextField>
                      </div>
                    </div>
                  </Form>
                </div>
              ) : null}
              <div className="drvr-separator" />
              <div className="drvr-forms-title">
                {t("admin.driver.editViewCreate.associatedVehicle")}
              </div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.vehicle")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item drvr-only-view">
                      <TextField
                        id="selectVehicle"
                        placeholder={
                          driver.vehicle
                            ? t("admin.driver.editViewCreate.selectVehicle")
                            : t("common.na")
                        }
                        value={
                          driver?.vehicle && vehicle?.alias && vehicle?.plate
                            ? vehicle?.alias + "-" + vehicle?.plate
                            : null
                        }
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Group */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">{t("common.group")}</div>
              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">{t("common.group")}</div>
                <Form>
                  <div className="drvr-forms">
                    <div className="drvr-left-form-item drvr-only-view">
                      <TextField
                        id="selectGroup"
                        placeholder={
                          fleetView.name
                            ? t("admin.driver.editViewCreate.selectGroup")
                            : t("common.na")
                        }
                        value={fleetView.name ?? null}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            {/* Account */}
            <div className="drvr-info-form">
              <div className="drvr-forms-title">{t("common.account")}</div>

              <div className="drvr-forms-container">
                <div className="drvr-left-forms-label">
                  {t("common.username")}
                </div>
                <Form>
                  <div className="drvr-forms">
                    <div className="left-form drvr-link drvr-only-view">
                      <TextField
                        id="username"
                        placeholder={
                          driver.username
                            ? t("common.username")
                            : t("common.na")
                        }
                        value={driver.username ?? null}
                      >
                        <IconForward
                          className={"drvr-username-button"}
                          size={14}
                          color="--global-colors-ink-light"
                          onClick={() => {
                            alert("Account page");
                          }}
                        />
                      </TextField>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>{" "}
          <FeedbackModal
            open={openEmailModal}
            title={`${t("pages.validation.emailTitle")}`}
            desc={`${t("pages.validation.emailDesc").replace(
              "*email",
              driver.email
            )}`}
            primaryLabel={`${t("common.confirm")}`}
            primaryAction={() => {
              store.dispatch(
                sendValidationAsync({
                  channel: "EMAIL",
                  entity: "DRIVER",
                  entityId: driver.id.toString() ?? "",
                })
              );
              setOpenEmailModal(false);
              setIsEmailExpired(false);
            }}
            secondaryLabel={`${t("common.cancel")}`}
            secondaryAction={() => {
              setOpenEmailModal(false);
            }}
            closeAction={() => {
              setOpenEmailModal(false);
            }}
          />
          <FeedbackModal
            open={openSmsModal}
            title={`${t("pages.validation.smsTitle")}`}
            desc={`${t("pages.validation.smsDesc").replace(
              "*phone",
              driver.phoneNumber
            )}`}
            primaryLabel={`${t("common.confirm")}`}
            primaryAction={() => {
              store.dispatch(
                sendValidationAsync({
                  channel: "PHONE",
                  entity: "DRIVER",
                  entityId: driver.id.toString() ?? "",
                })
              );
              setOpenSmsModal(false);
              setIsNumberExpired(false);
            }}
            secondaryLabel={`${t("common.cancel")}`}
            secondaryAction={() => {
              setOpenSmsModal(false);
            }}
            closeAction={() => {
              setOpenSmsModal(false);
            }}
          />
        </PageContent>
      )}
    </>
  );
};
export default ViewDriver;
