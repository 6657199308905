import PropTypes from "prop-types";
import { useContext, useEffect } from "react";
import { FormCtx } from "./Form";

import i18next from "i18next";
import "./TextField.css";

const TextField = (props) => {
  const { id, maxLength, minLength, errorList } = props;
  const {
    setFields,
    addField,
    fields,
    errors,
    validateField,
    formattedNumber,
  } = useContext(FormCtx);
  const field = fields[id] || {};
  const fieldError = errors[id] || "";
  const {
    name,
    rows,
    value,
    validate,
    disabled,
    size = "normal",
    label,
    placeholder,
    type = "text",
    events = {},
    classes = {},
  } = field;
  const { onChange, onClick, ...restEvents } = events;
  let { contClass, fieldClass, errorClass } = classes;

  if (fieldError !== "") {
    fieldClass = [fieldClass, "input--has-error"].join(" ");
  }
  if (size !== "normal") {
    fieldClass = [fieldClass, "input--small"].join(" ");
  }

  if (props.children !== undefined) {
    contClass = [contClass, "text-field--has-icon"].join(" ");
  }

  const handleChange = (event) => {
    setFields(event, field);

    if (typeof onChange === "function") {
      onChange({
        ...field,
        value: event.target.value,
      });
    }
  };

  const handleClick = (event) => {
    setFields(event, field);

    if (typeof onClick === "function") {
      onClick({
        ...field,
        value: event.target.value,
      });
    }
  };

  useEffect(() => {
    addField({
      field: props,
      value,
    });
  }, [props]);

  useEffect(() => {
    if (field.value !== undefined && errorList) {
      validateField(id);
      fieldError !== ""
        ? errorList((oldArray) => [...oldArray, id])
        : errorList((oldArray) => oldArray.filter((item) => item !== id)); //metodo che mantiene lo stato in un array dei form non corretti
    }
  }, [value, fieldError]);

  //#region methods to convert number with comma annotation (es. 1200 -> 1.200 or 1254,99 -> 1.254,99)
  function convertNumber(num) {
    const { format } = new Intl.NumberFormat(i18next.language);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return +num
      .replace(new RegExp(`[^${decimalSign}\\d]`, "g"), "")
      .replace(decimalSign, ".");
  }

  function decimal(num) {
    const { format } = new Intl.NumberFormat(i18next.language);
    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    return num.slice(-1) === decimalSign ? decimalSign : "";
  }

  if (formattedNumber) {
    document.getElementById(id)?.addEventListener("keyup", function (evt) {
      evt.currentTarget.value =
        convertNumber(evt.currentTarget.value).toLocaleString(
          i18next.language
        ) + decimal(evt.currentTarget.value);
    });
  }
  //#endregion

  const fieldProps = {
    ...restEvents,
    id,
    name,
    type,
    value: value || "",
    validate,
    placeholder,
    disabled,
    label,
    maxLength,
    minLength,
    className: fieldClass,
    onChange: handleChange,
    onClick: handleClick,
  };

  if (type === "textarea") {
    delete fieldProps.type;
    fieldProps.rows = rows || 2;
  }

  function preventIncrementDecrement(event) {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault();
    }
  }
  return (
    <>
      <div className={[contClass, "text-field"].join(" ")}>
        <label htmlFor={field.id}>{label}</label>
        {type === "textarea" ? (
          <textarea {...fieldProps} />
        ) : (
          <div>
            <input
              {...fieldProps}
              autoComplete="off"
              name="text-field"
              onKeyDown={(event) =>
                fieldProps.type === "number" && preventIncrementDecrement(event)
              }
              onWheel={(event) =>
                fieldProps.type === "number" && preventIncrementDecrement(event)
              }
            />
            {props.children}
          </div>
        )}
        {errorList && <p className={errorClass}>{fieldError}</p>}
      </div>
    </>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  placeholder: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  minLength: PropTypes.string,
  size: PropTypes.oneOf(["small", "normal"]),
  label: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "email",
    "tel",
    "textarea",
    "password",
    "file",
    "search",
    "time",
    "number",
  ]),
  events: PropTypes.exact({
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onClick: PropTypes.func,
  }),
  classes: PropTypes.exact({
    contClass: PropTypes.string,
    fieldClass: PropTypes.string,
    errorClass: PropTypes.string,
  }),
};

TextField.defaultProps = {
  id: "",
  placeholder: "Inserisci",
};

export default TextField;
