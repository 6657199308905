import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { Tag } from "../../ui/Tags/Tag";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { UserInfo } from "./usersInfoSlice";

export class AdminUsersTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      user?: UserInfo;
    },
    preferencesContext: Preferences,
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let user: UserInfo = entities.user ?? ({} as UserInfo);

    columns?.forEach((column) => {
      switch (column.field) {
        case "user":
          row.user = user.username ?? t("common.na");
          row.render.user = (
            <LabelText
              label={user.username ? user.username + "" : t("common.na")}
              text={
                user.firstName
                  ? user.firstName + " " + user.secondName
                  : t("common.na")
              }
            />
          );
          break;
        case "profile":
          let profile = t("admin.user.profile.unknown");
          switch (user.securityProfile?.name) {
            case "FLEET_MANAGER":
              profile = t("admin.user.profile.fleet");
              break;
            case "MAINTENANCE_MANAGER":
              profile = t("admin.user.profile.maintenance");
              break;
            case "DRIVER":
              profile = t("admin.user.profile.driver");
              break;
            case "BASIC":
              profile = t("admin.user.profile.basic");
              break;
          }
          row.render.profile = (
            <Tag
              className="table-tags"
              text={profile}
              size="small"
              mode="outline"
              type="neutro"
            ></Tag>
          );
          break;
        case "lastaccess":
          row.render.lastaccess = user.lastAccess
            ? ConvertTimeZone(
                user.lastAccess,
                preferencesContext?.timeZone ?? undefined,
                preferencesContext?.localeFormat ?? undefined
              )
            : t("common.na");
          break;
        case "status":
          if (user.active === undefined) {
            row.render.status = <Table.TextStatus text={t("common.na")} />;
          }
          if (user.active) {
            row.render.status = (
              <Table.TextStatus text={t("table.status.ACTIVE")} />
            );
          } else {
            row.render.status = (
              <Table.TextStatus text={t("table.status.INACTIVE")} />
            );
          }
          break;
        case "expiration":
          let expirationContent = user.expirationDate ? (
            <>
              {ConvertTimeZone(
                user.expirationDate,
                preferencesContext?.timeZone ?? undefined,
                preferencesContext?.localeFormat ?? undefined
              )}
            </>
          ) : (
            <>{t("common.never")}</>
          );
          row.render.expiration = <>{expirationContent}</>;
          break;

        default:
          break;
      }
    });

    const userDropDownList = [
      {
        id: 0,
        title: t("common.view"),
        onClick: () => navigate("/admin/users/view/" + user.id),
      },
      {
        id: 1,
        title: t("common.edit"),
        onClick: () => navigate("/admin/users/edit/" + user.id),
      },
      {
        id: 2,
        title: t("common.actionOption.deactivate"),
        onClick: () => {
          /*initial state is empty*/
        },
      },
    ];

    if (user.active) {
      userDropDownList[2].title = t("common.actionOption.deactivate");
      userDropDownList[2].onClick = () => {
        navigate("/admin/users/deactivate/" + user.id);
      };
    } else {
      userDropDownList[2].title = t("common.actionOption.activate");
      userDropDownList[2].onClick = () => {
        navigate("/admin/users/activate/" + user.id);
      };
    }
    if (!writePermission) {
      // hide edit and activate/deactivate
      userDropDownList.splice(1, 2);
    }
    row.render.action = (
      <DropdownButton aspect="ghost" size="small" list={userDropDownList} />
    );
    return row;
  };
}
