import { IconLocator } from "../Icon/Solid/Locator";

import "./CenterLocation.css";

export const CenterLocation = ({ className, onClick, ...props }) => {
  return (
    <div
      className={["map-button", className].join(" ")}
      onClick={onClick}
      {...props}
      style={{
        boxShadow: "1px 2px 4px -1px rgba(0, 0, 0, 0.3)",
      }}
    >
      <IconLocator size={14} />
    </div>
  );
};
