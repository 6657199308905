import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconHumidity } from "../../../ui/Icon/Line/Humidity";
import { IconKilometers } from "../../../ui/Icon/Line/Kilometers";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconParking } from "../../../ui/Icon/Line/Parking";
import { IconTemperature } from "../../../ui/Icon/Line/Temperature";
import { ModalBody } from "../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../ui/Modal/ModalWrapper";
import { Table } from "../../../ui/Table/Table";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  convertMillisecondsToShortedTime,
  formatDateForQueryParams,
  kmToMiles,
} from "../../../utils/Utils";
import { Beacon } from "../../beacon/beaconSlice";
import { Driver, driversSelectors } from "../../driver/driversSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import {
  Preset,
  presetsSelectors,
  selectpresetsSliceStatus,
} from "../../users/preset/presetsSlice";
import UserContext from "../../users/userContext";
import { Vehicle } from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import "./AnalogicReport.css";
import { AnalogicReportSensorDataTableBuilder } from "./AnalogicReportSensorDataTableBuilder";
import {
  analogicReportSensorDataSelector,
  getAnalogicReportSensorDataAsync,
  SensorData,
} from "./analogicReportSensorDataSlice";
import { analogicReportSummarySelector } from "./analogicReportSummarySlice";

export interface AnalogicReportSensorDataModalProps {
  vehicleSelected: Vehicle;
  sensorSelected: Beacon;
  statusSelected: {
    label: any;
    value: any;
  };
  open: any;
  onClose: (e: any) => any;
}

interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

export const AnalogicReportSensorDataModal: React.FC<AnalogicReportSensorDataModalProps> =
  ({ vehicleSelected, sensorSelected, open, onClose, statusSelected }) => {
    //DATA SENSOR TABLE BUILDER SECTION
    const reportSensorData: SensorData[] = _.orderBy(
      useAppSelector(analogicReportSensorDataSelector.selectAll) || [],
      "date",
      "asc"
    );

    const [preferencesContext]: [Preferences] = useContext(UserContext);
    const tableBuilderRef = useRef<AnalogicReportSensorDataTableBuilder>(
      new AnalogicReportSensorDataTableBuilder()
    );
    const tableData: TableData = {} as TableData;
    let tableBuilder = tableBuilderRef.current;

    const pathMatchViewSummary = useMatch(
      "/reports/analogic-report/:summaryId/details/:id"
    );
    let summaryId = pathMatchViewSummary?.params.id ?? 0;
    const reportSummary = useAppSelector((state) =>
      analogicReportSummarySelector.selectById(state, summaryId)
    );

    const navigate = useNavigate();
    const context = "analogicReport";
    const presetsArchiveSliceStatus = useAppSelector(selectpresetsSliceStatus);

    const driver: Driver =
      useAppSelector((state) =>
        driversSelectors.selectById(state, reportSensorData[0]?.driverId)
      ) ?? ({} as Driver);

    let presets: Preset[] = useAppSelector(presetsSelectors.selectAll);
    const presetsAnalogicReportSliceStatus = useAppSelector(
      selectpresetsSliceStatus
    );

    if (presetsAnalogicReportSliceStatus === "idle" && presets.length > 0) {
      let preset =
        presets.find((element) => element.name === undefined) ??
        presets.find((element) => element.lastSelected === true);
      if (!preset) {
        preset = presets.find(
          (element) => element.name === "Default" && element.context === context
        );
      }

      tableData.columns = tableBuilder.setColumns(
        preset?.columns
          ? preset?.columns.map((element: any) => {
              return {
                name: element,
              };
            })
          : []
      );
      tableData.rows =
        tableData.columns && tableData?.columns?.length > 0
          ? reportSensorData?.map((summary: SensorData) => {
              return tableBuilder.rowsBuilder(
                tableData.columns,
                {
                  SensorData: summary,
                },
                navigate,
                sensorSelected?.beaconThreshold?.maxTemperature ?? 0,
                sensorSelected?.beaconThreshold?.minTemperature ?? 0,
                vehicleSelected,
                driver
              );
            })
          : tableData.rows;
    }

    useEffect(() => {
      if (pathMatchViewSummary?.params.id && !!reportSummary) {
        let startDate = new Date(reportSummary?.date ?? "");
        let endDate = new Date(reportSummary?.date ?? "");
        startDate.setHours(0);
        startDate.setMinutes(0);
        startDate.setSeconds(0);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
        let params =
          "?startPeriod=" +
          encodeURIComponent(formatDateForQueryParams(startDate)) +
          "&endPeriod=" +
          encodeURIComponent(formatDateForQueryParams(endDate));
        store.dispatch(
          getAnalogicReportSensorDataAsync({
            beaconNamespace: reportSummary?.namespace ?? "",
            entityId: vehicleSelected.id?.toString(),
            queryParam: params,
          })
        );
      }
    }, [pathMatchViewSummary]);

    //GENERATE SECTION
    const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
    const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);
    const reportsRepository = new ReportsRepository();

    const downloadResult = (pdf: boolean) => {
      if (vehicleSelected || reportSummary?.date) {
        const formattedDate =
          reportSummary && reportSummary.date
            ? new Date(reportSummary.date).toISOString().split("T")[0]
            : "";
        const params =
          `?startPeriod=${formattedDate}T00%3A37&endPeriod=${formattedDate}T23%3A37&presetName=&namespace=${sensorSelected.namespace}` +
          (pdf ? "&isPdf=true" : "&isPdf=false") +
          "&presetName=Default";

        pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
        reportsRepository
          .getAnalogicReportDownload(params, vehicleSelected.id)
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            const fileName = data.split("/").pop();
            const attachmentDownloadLink = document.createElement("a");
            attachmentDownloadLink.href =
              process.env.REACT_APP_BUCKET_URL + data;
            attachmentDownloadLink.download = fileName;
            attachmentDownloadLink.target = "_blank";
            attachmentDownloadLink.style.display = "none";
            document.body.appendChild(attachmentDownloadLink);
            attachmentDownloadLink.click();
            document.body.removeChild(attachmentDownloadLink);
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          })
          .catch((error) => {
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
            if (
              error.response &&
              error.response.data.message ===
                GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
            ) {
              console.log(error?.message || "Report Timeout Exception");
              ToastNotification({
                toastId: "reportTimeoutError",
                status: "default",
                title: t("common.reportTimeoutErrorTitle"),
                description: t("common.reportTimeoutErrorDesc"),
              });
            } else {
              console.log(error.response?.data?.error || "Unknown Error");
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t("common.networkError"),
              });
            }
          });
      }
    };

    const sendReportEmail = () => {
      if ((vehicleSelected || reportSummary?.date) && sensorSelected) {
        const formattedDate =
          reportSummary && reportSummary.date
            ? new Date(reportSummary.date).toISOString().split("T")[0]
            : "";
        const params =
          `startPeriod=${formattedDate}T00%3A37&endPeriod=${formattedDate}T23%3A37&presetName=&namespace=${sensorSelected.namespace}` +
          "&isPdf=false" +
          (statusSelected.value == undefined
            ? "&inUse="
            : statusSelected.value
            ? "&inUse=true"
            : "&inUse=false");
        reportsRepository
          .getAnalogicReportSendEmail(params, vehicleSelected.id)
          .then((response: any) => {
            return response.data;
          })
          .then((response: any) => {
            return response.data;
          })
          .then((data: any) => {
            ToastNotification({
              toastId: "requestSendingMailSuccess",
              status: "success",
              description: t(
                "report.toastNotification.requestSendingMailSuccess"
              ),
            });
          })
          .catch((error: any) => {
            if (!error.response) console.log(error?.message || "Unknown Error");
            else console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          });
      }
      return null;
    };

    const list = [
      {
        id: 0,
        title: t("report.filterBar.downloadExcel"),
        icon: isExcelGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(false);
        },
      },
      {
        id: 1,
        title: t("report.filterBar.downloadPDF"),
        icon: isPdfGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(true);
        },
      },
      {
        id: 2,
        title: t("report.filterBar.sendReportEmail"),
        icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          sendReportEmail();
        },
      },
    ];

    return (
      <>
        <div className="analogic-report-sensor-data-modal">
          <ModalWrapper open={open} closeAction={onClose}>
            <ModalBody
              title={t("report.analogic.details")}
              stylePadding={"0px"}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    gap: "30px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    borderBottom: "1px solid #E4E6E9",
                  }}
                >
                  <div className="analogic-report-details-summary-element">
                    <IconCalendar size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.date")}</span>
                      <span>
                        {reportSummary?.date &&
                          new Date(reportSummary?.date).getDate() +
                            "/" +
                            new Date(reportSummary?.date).getMonth() +
                            1 +
                            "/" +
                            new Date(reportSummary?.date).getFullYear()}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconKilometers size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.traveledKm")}</span>
                      <span>
                        {preferencesContext.isMetric
                          ? reportSummary?.kilometersTraveled + " km"
                          : kmToMiles(reportSummary?.kilometersTraveled ?? 0) +
                            " mil"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconParking size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.parkingHours")}</span>
                      <span>
                        {reportSummary?.parkingTime &&
                          convertMillisecondsToShortedTime(
                            reportSummary?.parkingTime
                          ).replace(":", " h ") + "m"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconTemperature size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.avgTemperature")}</span>
                      <span>
                        {reportSummary?.avgTemperature &&
                          reportSummary?.avgTemperature.toString().slice(0, 5) +
                            " °"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconTemperature size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.maxTemperature")}</span>
                      <span>
                        {reportSummary?.maxTemperature &&
                          reportSummary?.maxTemperature.toString().slice(0, 5) +
                            " °"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconTemperature size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.minTemperature")}</span>
                      <span>
                        {reportSummary?.minTemperature &&
                          reportSummary?.minTemperature.toString().slice(0, 5) +
                            " °"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconHumidity size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.avgRh")}</span>
                      <span>
                        {reportSummary?.minHumidity &&
                          reportSummary?.avgHumidity.toString().slice(0, 5) +
                            " %"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconHumidity size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.maxRh")}</span>
                      <span>
                        {reportSummary?.minHumidity &&
                          reportSummary?.maxHumidity.toString().slice(0, 5) +
                            " %"}
                      </span>
                    </div>
                  </div>
                  <div className="analogic-report-details-summary-element">
                    <IconHumidity size={14} />
                    <div className="analogic-report-details-summary-element-body">
                      <span>{t("table.columns.minRh")}</span>
                      <span>
                        {reportSummary?.minHumidity &&
                          reportSummary?.minHumidity.toString().slice(0, 5) +
                            " %"}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    overflow: "scroll",
                    height: "400px",
                  }}
                >
                  {tableData?.rows?.length > 0 ? (
                    <Table data={tableData}>
                      <Table.Head />
                      <Table.Body id="ior-table-body" />
                    </Table>
                  ) : (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div className="page-counter-loader" />
                    </div>
                  )}
                </div>
              </div>
            </ModalBody>
            <ModalFooter downloadButtonList={list}></ModalFooter>
          </ModalWrapper>
        </div>
      </>
    );
  };
