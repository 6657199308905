import dayjs from "dayjs";
import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../../app/hooks";
import { store } from "../../../app/store";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { Button } from "../../../ui/Button/Button";
import { DropdownButton } from "../../../ui/Button/DropdownButton";
import { Dropdown } from "../../../ui/Dropdown/Dropdown";
import { DatePickerDropdown } from "../../../ui/Forms/DatePickerDropdown";
import Form from "../../../ui/Forms/Form";
import SearchField from "../../../ui/Forms/SearchField";
import { IconAdd } from "../../../ui/Icon/Line/Add";
import { IconCalendar } from "../../../ui/Icon/Line/Calendar";
import { IconDown } from "../../../ui/Icon/Line/Down";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import { IconList } from "../../../ui/Icon/Line/List";
import { IconLocatorOff } from "../../../ui/Icon/Line/LocatorOff";
import { IconVehicle } from "../../../ui/Icon/Line/Vehicle";
import { ToastNotification } from "../../../utils/ToastNotification";
import { getQueryString } from "../../../utils/Utils";
import { getAddressBooksAsync } from "../../address/addressBookSlice";
import {
  Beacon,
  beaconsSelectors,
  getBeaconsByEntityAsync,
  selectBeaconsSliceStatus,
} from "../../beacon/beaconSlice";
import { fleetViewsSelectors } from "../../fleet/fleetViewsSlice";
import { schedulesSelectors } from "../../schedules/schedulesSlice";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import { Vehicle, vehiclesSelectors } from "../../vehicle/vehiclesSlice";
import ReportsRepository from "../reportRepository";
import { getReportsMetadataAsync } from "../reportsMetadataSlice";
import { SchedulationManageModal } from "../schedulation/SchedulationManageModal";
import { SchedulationModal } from "../schedulation/SchedulationModal";
import "../vehicle/VehicleReportFilterBar.css";
import "./AnalogicReportFilterbar.css";
import { selectAnalogicReportSummarySliceStatus } from "./analogicReportSummarySlice";

export interface AnalogicReportFilterbarProps {
  setVehicle: (vehicle: Vehicle) => any;
  setSensor: (sensor: Beacon) => any;
  statusSelected: (status: any) => any;
  apiCall: (
    beaconNameSpace: string,
    vehicleId: string,
    startDate: Date,
    endDate: Date
  ) => any;
}
interface QueryParams {
  [paramName: string]: any;
}

export const AnalogicReportFilterbar: React.FC<AnalogicReportFilterbarProps> =
  ({ setVehicle, setSensor, apiCall, statusSelected }) => {
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    //VEHICLE SECTION
    const queryParamsRef = useRef<QueryParams>({});
    let queryParams: QueryParams = queryParamsRef.current;
    const [isOpen, setIsOpen] = useState(false);
    const [alias, setAlias] = useState("");
    const [vehicleId, setVehicleId] = useState(-1);
    const vehicles: Vehicle[] = useAppSelector(vehiclesSelectors.selectAll);
    let vehiclesGroupByFleet = vehicles.reduce(
      (group: any, vehicle: Vehicle) => {
        const { fleet } = vehicle;
        const fleetName = fleetViewsSelectors.selectById(
          store.getState(),
          fleet
        )?.name;
        if (fleetName) {
          group[fleetName] = group[fleetName] ?? [];
          group[fleetName].push(vehicle);
        }
        return group;
      },
      {}
    );
    let fleetNames = Object.keys(vehiclesGroupByFleet);
    const filterVehicle = (vehicle: Vehicle) => {
      const filterVehicle =
        alias == "" ||
        (vehicle?.alias &&
          vehicle?.alias?.toLowerCase().includes(alias.toLowerCase())) ||
        (vehicle?.plate &&
          vehicle?.plate?.toLowerCase().includes(alias.toLowerCase())) ||
        (vehicle?.id &&
          vehicle?.id?.toString()?.toLowerCase().includes(alias.toLowerCase()));
      return filterVehicle;
    };

    useEffect(() => {
      store.dispatch(getAddressBooksAsync(""));
    }, []);

    //DATEPICKER SECTION
    const [isSelectedPeriod, setIsSelectedPeriod] = useState(false);
    const [initialDateValue, setInitialDateValue] = useState<Date[]>([]);
    const [dateRange, setDateRange] = useState<string[]>(
      initialDateValue.map((value) => value.toString())
    );

    //STATUS SECTION
    const statusOptions = [
      {
        label: t("report.analogic.status.inUse"),
        value: true,
      },
      {
        label: t("report.analogic.status.notInUse"),
        value: false,
      },
      {
        label: t("report.analogic.status.allStatuses"),
        value: undefined,
      },
    ];
    const [selectedStatus, setSelectedStatus] = useState({
      label: t("report.analogic.status.allStatuses"),
      value: undefined,
    });
    const [selectedSensor, setSelectedSensor] = useState<any>();

    //SENSOR SECTION
    const sensors: Beacon[] = useAppSelector(beaconsSelectors.selectAll).filter(
      (beacon) =>
        selectedStatus.value !== undefined
          ? beacon.inUse === selectedStatus.value
          : true
    );
    const [selectedStatusSchedulation, setSelectedStatusSchedulation] =
      useState({
        label: t("report.analogic.status.allStatuses"),
        value: undefined,
      });

    const sensorsForSchedulationModal: Beacon[] = useAppSelector(
      beaconsSelectors.selectAll
    ).filter(
      (beacon) =>
        beacon.entityType === "VEHICLE" &&
        (selectedStatusSchedulation.value !== undefined
          ? beacon.inUse === selectedStatusSchedulation.value
          : true)
    );

    const [selectedSensorSchedulation, setSelectedSensorSchedulation] =
      useState<any>();

    const sensorsNotFiltered: Beacon[] = useAppSelector(
      beaconsSelectors.selectAll
    );

    const beaconSliceStatus = useAppSelector(selectBeaconsSliceStatus);

    //GENERATE SECTION
    const [isPdfGenerated, setIsPdfGenerated] = useState<boolean>(true);
    const [isExcelGenerated, setIsExcelGenerated] = useState<boolean>(true);

    //SCHEDULATION SECTION
    const pathMatchAddingSchedulation = useMatch(
      "/reports/analogic-report/addSchedulation"
    );
    const pathMatchEditingSchedulation = useMatch(
      "/reports/analogic-report/editSchedulation/:schedulationId"
    );
    const [schedulationModal, setSchedulationModal] = useState(
      !!pathMatchAddingSchedulation || !!pathMatchEditingSchedulation
    );
    const [manageModalOpened, setManageModalOpened] = useState(false);
    const schedules = useAppSelector(schedulesSelectors.selectAll);
    const filterSchedulesByType = (type: string) => {
      return schedules.filter((schedule: any) => schedule.type === type);
    };
    const [queryString, setQueryString] = useState("");
    const reportsRepository = new ReportsRepository();

    const downloadResult = (pdf: boolean) => {
      if ((vehicleId && queryString !== "") || isSelectedPeriod) {
        const params =
          queryString +
          (pdf ? "&isPdf=true" : "&isPdf=false") +
          "&presetName=Default";

        pdf ? setIsPdfGenerated(false) : setIsExcelGenerated(false);
        reportsRepository
          .getAnalogicReportDownload(params, vehicleId)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const fileName = data.split("/").pop();
            const attachmentDownloadLink = document.createElement("a");
            attachmentDownloadLink.href =
              process.env.REACT_APP_BUCKET_URL + data;
            attachmentDownloadLink.download = fileName;
            attachmentDownloadLink.target = "_blank";
            attachmentDownloadLink.style.display = "none";
            document.body.appendChild(attachmentDownloadLink);
            attachmentDownloadLink.click();
            document.body.removeChild(attachmentDownloadLink);
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
          })
          .catch((error) => {
            pdf ? setIsPdfGenerated(true) : setIsExcelGenerated(true);
            if (
              error.response &&
              error.response.data.message ==
                GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
            ) {
              console.log(error?.message || "Report Timeout Exception");
              ToastNotification({
                toastId: "reportTimeoutError",
                status: "default",
                title: t("common.reportTimeoutErrorTitle"),
                description: t("common.reportTimeoutErrorDesc"),
              });
            } else {
              console.log(error.response?.data?.error || "Unknown Error");
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t("common.networkError"),
              });
            }
          });
      }
    };

    const sendReportEmail = () => {
      if ((vehicleId && queryString !== "") || isSelectedPeriod) {
        const params =
          queryString +
          "&isPdf=false" +
          (selectedStatus.value === undefined
            ? "&inUse="
            : selectedStatus.value
            ? "&inUse=true"
            : "&inUse=false");
        reportsRepository
          .getAnalogicReportSendEmail(params, vehicleId)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            ToastNotification({
              toastId: "requestSendingMailSuccess",
              status: "success",
              description: t(
                "report.toastNotification.requestSendingMailSuccess"
              ),
            });
          })
          .catch((error) => {
            if (!error.response) console.log(error?.message || "Unknown Error");
            else console.log(error.response?.data?.error || "Unknown Error");
            ToastNotification({
              toastId: "networkError",
              status: "error",
              description: t("common.networkError"),
            });
          });
      }
      return null;
    };

    const list = [
      {
        id: 0,
        title: t("report.filterBar.downloadExcel"),
        icon: isExcelGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(false);
        },
      },
      {
        id: 1,
        title: t("report.filterBar.downloadPDF"),
        icon: isPdfGenerated ? (
          <IconDownload size={14} color="--global-colors-ink-light" />
        ) : (
          <div className="details-driverReport-download-spinner"></div>
        ),
        onClick: () => {
          downloadResult(true);
        },
      },
      {
        id: 2,
        title: t("report.filterBar.sendReportEmail"),
        icon: <IconLocatorOff size={14} color="--global-colors-ink-light" />,
        onClick: () => {
          sendReportEmail();
        },
      },
    ];

    const sliceLoaderStatus = useAppSelector(
      selectAnalogicReportSummarySliceStatus
    );

    //FUNCTIONS SECTION
    function generationDisabled() {
      if (
        !_.isEmpty(selectedSensor) &&
        vehicleId !== -1 &&
        dateRange.length > 0
      ) {
        return false;
      }
      return true;
    }

    function generate() {
      setVehicle(
        vehicles.find((vehicle) => vehicle.id === vehicleId) ?? ({} as Vehicle)
      );
      setSensor(
        sensors.find((sensor) => sensor.namespace === selectedSensor.value) ??
          ({} as Beacon)
      );
      statusSelected(selectedStatus);
      apiCall(
        selectedSensor.value,
        vehicleId.toString(),
        new Date(dateRange[0]),
        new Date(dateRange[1])
      );
    }

    //USEEFFECT SECTION
    useEffect(() => {
      setSelectedSensor({});
    }, [vehicleId]);

    useEffect(() => {
      if (selectedStatus.value !== undefined) {
        if (
          selectedStatus.value !==
          sensors.find((sensor) => sensor.namespace === selectedSensor.value)
            ?.inUse
        ) {
          setSelectedSensor({});
        }
      }
    }, [selectedStatus]);

    useEffect(() => {
      pathMatchEditingSchedulation && setSchedulationModal(true);
    }, [pathMatchEditingSchedulation]);

    const handleChanges = (params: Map<string, string[] | string>): void => {
      let queryString = "";
      if (!!params) {
        params.forEach((value, key) => {
          if (!!value && value.length > 0) {
            queryParams[key] = value;
          } else {
            if (queryParams.hasOwnProperty(key)) {
              delete queryParams[key];
            }
          }
        });
        queryString = getQueryString(queryParams);
      }
      setQueryString(queryString);
    };

    let sensorsForSchedulationModalSelected =
      sensorsForSchedulationModal && !_.isEmpty(sensorsForSchedulationModal)
        ? sensorsForSchedulationModal
            .map((sensor) => {
              return { label: sensor?.name, value: sensor?.namespace };
            })
            .filter(
              (sensor) => sensor?.value === selectedSensorSchedulation?.value
            )
        : undefined;

    const disabledButton = (
      period: any,
      vehicleId: number,
      sensors: any,
      selectedSensor: any
    ) => {
      return period && vehicleId != -1 && !_.isEmpty(sensors) && selectedSensor;
    };

    return (
      <div
        className="ior-filterbar-row"
        style={{ justifyContent: "space-between" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: " 24px",
            marginBottom: " 24px",
          }}
        >
          <div className="report-vehicle-filterbar-search">
            <div
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <Form>
                <SearchField
                  name="search"
                  id="search-field"
                  size="small"
                  placeholder={t(
                    "locationHistory.searchDropdown.placeholderVehicle"
                  )}
                  value={alias}
                  onChange={(val: string) => {
                    setAlias(val);
                  }}
                />
              </Form>
            </div>
            {isOpen && vehicles.length > 0 && (
              <div className="report-vehicle-search-results">
                {fleetNames?.map((element: string, idx: number) => {
                  return (
                    <React.Fragment key={idx}>
                      <div className="report-vehicle-fleet-label">
                        {element}
                        {` (${
                          vehiclesGroupByFleet[element].filter(filterVehicle)
                            .length
                        })`}
                      </div>
                      {vehiclesGroupByFleet[element]
                        .filter(filterVehicle)
                        .map((vehicle: Vehicle, index: number) => (
                          <div
                            key={index}
                            className={
                              alias === vehicle.alias
                                ? "report-vehicle-alias-selected"
                                : "report-vehicle-alias"
                            }
                            onClick={() => {
                              setIsOpen(false);
                              setVehicleId(vehicle.id);
                              setAlias(vehicle.alias);
                              store.dispatch(
                                getBeaconsByEntityAsync({
                                  queryParams:
                                    "?entityType=VEHICLE&entityId=" +
                                    vehicle.id,
                                })
                              );
                            }}
                          >
                            {vehicle.alias}{" "}
                            <span className="report-vehicle-dot" />
                            {vehicle.plate}
                          </div>
                        ))}
                    </React.Fragment>
                  );
                })}
              </div>
            )}
          </div>

          <div className="ior-dropdown-events">
            <Dropdown
              itemAttribute="label"
              placeholderInValue={t("report.analogic.status.status")}
              size="small"
              onChange={(val: any) => {
                setSelectedStatus(val[0]);
              }}
              options={statusOptions}
              value={statusOptions.filter(
                (status) => status.value === selectedStatus?.value
              )}
            />
          </div>

          {sensors && (
            <div className="ior-dropdown-events">
              <Dropdown
                itemAttribute="label"
                placeholder={t("report.analogic.sensors")}
                placeholderInValue={t("report.analogic.sensors") + " :"}
                size="small"
                onChange={(val: any) => {
                  setSelectedSensor(val[0]);
                  const map = new Map();
                  map.set("namespace", val[0].value);
                  handleChanges(map);
                }}
                isDisabled={sensors.length === 0}
                options={sensors.map((sensor) => {
                  return { label: sensor.name, value: sensor.namespace };
                })}
                value={sensors
                  .map((sensor) => {
                    return { label: sensor?.name, value: sensor?.namespace };
                  })
                  .filter((sensor) => sensor?.value === selectedSensor?.value)}
              />
            </div>
          )}

          <div className="report-analogic-date-picker">
            <Form>
              <DatePickerDropdown
                setDate={(val) => {
                  let startPeriod = "";
                  let endPeriod = "";
                  if (_.isArray(val)) {
                    if (val.length === 1) {
                      endPeriod =
                        typeof val[0] === "string"
                          ? val[0]
                          : val[0].format("YYYY-MM-DDTHH:mm");
                      const startPeriodDate = new Date(endPeriod);
                      startPeriodDate.setHours(0, 0, 0, 0);
                      startPeriod =
                        dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
                    } else {
                      startPeriod =
                        typeof val[0] === "string"
                          ? val[0]
                          : val[0].format("YYYY-MM-DDTHH:mm");
                      endPeriod = val[1]?.format("YYYY-MM-DDTHH:mm") ?? "";
                    }
                  }
                  setIsSelectedPeriod(true);
                  if (val.length > 1) {
                    setDateRange([startPeriod, endPeriod]);
                  } else {
                    setDateRange([]);
                  }
                  const map = new Map();
                  map.set("startPeriod", startPeriod);
                  map.set("endPeriod", endPeriod);
                  handleChanges(map);
                }}
                hasTime={true}
                localeFormat={
                  preferencesContext.localeFormat ?? "DD / MM / YYYY"
                }
                language={preferencesContext.language ?? "it"}
                icon={
                  <IconCalendar size={12} color="--global-colors-ink-light" />
                }
                initialValue={initialDateValue.length ? initialDateValue : []}
                defaultValue={initialDateValue[1]}
                dateRange={[dayjs().subtract(1, "year"), dayjs()]} //From today to past 1 year
                limitDaysRange={7}
                clearCallback={() => {
                  const map = new Map();
                  map.set("startPeriod", "");
                  map.set("endPeriod", "");
                  handleChanges(map);
                  setInitialDateValue([]);
                }}
              />
            </Form>
          </div>

          <div className="ior-filterbar-btn-generate">
            <Button
              aspect="primary"
              size="small"
              label={t("report.filterBar.buttonGenerate")}
              onClick={() => {
                generate();
              }}
              isLoading={sliceLoaderStatus === "loading"}
              disabled={generationDisabled()}
            />
          </div>
        </div>
        <div>
          <div className="ior-filterbar-btn ">
            <div className="ior-filterbar-btn-schedule">
              <DropdownButton
                aspect="secondary"
                size="small"
                label={t("report.filterBar.schedule")}
                onClick={() =>
                  store.dispatch(
                    getReportsMetadataAsync(
                      "?lang=" + preferencesContext.language
                    )
                  )
                }
                disabled={false}
                list={
                  [
                    {
                      id: 0,
                      title: t("common.schedulate"),
                      icon: <IconAdd size={14} color={"#687484"} />,
                      onClick: () => {
                        setSchedulationModal(!schedulationModal);
                      },
                    },
                    {
                      id: 0,
                      title: t("common.schedulationList"),
                      icon: <IconList size={14} color={"#687484"} />,
                      onClick: () => {
                        setManageModalOpened(true);
                      },
                      disabled:
                        filterSchedulesByType("ANALOGIC_REPORT").length === 0,
                    },
                  ] as any
                }
              >
                <IconCalendar size={14} color="--global-colors-ink-ink" />
              </DropdownButton>
            </div>
            <DropdownButton
              aspect="secondary"
              size="small"
              disabled={
                !disabledButton(
                  isSelectedPeriod,
                  vehicleId,
                  sensors,
                  selectedSensor
                )
              }
              label={t("common.export")}
              list={list}
            >
              <IconDown size={14} color="--global-colors-ink-ink" />
            </DropdownButton>
          </div>
          {schedulationModal && (
            <SchedulationModal
              reportType="ANALOGIC_REPORT"
              schedulationId={
                pathMatchEditingSchedulation?.params?.schedulationId
              }
              onClose={() => {
                setSchedulationModal(false);
              }}
              isOpen={schedulationModal}
              customEntities={vehicles.map((vehicle) => {
                return {
                  title: vehicle.alias,
                  id: vehicle.id,
                };
              })}
              hasSingleElement={true}
              customEntitiesParamName="vehicleId"
              customTextLabel={t("common.selectVehicle")}
              customIcon={<IconVehicle size={14} />}
              paramsComponents={[
                <Dropdown
                  itemAttribute="label"
                  placeholderInValue={t("report.analogic.status.status")}
                  size="small"
                  onChange={(val: any) => {
                    setSelectedStatusSchedulation(val[0]);
                  }}
                  options={statusOptions}
                  value={statusOptions.filter(
                    (status) =>
                      status.value === selectedStatusSchedulation?.value
                  )}
                />,
                <Dropdown
                  itemAttribute="label"
                  placeholder={t("report.analogic.sensors")}
                  placeholderInValue={t("report.analogic.sensors") + " :"}
                  size="small"
                  isDisabled={sensorsForSchedulationModal.length === 0}
                  onChange={(val: any) => {
                    setSelectedSensorSchedulation(val[0]);
                    const map = new Map();
                    map.set("namespace", val[0].value);
                    handleChanges(map);
                  }}
                  options={sensorsForSchedulationModal.map((sensor) => {
                    return { label: sensor.name, value: sensor.namespace };
                  })}
                  value={sensorsForSchedulationModalSelected}
                />,
              ]}
              paramsValue={sensorsForSchedulationModalSelected}
              params={queryParams}
              paramsToSchedulate={["namespace"]}
            />
          )}
          <SchedulationManageModal
            schedulationAddModal={(e) => setSchedulationModal(e)}
            reportType={"ANALOGIC_REPORT"}
            isOpen={manageModalOpened}
            onClose={() => {
              setManageModalOpened(false);
            }}
            schedulations={filterSchedulesByType("ANALOGIC_REPORT")}
          />
        </div>
      </div>
    );
  };
