import { schema } from "normalizr";

export const beaconSchema = new schema.Entity(
  "beacon",
  {},
  { idAttribute: "namespace" }
);

// Schema per un array di beacon
export const beaconsSchema = new schema.Array(beaconSchema);
