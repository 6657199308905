import datadogRum from "../datadog/datadogConf";
import HttpClient from "./HttpClient";

export default class AuthenticationService extends HttpClient {
  constructor() {
    super(false);
  }

  login(
    myUserName: string,
    myPassword: string,
    successCallbackFn: (a: any) => any,
    errorCallbackFn: (b: any) => any
  ) {
    this.instance
      .post("/auth/login", {
        userName: myUserName,
        password: myPassword,
      })
      .then(async (response) => {
        const userData = response.data;
        localStorage.setItem("jwtToken", userData.token);
        localStorage.setItem("refreshToken", userData.refreshToken);
        localStorage.setItem("customerId", userData.customerId);
        localStorage.setItem("tenantId", userData.tenantId);
        localStorage.setItem(
          "fullName",
          userData.firstName + " " + userData.secondName
        );
        localStorage.setItem("email", userData.email);
        localStorage.setItem("tenantEmail", userData.tenantEmail);
        localStorage.setItem("chatToken", userData.chatToken);
        localStorage.setItem("username", userData.username);
        await this.saveToCache("username", userData.username);
        localStorage.setItem("preferencesId", userData.preferencesId);

        localStorage.setItem("profilePic", userData.profilePicture);
        if (userData.platformLogo) {
          localStorage.setItem("logoType", userData.platformLogo);
        }
        successCallbackFn(userData);

        datadogRum.setUser({
          id: userData.customerId,
          name: userData.firstName + " " + userData.secondName,
          email: userData.email,
          token: userData.refreshToken,
        });

        datadogRum.startSessionReplayRecording();
      })
      .catch((error) => {
        errorCallbackFn(error);
      });
  }

  loginFromHS(
    key: string,
    successCallbackFn: (a: any) => any,
    errorCallbackFn: (b: any) => any
  ) {
    this.instance
      .post("/auth/loginFromHS", {
        key: key,
      })
      .then(async (response) => {
        const userData = response.data;
        localStorage.setItem("jwtToken", userData.token);
        localStorage.setItem("refreshToken", userData.refreshToken);
        localStorage.setItem("customerId", userData.customerId);
        localStorage.setItem("tenantId", userData.tenantId);
        localStorage.setItem(
          "fullName",
          userData.firstName + " " + userData.secondName
        );
        localStorage.setItem("email", userData.email);
        localStorage.setItem("tenantEmail", userData.tenantEmail);
        localStorage.setItem("chatToken", userData.chatToken);
        localStorage.setItem("username", userData.username);
        await this.saveToCache("username", userData.username);
        localStorage.setItem("preferencesId", userData.preferencesId);

        localStorage.setItem("profilePic", userData.profilePicture);
        successCallbackFn(userData);

        datadogRum.setUser({
          id: userData.customerId,
          name: userData.firstName + " " + userData.secondName,
          email: userData.email,
          token: userData.refreshToken,
        });

        datadogRum.startSessionReplayRecording();
      })
      .catch((error) => {
        localStorage.removeItem("firebaseToken");
        localStorage.removeItem("jwtToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("tenantId");
        localStorage.removeItem("customerId");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("tenantEmail");
        localStorage.removeItem("chatToken");
        localStorage.removeItem("fullName");
        localStorage.removeItem("preferencesId");
        localStorage.removeItem("profilePic");
        this.removeFromCache("username");
        errorCallbackFn(error);
      });
  }

  logout() {
    datadogRum.stopSessionReplayRecording();
    return super.logout();
  }

  generateCode(email: string) {
    return this.instance.post("/auth/generateCode", {
      email: email,
    });
  }

  validateCode(generatedCode: string) {
    return this.instance.get("/auth/validateCode/" + generatedCode);
  }

  resetPassword(newPassword: string, generatedCode: string) {
    return this.instance.put("/auth/resetPassword", {
      code: generatedCode,
      newPassword: newPassword,
    });
  }

  validate(
    channel?: string,
    entity?: string,
    entityId?: string,
    validationCode?: string,
    language?: string
  ) {
    return this.instance.put(
      "/auth/entities/" +
        entity +
        "/" +
        entityId +
        "/channel/" +
        channel +
        "/validationcode/" +
        validationCode +
        "?language=" +
        language
    );
  }
}
