import i18next from "i18next";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import { store } from "../app/store";
import { GTFleetSuccessCodes } from "../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../config/GTfleetErrorCodes";
import { Button } from "../ui/Button/Button";
import { Check } from "../ui/Indicators/Check";
import { LabelText } from "../ui/LabelText/LabelText";
import { Logo } from "../ui/Logo/Logo";
import "./Validation.css";
import {
  selectValidationsSliceReasonCode,
  selectValidationsSliceStatus,
  validateAsync,
} from "./validationSlice";

interface QueryParams {
  validationType: string;
  validationEntity: string;
  validationId: string;
  validationCode: string;
  language: string;
}

const Validation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useState({} as QueryParams);
  const [searchParams] = useSearchParams();

  const validationsSliceReasonCode = useAppSelector(
    selectValidationsSliceReasonCode
  );
  const validationSliceStatus = useAppSelector(selectValidationsSliceStatus);

  useEffect(() => {
    setQueryParams({
      validationType: searchParams.get("validationType") ?? "",
      validationEntity: searchParams.get("validationEntity") ?? "",
      validationId: searchParams.get("validationId") ?? "",
      validationCode: searchParams.get("validationCode") ?? "",
      language: searchParams.get("language") ?? "",
    });

    if (queryParams?.language) {
      i18next.changeLanguage(queryParams.language).catch((error) => {
        console.log(error);
      });
    }
  }, [searchParams]);

  // Chiamata API
  useEffect(() => {
    if (searchParams) {
      (async () => {
        try {
          await store.dispatch(
            validateAsync({
              channel: searchParams.get("validationType") ?? "",
              entity: searchParams.get("validationEntity") ?? "",
              entityId: searchParams.get("validationId") ?? "",
              validationCode: searchParams.get("validationCode") ?? "",
              language: searchParams.get("language") ?? "",
            })
          );
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, []);

  const handleNotValidated = () => {
    switch (validationsSliceReasonCode) {
      case GTFleetErrorCodes.CODE_NOT_FOUND:
        return t("pages.validation.notFoundCodeMessage");
      case GTFleetErrorCodes.VALIDATION_CODE_DATE_EXPIRED:
        return t("pages.validation.expiredCodeMessage");
      case GTFleetErrorCodes.VALIDATION_CODE_DOES_NOT_MATCH:
        return t("pages.validation.mismatchedCodeMessage");
      case GTFleetErrorCodes.VALIDATION_CODE_ALREDY_USED:
        return t("pages.validation.alreadyUsedCodeMessage");
      case GTFleetErrorCodes.ADDRESS_BOOK_NOT_FOUND:
        return t("pages.validation.addessBookNotFound");
      case GTFleetErrorCodes.DRIVER_NOT_FOUND:
        return t("pages.validation.driverNotFound");
      case GTFleetErrorCodes.USERNAME_NOT_FOUND:
        return t("pages.validation.usernameNotFound");
    }
  };

  return (
    <div className="validation">
      <div className="validation-logo">
        <Logo type="fleet" mode="colors" />
      </div>
      {validationSliceStatus === "loading" ? (
        <div className="validation-loader" />
      ) : (
        <div className="validation-content">
          {validationsSliceReasonCode === GTFleetSuccessCodes.PUT ? (
            <div className="validation-success">
              <Check status="success" />
              {queryParams.validationType === "EMAIL" ? (
                <LabelText
                  label={t("pages.validation.verified")}
                  text={t("pages.validation.successEmailMessage")}
                />
              ) : (
                <LabelText
                  label={t("pages.validation.verified")}
                  text={t("pages.validation.successPhoneMessage")}
                />
              )}
            </div>
          ) : (
            <div className="validation-fail">
              <Check status="failed" />
              <LabelText
                label={t("pages.validation.notVerified")}
                text={handleNotValidated()}
              />
            </div>
          )}
          <Button
            aspect="primary"
            size="small"
            label={t("pages.validation.goToDashboard")}
            onClick={() => navigate("/dashboard/fleet-control")}
          />
        </div>
      )}
    </div>
  );
};

export default Validation;
