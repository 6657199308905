import dayjs from "dayjs";
import { t } from "i18next";
import { useContext } from "react";
import { NavigateFunction } from "react-router-dom";
import { GTFleetErrorCodes } from "../../../config/GTfleetErrorCodes";
import { DropdownButton, ListItem } from "../../../ui/Button/DropdownButton";
import { IconDevices } from "../../../ui/Icon/Line/Devices";
import { IconDownload } from "../../../ui/Icon/Line/Download";
import {
  AbstractTableBuilder,
  Row,
} from "../../../ui/Table/utils/AbstractTableBuilder";
import "../../../ui/Table/utils/TableBuilder.css";
import { ToastNotification } from "../../../utils/ToastNotification";
import {
  convertMillisecondsToShortedTime,
  getMonthStringified,
  getQueryString,
  numberAnnotation,
} from "../../../utils/Utils";
import { Preferences } from "../../users/preference/preferencesSlice";
import UserContext from "../../users/userContext";
import ReportsRepository from "../reportRepository";
import { SensorSummary } from "./analogicReportSummarySlice";

interface QueryParams {
  [paramName: string]: any;
}

export class AnalogicReportSensorSummaryTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      SensorSummary?: SensorSummary;
    },
    thresholdMax: number,
    thresholdMin: number,
    vehicleId: number,
    navigate: NavigateFunction,
    preferences: Preferences
  ) => {
    let row: Row = { render: {} as Row };
    const [preferencesContext]: [Preferences] = useContext(UserContext);

    const reportsRepository = new ReportsRepository();

    const downloadResult = (
      pdf: boolean,
      queryString: string,
      isSelectedPeriod: boolean
    ) => {
      if ((vehicleId && queryString !== "") || isSelectedPeriod) {
        const params =
          queryString +
          (pdf ? "&isPdf=true" : "&isPdf=false") +
          "&presetName=Default";

        reportsRepository
          .getAnalogicReportDownload(params, vehicleId)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            const fileName = data.split("/").pop();
            const attachmentDownloadLink = document.createElement("a");
            attachmentDownloadLink.href =
              process.env.REACT_APP_BUCKET_URL + data;
            attachmentDownloadLink.download = fileName;
            attachmentDownloadLink.target = "_blank";
            attachmentDownloadLink.style.display = "none";
            document.body.appendChild(attachmentDownloadLink);
            attachmentDownloadLink.click();
            document.body.removeChild(attachmentDownloadLink);
          })
          .catch((error) => {
            if (
              error.response &&
              error.response.data.message ==
                GTFleetErrorCodes.REPORT_TIMEOUT_EXCEPTION
            ) {
              console.log(error?.message || "Report Timeout Exception");
              ToastNotification({
                toastId: "reportTimeoutError",
                status: "default",
                title: t("common.reportTimeoutErrorTitle"),
                description: t("common.reportTimeoutErrorDesc"),
              });
            } else {
              console.log(error.response?.data?.error || "Unknown Error");
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t("common.networkError"),
              });
            }
          });
      }
    };

    let sensorSummary: SensorSummary =
      entities.SensorSummary ?? ({} as SensorSummary);

    function renderThresholdAlert(temperature: number) {
      if (temperature > thresholdMax || temperature < thresholdMin) {
        return (
          <>
            <span style={{ color: "#FF4F48" }}>
              {temperature.toString().slice(0, 5) + " °"}
            </span>
          </>
        );
      } else {
        return (
          <>
            <span>{temperature.toString().slice(0, 5) + " °"}</span>
          </>
        );
      }
    }

    function renderAlerts(alert: number) {
      if (alert > 0) {
        return (
          <>
            <span style={{ color: "#FF4F48" }}>{alert}</span>
          </>
        );
      } else {
        return (
          <>
            <span>{alert}</span>
          </>
        );
      }
    }

    const dropdownButtonList: ListItem[] = [
      {
        id: 0,
        title: t("report.analogic.dropdownSummary.locationHistory"),
        onClick: () => {
          let params = getQueryString({
            firstPointDate: [
              dayjs(new Date(sensorSummary?.date).setHours(0, 0, 0, 0)).format(
                "YYYY/MM/DD HH:mm"
              ),
              dayjs(
                new Date(sensorSummary?.date).setHours(23, 59, 59, 999)
              ).format("YYYY/MM/DD HH:mm"),
            ],
            "vehicle.id": vehicleId,
          });
          window.open("/dashboard/vehicles/location-history/tracks" + params);
        },
      },
      {
        id: 1,
        title: t("report.analogic.dropdownSummary.details"),
        onClick: () => {
          navigate(
            "/reports/analogic-report/" +
              sensorSummary?.uuid +
              "/details/" +
              sensorSummary?.uuid
          );
        },
      },
    ];

    columns &&
      columns.forEach((column) => {
        switch (column.field) {
          case "sensorName":
            row.render.sensorName = (
              <div className="table-report-archive-row-element">
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    <IconDevices size={14} color={"#687484"} />
                    {sensorSummary?.name}
                  </div>
                </>
              </div>
            );
            break;
          case "date":
            row.render.date = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.date &&
                      new Date(sensorSummary.date).getDate() +
                        " " +
                        t(
                          getMonthStringified(
                            new Date(sensorSummary.date).getMonth()
                          )
                        ) +
                        " " +
                        new Date(sensorSummary.date).getFullYear()}
                  </div>
                </div>
              </>
            );
            break;
          case "traveled":
            row.render.kmTraveled = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {`${numberAnnotation(
                      sensorSummary?.kilometersTraveled / 1000
                    )} ${preferencesContext.isMetric ? "km" : "mil"}`}
                  </div>
                </div>
              </>
            );
            break;
          case "parkingHours":
            row.render.parkingHours = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {convertMillisecondsToShortedTime(
                      sensorSummary?.parkingTime
                    ).replace(":", " h ") + "m"}
                  </div>
                </div>
              </>
            );
            break;
          case "alerts":
            row.render.alerts = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {renderAlerts(sensorSummary.numberEvent ?? 0)}
                  </div>
                </div>
              </>
            );
            break;
          case "avgTemperature":
            row.render.avgTemperature = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {renderThresholdAlert(sensorSummary?.avgTemperature)}
                  </div>
                </div>
              </>
            );
            break;
          case "avgRh":
            row.render.avgRh = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.avgHumidity.toString().slice(0, 5) + " %"}
                  </div>
                </div>
              </>
            );
            break;
          case "maxTemperature":
            row.render.maxTemperature = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {renderThresholdAlert(sensorSummary?.maxTemperature)}
                  </div>
                </div>
              </>
            );
            break;
          case "minTemperature":
            row.render.minTemperature = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {renderThresholdAlert(sensorSummary?.minTemperature)}
                  </div>
                </div>
              </>
            );
            break;
          case "maxRh":
            row.render.maxRh = (
              <>
                <div className="table-report-archive-row-element">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.maxHumidity + " %"}
                  </div>
                </div>
              </>
            );
            break;
          case "minRh":
            row.render.minRh = (
              <>
                <div
                  className="table-report-archive-row-element"
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                    }}
                  >
                    {sensorSummary?.minHumidity + " %"}
                  </div>
                  <div
                    style={{
                      marginLeft: "100px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "13px",
                    }}
                  >
                    <div>
                      <DropdownButton
                        aspect={"ghost"}
                        size={"small"}
                        list={dropdownButtonList}
                      />
                    </div>
                    <div
                      onClick={() => {
                        const endPeriodDate = new Date(sensorSummary.date);
                        endPeriodDate.setHours(23, 59, 59, 0);
                        const endPeriod =
                          dayjs(endPeriodDate).format("YYYY-MM-DDTHH:mm");
                        downloadResult(
                          false,
                          getQueryString({
                            namespace: sensorSummary.namespace,
                            startPeriod: sensorSummary.date,
                            endPeriod: endPeriod,
                          }),
                          true
                        );
                      }}
                    >
                      <IconDownload size={14} color={"#687484"} />
                    </div>
                  </div>
                </div>
              </>
            );
            break;
          default:
            break;
        }
      });

    return row;
  };
}
