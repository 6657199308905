import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Section } from "../../../../ui/Modal/CustomModals/AddressBookModal";

import _, { isEqual } from "lodash";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../app/hooks";
import { store } from "../../../../app/store";
import { GTFleetSuccessCodes } from "../../../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../../../config/GTfleetErrorCodes";
import { Button } from "../../../../ui/Button/Button";
import { IconTrash } from "../../../../ui/Icon/Line/Trash";
import { ModalBody } from "../../../../ui/Modal/ModalBody";
import { ModalFooter } from "../../../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../../../ui/Modal/ModalWrapper";
import { ToastNotification } from "../../../../utils/ToastNotification";
import { getQueryString } from "../../../../utils/Utils";
import {
  emptyStatusAndReasonCode,
  selectValidationsSliceReasonCode,
  selectValidationsSliceStatus,
  sendValidationAsync,
  validationsEmptyState,
} from "../../../../validation/validationSlice";
import {
  AddressBook,
  addressBooksEmptyState,
  createAddressBookAsync,
  deleteAddressBookAsync,
  editAddressBookAsync,
  getAddressBooksAsync,
  getFilteredAddressBookAsync,
  selectaddressBookSliceReasonCode,
  selectaddressBookSliceStatus,
} from "../../../address/addressBookSlice";
import AddEditDeleteContactModal from "./AddEditValidateDeleteContactModal";
import "./AddressBookModal.css";
import { ContactsListModal } from "./ContactListModal";
interface AddressBookModalProps {
  open: boolean;
  sections: Section[];
  currentActive: string;
  allComponents?: boolean;
  onClose: (e: any) => any;
}

interface QueryParams {
  [paramName: string]: any;
}

let fetchUsers: (viewUserRoute: any) => any;
let userPolling: any;
export const AddressBookModals: React.FC<AddressBookModalProps> = ({
  open,
  sections,
  allComponents,
  onClose,
  currentActive,
}) => {
  const location = useLocation();
  const { pathname } = location;

  const isAddContact = pathname.includes("/add-contact");
  const isEditContact = pathname.includes("/edit-contact");
  const isValidateContact = pathname.includes("/validate-contact");
  const isDeleteContact = pathname.includes("/delete-contact");
  const isContactsList = pathname.includes("/contacts-list");
  const isAddContactInCreateRule =
    pathname.includes("/add-rule") && pathname.includes("/add-contact");
  const isAddContactInEditRule =
    pathname.includes("/edit-rule") && pathname.includes("/add-contact");
  const editNotificationRoute = useMatch(
    "/admin/notifications/edit-rule/:notificationId/add-contact"
  );

  const [sidebarSelected, setSidebarSelected] = useState(currentActive);
  const [addressBook, setAddressBook] = useState<AddressBook>(
    {} as AddressBook
  );
  const [editAddressBook, setEditAddressBook] = useState<AddressBook>(
    {} as AddressBook
  );
  const [errorList, setErrorList] = useState<string[]>([]);
  const navigate = useNavigate();

  const queryParamsRef = useRef<QueryParams>({});
  let queryParams: QueryParams = queryParamsRef.current;

  let queryString = "";
  queryString = getQueryString(queryParams);

  const addressBookSliceStatus = useAppSelector(selectaddressBookSliceStatus);
  const addressBookSliceReasonCode = useAppSelector(
    selectaddressBookSliceReasonCode
  );

  const validationSliceStatus = useAppSelector(selectValidationsSliceStatus);
  const validationSliceReasonCode = useAppSelector(
    selectValidationsSliceReasonCode
  );

  useEffect(() => {
    setSidebarSelected(currentActive);
  }, [currentActive]);

  const addContactSection = sections.find((el) => el.name === "add-contact");
  const contactsListSection = sections.find(
    (el) => el.name === "contacts-list"
  );

  /**
   * This function dispacth a call to get all status message from id
   * and set timer for polling
   */
  fetchUsers = (queryString: any) => {
    store.dispatch(
      getFilteredAddressBookAsync({
        queryParams: queryString,
      })
    );

    userPolling = setTimeout(() => fetchUsers(queryString), 60000);
  };

  useEffect(() => {
    !allComponents && fetchUsers(queryString);
    return () => {
      if (!allComponents) {
        clearTimeout(userPolling);
        store.dispatch(validationsEmptyState());
        store.dispatch(addressBooksEmptyState());
      }
    };
  }, []);

  //#region toast notifications
  useEffect(() => {
    if (
      addressBookSliceStatus === "idle" &&
      addressBookSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "addressBookCreateSuccess",
        status: "success",
        description: t("admin.notifications.addressBook.success.CREATED"),
      });
      !allComponents && navigate(getNavigationStatement());
      store.dispatch(addressBooksEmptyState());
      store.dispatch(getAddressBooksAsync({}));
    }
    if (
      addressBookSliceStatus === "idle" &&
      addressBookSliceReasonCode === GTFleetSuccessCodes.PUT
    ) {
      ToastNotification({
        toastId: "addressBookEditSuccess",
        status: "success",
        description: t("admin.notifications.addressBook.success.UPDATED"),
      });
      !allComponents && navigate(getNavigationStatement());
    }
    if (
      addressBookSliceStatus === "idle" &&
      addressBookSliceReasonCode === GTFleetSuccessCodes.DELETE
    ) {
      ToastNotification({
        toastId: "addressBookDeleteSuccess",
        status: "success",
        description: t("admin.notifications.addressBook.success.DELETE"),
      });
      !allComponents && navigate(getNavigationStatement());
    }
    if (
      addressBookSliceStatus === "failed" &&
      addressBookSliceReasonCode ===
        GTFleetErrorCodes.ADDRESS_BOOK_ALREADY_EXISTS
    ) {
      ToastNotification({
        toastId: "addressBookAlreadyExists",
        status: "error",
        description: t(
          "admin.notifications.addressBook.error.ADDRESS_BOOK_ALREADY_EXISTS"
        ),
      });
      !allComponents && navigate("/admin/notifications/contacts-list");
    } else if (
      addressBookSliceStatus === "failed" &&
      addressBookSliceReasonCode === GTFleetErrorCodes.ADDRESS_BOOK_NOT_FOUND
    ) {
      ToastNotification({
        toastId: "addressBookNotFound ",
        status: "error",
        description: t(
          "admin.notifications.addressBook.error.ADDRESS_BOOK_NOT_FOUND"
        ),
      });
      !allComponents && navigate("/admin/notifications/contacts-list");
    } else if (
      addressBookSliceStatus === "failed" &&
      addressBookSliceReasonCode ===
        GTFleetErrorCodes.ADDRESS_BOOK_ASSOCIATED_TO_NOTIFICATION_RULE
    ) {
      ToastNotification({
        toastId: "addressBookNotFound ",
        status: "error",
        description: t(
          "admin.notifications.addressBook.error.ADDRESS_BOOK_ASSOCIATED_TO_NOTIFICATION_RULE"
        ),
      });
      !allComponents && navigate("/admin/notifications/contacts-list");
    } else if (
      addressBookSliceStatus === "failed" &&
      addressBookSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [addressBookSliceStatus, addressBookSliceReasonCode, navigate]);
  //#endregion toast notifications

  useEffect(() => {
    if (
      validationSliceStatus === "idle" &&
      validationSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "userValidationSendSuccess",
        status: "success",
        description: t("validation.validationSendSuccess"),
      });
    }
    if (
      validationSliceStatus === "failed" &&
      validationSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    store.dispatch(emptyStatusAndReasonCode());
  }, [validationSliceStatus, validationSliceReasonCode]);

  const handleAddressBookChange = (addressBook: AddressBook) => {
    setAddressBook(addressBook);
  };

  const handleAddressBookEdit = (editAddressBook: AddressBook) => {
    setEditAddressBook(editAddressBook);
  };

  const createActionOnClick = () => {
    if (Object.values(addressBook).length > 2) {
      store.dispatch(createAddressBookAsync({ addressBook: addressBook }));
    }
    if (allComponents) {
      onClose(null);
    }
  };

  const editActionOnClick = () => {
    if (Object.values(addressBook).length > 2) {
      store.dispatch(editAddressBookAsync({ addressBook: addressBook }));
    }
  };

  const deleteActionOnClick = () => {
    if (Object.values(addressBook).length > 2) {
      store.dispatch(deleteAddressBookAsync(addressBook.id));
    }
  };

  const validateActionOnClick = () => {
    store.dispatch(
      sendValidationAsync({
        channel: "EMAIL",
        entity: "ADDRESS_BOOK",
        entityId: addressBook.id.toString() ?? "",
      })
    );

    store.dispatch(
      sendValidationAsync({
        channel: "PHONE",
        entity: "ADDRESS_BOOK",
        entityId: addressBook.id.toString() ?? "",
      })
    );
    navigate("/admin/notifications/contacts-list");
  };

  function getNavigationStatement() {
    const navigateStatement = "/admin/notifications/contacts-list";
    if (isAddContactInCreateRule) {
      return "/admin/notifications/add-rule";
    }
    if (isAddContactInEditRule) {
      return `/admin/notifications/edit-rule/${editNotificationRoute?.params.notificationId}`;
    }
    if (
      isAddContact ||
      isEditContact ||
      isValidateContact ||
      isDeleteContact ||
      isContactsList
    ) {
      return navigateStatement;
    }
    return navigateStatement;
  }

  const handleSidebarItemClick = (sectionName: string) => {
    setSidebarSelected(sectionName);
    !allComponents &&
      navigate({
        pathname: `/admin/notifications/${sectionName}`,
        search: queryString,
      });
  };

  function primaryLabelBehaviour() {
    if (allComponents) {
      return t("common.save");
    }

    if (isContactsList || isAddContact) {
      return t("admin.notifications.modal.saveAndExit");
    } else if (isDeleteContact) {
      return t("common.delete");
    } else if (isValidateContact) {
      return t("common.confirm");
    } else if (isEditContact) {
      return t("common.save");
    } else {
      return "";
    }
  }

  function primaryLabelAction() {
    if (allComponents) {
      return createActionOnClick();
    }
    if (isAddContact) {
      return createActionOnClick();
    } else if (isDeleteContact) {
      return deleteActionOnClick();
    } else if (isValidateContact) {
      return validateActionOnClick();
    } else if (isEditContact) {
      return editActionOnClick();
    }
  }

  function disablePrimaryButton() {
    if (allComponents) {
      if (
        _.isEmpty(errorList) &&
        addressBook.firstName &&
        addressBook.lastName &&
        ((addressBook.telephonePrefix && addressBook.telephone?.length == 10) ||
          addressBook.email)
      ) {
        return false;
      } else {
        return true;
      }
    }
    if (isContactsList) {
      return true;
    } else if (
      isAddContact &&
      _.isEmpty(errorList) &&
      addressBook.firstName &&
      addressBook.lastName &&
      ((addressBook.telephonePrefix && addressBook.telephone?.length == 10) ||
        addressBook.email)
    ) {
      return false;
    } else if (
      isEditContact &&
      !isEqual(addressBook, editAddressBook) &&
      _.isEmpty(errorList) &&
      ((addressBook.telephonePrefix && addressBook.telephone?.length == 10) ||
        addressBook.email)
    ) {
      return false;
    } else if (isValidateContact) {
      return false;
    } else if (isDeleteContact) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="addressBook-modal">
      <ModalWrapper open={open} closeAction={onClose}>
        <div className="addressBook-modal-body">
          <ModalBody
            title={t("admin.notifications.modal.titleModalAddressBook")}
            desc={t("admin.notifications.modal.descModalAddressBook")}
          >
            <div className="left-addressBook-column">
              {sidebarSelected === "add-contact" && addContactSection && (
                <AddEditDeleteContactModal
                  onAddressBookChange={handleAddressBookChange}
                  onErrorListChange={setErrorList}
                  open={true}
                  sections={[{ id: 0, name: "add-contact" }]}
                  currentActive={currentActive}
                  onClose={onClose}
                />
              )}
              {sidebarSelected === "contacts-list" &&
                contactsListSection &&
                isContactsList && (
                  <ContactsListModal
                    open={true}
                    sections={[{ id: 1, name: "contacts-list" }]}
                    currentActive={currentActive}
                    onClose={onClose}
                  />
                )}
              {sidebarSelected === "contacts-list" &&
                contactsListSection &&
                isEditContact && (
                  <AddEditDeleteContactModal
                    onAddressBookChange={handleAddressBookChange}
                    onAddressBookRetrieve={handleAddressBookEdit}
                    onErrorListChange={setErrorList}
                    open={true}
                    sections={[{ id: 2, name: "edit-contact" }]}
                    currentActive={currentActive}
                    onClose={onClose}
                  />
                )}
              {sidebarSelected === "contacts-list" &&
                contactsListSection &&
                isValidateContact && (
                  <AddEditDeleteContactModal
                    onAddressBookChange={handleAddressBookChange}
                    open={true}
                    sections={[{ id: 3, name: "validate-contact" }]}
                    currentActive={currentActive}
                    onClose={onClose}
                  />
                )}
              {sidebarSelected === "contacts-list" &&
                contactsListSection &&
                isDeleteContact && (
                  <AddEditDeleteContactModal
                    onAddressBookChange={handleAddressBookChange}
                    open={true}
                    sections={[{ id: 4, name: "delete-contact" }]}
                    currentActive={currentActive}
                    onClose={onClose}
                  />
                )}
              <div className="section-button-list">
                {sections.map((section) => (
                  <Button
                    key={section.name}
                    size="regular"
                    aspect="ghost"
                    label={t(`admin.notifications.modal.${section.name}`)}
                    active={sidebarSelected === section.name}
                    onClick={() => handleSidebarItemClick(section.name)}
                  />
                ))}
              </div>
            </div>
          </ModalBody>
        </div>
        <ModalFooter
          primaryLabel={primaryLabelBehaviour()}
          primaryAction={() => primaryLabelAction()}
          isDanger={isDeleteContact ? true : false}
          iconFooter={
            isDeleteContact ? (
              <IconTrash size={14} color="--global-colors-white" />
            ) : (
              ""
            )
          }
          disablePrimaryButton={disablePrimaryButton()}
          hasBack={
            isEditContact || isDeleteContact || isValidateContact ? true : false
          }
          backAction={() => navigate(-1)}
          secondaryLabel={t("admin.notifications.modal.cancel")}
          secondaryAction={onClose}
        />
      </ModalWrapper>
    </div>
  );
};

export default AddressBookModals;
