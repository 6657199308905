import { Cluster } from "@googlemaps/markerclusterer";
import { Easing, Tween } from "@tweenjs/tween.js";
import _ from "lodash";
import { useContext, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { RouteButton } from "../../ui/FleetControl/RouteButton";
import { IconKey } from "../../ui/Icon/Solid/Key";
import { IconLocator } from "../../ui/Icon/Solid/Locator";
import { Actions } from "../../ui/Map/Actions";
import { BaseMap, ComponentOnMap } from "../../ui/Map/BaseMap";
import { MapType } from "../../ui/Map/MapType";
import { MarkerAsComponent } from "../../ui/Map/MarkerAsComponent";
import { Zoom } from "../../ui/Map/Zoom";
import { ClusterMarker } from "../../ui/Marker/ClusterMarker";
import { MapMarkerCustomIcon } from "../../ui/Marker/MapMarkerCustomIcon";
import { MapMarkerPin } from "../../ui/Marker/MapMarkerPin";
import { MapMarkerPositionDirection } from "../../ui/Marker/MapMarkerPositionDirection";
import { getQueryString } from "../../utils/Utils";
import { AccessibleMarkerClusterer } from "../geofence/AccessibleMarkerClusterer";
import {
  Geofence,
  geofencesSelectors,
  getGeofencesAsync,
} from "../geofence/geofenceSlice";
import {
  geofenceCategoriesSelectors,
  GeofenceCategory,
} from "../geofenceCategory/geofenceCategoriesSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import UserContext from "../users/userContext";
import "./FleetControlMap.css";

interface PointObj {
  latitude: number;
  longitude: number;
  direction: number;
  timestamp: Date;
}

interface LiveTrackingMapProps {
  id: number;
  googleMapsApiKey: string;
  zoom: number;
  hasStreetView: boolean;
  latitude: number;
  longitude: number;
  currentPoint?: PointObj;
  trackingPoints: PointObj[];
  geofenceCategoriesProp: any;
  geofencesProp: any;
}

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
    onwebkitfullscreenchange?: any;
    onmsfullscreenchange?: any;
    onmozfullscreenchange?: any;
  }

  interface HTMLElement {
    msRequestFullScreen?: () => Promise<void>;
    mozRequestFullScreen?: () => Promise<void>;
    webkitRequestFullScreen?: () => Promise<void>;
  }
}

let trackingPolyline: any = null;
let currentPosition: any = null;

let dragListener: google.maps.MapsEventListener;
let zoomListener: google.maps.MapsEventListener;
let repositioning: google.maps.MapsEventListener;
let geofenceMarkers: google.maps.Marker[] = [];
let geofenceClusterMarkers: AccessibleMarkerClusterer;
let visibleMarkers: any[] = []; // Array of visible markers
let nonVisibleMarkers: any[] = [];

export const LiveTrackingMap: React.FC<LiveTrackingMapProps> = ({
  id,
  googleMapsApiKey,
  zoom,
  hasStreetView,
  latitude,
  longitude,
  currentPoint,
  trackingPoints,
}) => {
  let googleMap = window.google;
  const [map, setMap] = useState<any>();
  /**
   * If map icons are loaded or not
   */
  const [mapIconsLoaded, setMapIconsLoaded] = useState(false);

  const [liveTrackingMarker, setLiveTrackingMarker] = useState<any>();
  const [firstPointMarker, setFirstPointMarker] = useState<any>();
  const [firstPointMarkerFullRoute, setFirstPointMarkerFullRoute] =
    useState<any>();
  const [firstPoint, setFirstPoint] = useState<any>();
  const [lastPosition, setLastPosition] = useState<google.maps.LatLng>();
  const [lastHeading, setLastHeading] = useState(0);
  const [componentsOnMap, setComponentsOnMap] = useState<ComponentOnMap[]>([]);
  const [isMapIdle, setIsMapIdle] = useState(false);

  const [visibleMarkersHook, setVisibleMarkersHook] = useState<
    google.maps.Marker[]
  >([]);
  const [nonVisibleMarkersHook, setNonVisibleMarkersHook] = useState<
    google.maps.Marker[]
  >([]);

  const [preferencesContext]: [Preferences] = useContext(UserContext);
  const [isSatellite, setIsSatellite] = useState<boolean>(
    preferencesContext.satelliteOnMap
  );
  const [showPrevPoints, setShowPrevPoints] = useState(false);

  let geofences: Geofence[] = useAppSelector(geofencesSelectors.selectAll);
  let geofenceCategories: GeofenceCategory[] = useAppSelector(
    geofenceCategoriesSelectors.selectAll
  );

  // This method is in charge to remove polyline after map unmounted
  // and does the api call to get the geofences
  useEffect(() => {
    store.dispatch(
      getGeofencesAsync({ queryParams: getQueryString({ status: "ACTIVE" }) })
    );
    return function cleanup() {
      trackingPolyline.setMap(null);
      trackingPolyline = null;
      currentPosition = null;
    };
  }, []);

  //#region This methods are used for mapType button
  // this method is used to activate street map view from mapType button
  const mapClick = () => {
    map.setMapTypeId("roadmap");
    setIsSatellite(false);
  };

  // this method is used to activate satellite view from mapType button
  const satelliteClick = () => {
    map.setMapTypeId("hybrid");
    setIsSatellite(true);
  };
  //#endregion

  //#region this method is in charge to add components on map
  const lastTrackingPoint = useRef({ latitude: 0, longitude: 0 });

  useEffect(() => {
    const componentsOnMapArray: ComponentOnMap[] = [];
    if (googleMap && map) {
      // update map type on change from fleetControlMap
      preferencesContext.satelliteOnMap
        ? map.setMapTypeId("hybrid")
        : map.setMapTypeId("roadmap");

      map.setTilt(55);
      // set zoom to 17 (minimum value for 3d). don't use bigger values to avoid
      // unwanted map scrolling without smooth animation
      map.setOptions({ zoom: 17, minZoom: 9, maxZoom: 21 });
      if (document.getElementById("trackingMarker") as HTMLElement) {
        (
          document.getElementById("trackingMarker") as HTMLElement
        ).style.display = "flex";
      }

      componentsOnMapArray.push({
        selectorName: ".action-control",
        selectorPosition: googleMap.maps.ControlPosition.TOP_RIGHT,
        component: (
          <>
            <div
              className="action-control"
              style={{ zIndex: 1, position: "absolute", padding: "8px" }}
            >
              <Actions
                isFull={true}
                enabledTracking={true}
                handleFullScreen={() => fullsc()}
                handleScaleDown={() => fullsc()}
                searchDisabled={true}
                settingsDisabled={true}
                geofencesProp={{
                  geofences: geofences,
                  geofenceCategories: geofenceCategories,
                  setGeofences: setGeofences,
                }}
                centerLocationProps={{
                  active: true,
                  onClick: centerLocation,
                }}
              />
            </div>
          </>
        ),
      });
      componentsOnMapArray.push({
        selectorName: ".zoom-control-container",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_BOTTOM,
        component: (
          <div
            className="zoom-control-container"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
              marginBottom: "-21px",
            }}
          >
            <Zoom
              handleMinus={() => minusClick()}
              handlePlus={() => plusClick()}
            />
          </div>
        ),
      });

      componentsOnMapArray.push({
        selectorName: ".maptype-control",
        selectorPosition: googleMap.maps.ControlPosition.BOTTOM_RIGHT,
        component: (
          <MapType
            handleMap={() => mapClick()}
            handleSatellite={() => satelliteClick()}
            activeSatellite={preferencesContext.satelliteOnMap}
          />
        ),
      });
      componentsOnMapArray.push({
        selectorName: ".route-button",
        selectorPosition: googleMap.maps.ControlPosition.RIGHT_TOP,
        component: (
          <div
            className="route-button"
            style={{
              zIndex: 1,
              position: "absolute",
              padding: "16px",
              marginBottom: "-21px",
            }}
          >
            <RouteButton onClick={setShowPrevPoints} />
          </div>
        ),
      });
    }
    setComponentsOnMap(componentsOnMapArray);
  }, [map, isMapIdle, geofences]);
  //#endregion

  //#region this method is in charge to manage color of the polyline and the start marker
  useEffect(() => {
    if (trackingPolyline && map) {
      let newColor = isSatellite ? "#00AAFF" : "#0052BD";
      trackingPolyline.setOptions({
        strokeColor: newColor,
      });
      liveTrackingMarker.setComponent(
        <MapMarkerPositionDirection
          hasDirection={true}
          isSatellite={isSatellite}
        />
      );
    }
    if (firstPointMarker) {
      firstPointMarker.setComponent(
        <MapMarkerCustomIcon
          backgroundColor={isSatellite ? "#00AAFF" : "#0052BD"}
          iconSelected={
            <IconLocator size={16} color="--global-colors-ui-white" />
          }
        />
      );
    }
  }, [isSatellite]);

  useEffect(() => {
    if (
      !trackingPolyline ||
      !trackingPolyline.getPath() ||
      _.isEmpty(trackingPoints)
    ) {
      return;
    }
    const path = trackingPolyline.getPath();

    if (showPrevPoints) {
      path.clear();
      trackingPoints.forEach((newPos) => {
        path.push(new google.maps.LatLng(newPos.latitude, newPos.longitude));
      });
      trackingPolyline.setMap(map);

      setFirstPointMarkerFullRoute(
        MarkerAsComponent({
          id: "firstPointMarker",
          googleMap: googleMap,
          lat: trackingPoints[0].latitude,
          lng: trackingPoints[0].longitude,
          map: map,
          component: (
            <MapMarkerCustomIcon
              backgroundColor={isSatellite ? "#00AAFF" : "#0052BD"}
              iconSelected={
                <IconKey size={16} color="--global-colors-ui-white" />
              }
            />
          ),
        })
      );
    } else if (!showPrevPoints && firstPoint) {
      path.clear();
      const filteredTrackingPoints = trackingPoints.filter(
        (point) => point.timestamp >= new Date(firstPoint.timestamp)
      );
      filteredTrackingPoints.forEach((newPos) => {
        path.push(new google.maps.LatLng(newPos.latitude, newPos.longitude));
      });
      trackingPolyline.setMap(map);
      firstPointMarkerFullRoute.setMap(null);
    } else {
      trackingPolyline.setMap(null);
    }
  }, [showPrevPoints]);

  //#region this method is in charge to manage live tracking map animation
  useEffect(() => {
    const animateMap = async () => {
      const googleTrac = window.google;
      let angle = 0;

      if (!_.isEmpty(currentPoint)) {
        lastTrackingPoint.current.latitude = currentPoint.latitude;
        lastTrackingPoint.current.longitude = currentPoint.longitude;

        currentPosition = new googleTrac.maps.LatLng(
          currentPoint.latitude,
          currentPoint.longitude
        );

        // #region manage animation
        // calculate angle (or set last position when current point is the first one)
        if (lastPosition) {
          angle = googleTrac.maps.geometry?.spherical.computeHeading(
            lastPosition,
            currentPosition
          );
          // correction for points with the same gps coords
          if (angle === 0) {
            let distance =
              googleTrac.maps.geometry?.spherical.computeDistanceBetween(
                lastPosition,
                currentPosition
              );
            if (distance === 0) angle = lastHeading;
          }
          // correction for bad verse rotation
          if (angle * lastHeading < 0) {
            // latest and current angle have different sign
            if (Math.abs(angle - lastHeading) > 180) {
              if (angle < 0) angle = angle + 360;
              else angle = angle - 360;
            }
          }
        } else {
          setLastPosition(currentPosition);
        }

        // configure and start camera animation
        let lat =
          lastPosition !== undefined
            ? lastPosition.lat()
            : currentPosition.lat();
        let lng =
          lastPosition !== undefined
            ? lastPosition.lng()
            : currentPosition.lng();

        const cameraOptions = {
          heading: lastHeading,
          center: {
            lat: lat,
            lng: lng,
          },
        };
        const cameraTween = new Tween(cameraOptions)
          .to(
            {
              heading: angle,
              center: {
                lat: currentPosition.lat,
                lng: currentPosition.lng,
              },
            },
            1600
          ) // Move to destination in 1.6 second.
          .easing(Easing.Quadratic.Out) // Use an easing function to make the animation smooth.
          .onUpdate(() => {
            map?.moveCamera(cameraOptions);
          })
          .start();
        const animateCamera = (time: any) => {
          requestAnimationFrame(animateCamera);
          cameraTween.update(time);
        };
        requestAnimationFrame(animateCamera);
        // draw polyline
        if (!trackingPolyline) {
          trackingPolyline = new googleMap.maps.Polyline({
            strokeColor: isSatellite ? "#00AAFF" : "#0052BD",
            strokeOpacity: 1.0,
            strokeWeight: 8,
          });
        }

        // #endregion manage animation
        if (firstPointMarker == undefined && liveTrackingMarker === undefined) {
          const path = trackingPolyline.getPath();
          trackingPoints.map((newPos) =>
            path.push(new google.maps.LatLng(newPos.latitude, newPos.longitude))
          );
          trackingPolyline.setMap(map);
        }

        // draw the latest path
        setTimeout(() => {
          // putting on map the directional marker
          if (liveTrackingMarker === undefined) {
            setLiveTrackingMarker(
              MarkerAsComponent({
                id: "trackingMarker",
                googleMap: googleMap,
                lat: currentPoint.latitude,
                lng: currentPoint.longitude,
                map: map,
                component: (
                  <MapMarkerPositionDirection
                    hasDirection={true}
                    isSatellite={isSatellite}
                  />
                ),
              })
            );
            // building start marker
            if (firstPointMarker == undefined) {
              if (currentPoint != undefined) {
                setFirstPoint(currentPoint);
              }
              setFirstPointMarker(
                MarkerAsComponent({
                  id: "firstPointMarker",
                  googleMap: googleMap,
                  lat: currentPoint.latitude,
                  lng: currentPoint.longitude,
                  map: map,
                  component: (
                    <MapMarkerCustomIcon
                      backgroundColor={isSatellite ? "#00AAFF" : "#0052BD"}
                      iconSelected={
                        <IconLocator
                          size={16}
                          color="--global-colors-ui-white"
                        />
                      }
                    />
                  ),
                })
              );
            }
          } else {
            // this allow to move directional marker to next point
            liveTrackingMarker.setPosition(
              currentPoint.latitude,
              currentPoint.longitude
            );
          }

          // set map center (smooth animation when possible(cached map tiles))
          map?.panTo(currentPosition);

          const path = trackingPolyline.getPath();
          path.push(currentPosition);
          trackingPolyline.setMap(map);
        }, 1650);

        // save current values for next track data
        setLastPosition(currentPosition);
        setLastHeading(angle);
      }
    };

    animateMap().catch(console.error);
  }, [currentPoint, map]);
  //#endregion

  // this method is in charge to disable components on map
  function disableComponent(component: string) {
    switch (component) {
      case "gm-svpc":
        document
          .getElementsByClassName("gm-svpc")[0]
          ?.classList.add("disabled");
        break;
      case "center-location":
        (
          document.getElementsByClassName("center-location")[0] as HTMLElement
        ).style.display = "";
        break;
    }
  }

  // Disable map icons
  if (mapIconsLoaded) {
    disableComponent("gm-svpc");
    disableComponent("center-location");
  }

  //#region marker on map

  const removeGeofenceMarkers = () => {
    if (geofenceMarkers.length !== 0) {
      geofenceMarkers.forEach((geofence: any) => {
        geofence.component.props.children[0].props.circle.setMap(null);
        geofence.setMap(null);
      });
    }
    if (!_.isEmpty(geofenceClusterMarkers)) {
      geofenceClusterMarkers.clearMarkers();
      geofenceClusterMarkers.setMap(null);
      geofenceClusterMarkers = {} as AccessibleMarkerClusterer;
    }
    geofenceMarkers = [];
    visibleMarkers = [];
    nonVisibleMarkers = [];
    setVisibleMarkersHook([]);
    setNonVisibleMarkersHook([]);
  };

  function setGeofences(geofenceOptions: any) {
    removeGeofenceMarkers();
    let markerArray: google.maps.Marker[] = [];
    let bounds = new googleMap.maps.LatLngBounds();

    geofenceOptions.forEach((option: any) => {
      if (option.checked) {
        option.geofences.forEach((geofence: any) => {
          const category = geofenceCategoriesSelectors.selectById(
            store.getState(),
            geofence.geofenceCategory
          );

          markerArray.push(
            MarkerAsComponent({
              googleMap: googleMap,
              id: "customMarker-" + geofence.id,
              lat: geofence.shape.center.lat,
              lng: geofence.shape.center.lng,
              map: map,
              geofence: geofence.name,
              isGeofence: true,
              eventType: option.color,
              status: category?.name,
              component: (
                <>
                  <MapMarkerPin
                    key={geofence.id}
                    color={category?.color ?? "#0052BD"}
                    circle={
                      new googleMap.maps.Circle({
                        strokeColor: option.color,
                        strokeOpacity: 0.8,
                        strokeWeight: 2,
                        fillColor: option.color,
                        fillOpacity: 0.3,
                        map,
                        center: geofence.shape.center,
                        radius: geofence.shape.radius,
                      })
                    }
                  />
                  {geofence.name && (
                    <label className="labelId">{geofence.name}</label>
                  )}
                </>
              ),
            })
          );
          bounds.extend(
            new googleMap.maps.LatLng(
              geofence.shape.center.lat,
              geofence.shape.center.lng
            )
          );
        });
      }
    });
    geofenceMarkers.push(...markerArray);
    setFirstBound();
  }

  //#endregion

  // #region progressive markers loader

  useEffect(() => {
    // Create event listeners for map drag and zoom events
    if (googleMap && map) {
      dragListener && googleMap.maps.event.removeListener(dragListener);
      zoomListener && googleMap.maps.event.removeListener(zoomListener);
      repositioning && googleMap.maps.event.removeListener(repositioning);

      dragListener = googleMap.maps.event.addListener(
        map,
        "dragend",
        updateMarkersOnMap
      );
      zoomListener = googleMap.maps.event.addListener(
        map,
        "zoom_changed",
        updateMarkersOnMap
      );
      repositioning = googleMap.maps.event.addListener(
        map,
        "center_changed",
        updateMarkersOnMap
      );

      // Cleanup function to remove event listeners
      return () => {
        if (googleMap) {
          googleMap.maps.event.removeListener(dragListener);
          googleMap.maps.event.removeListener(zoomListener);
          googleMap.maps.event.removeListener(repositioning); // riposiziona
        }
      };
    }
  }, [map, geofenceMarkers]);

  const setFirstBound = async (): Promise<void> => {
    if (geofenceMarkers.length > 0) {
      // Separazione iniziale dei marker visibili e non visibili
      geofenceMarkers.forEach((marker: any) => {
        if (map.getBounds().contains(marker.getPosition())) {
          visibleMarkers.push(marker);
        } else {
          marker.setMap(null);
          // marker.component.props.children[0].props.circle.setMap(null);
          nonVisibleMarkers.push(marker);
        }
      });

      // Creazione del cluster iniziale solo con i marker visibili
      if (_.isEmpty(geofenceClusterMarkers)) {
        geofenceClusterMarkers = new AccessibleMarkerClusterer({
          map: map,
          markers: visibleMarkers,
          renderer: geofenceRenderer,
        });
      }
      setVisibleMarkersHook([...visibleMarkers]);
      setNonVisibleMarkersHook([...nonVisibleMarkers]);
      await waitForClustersToBeReady();
      updateMarkersOnMap();
    }
  };

  /**
   * @param waitForClustersToBeReady
   * check every 100ms if there are cluster then exit the function
   * @returns
   */
  const waitForClustersToBeReady = (): Promise<void> => {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        const clusters = geofenceClusterMarkers.getClusters();
        if (visibleMarkers.length > 0 && clusters.length > 0) {
          clearInterval(interval);
          resolve();
        }
      }, 100);
    });
  };

  function updateMarkersOnMap(): void {
    const newVisibleMarkers: any[] = [];
    const newNonVisibleMarkers: any[] = [];
    const toAdd: any[] = [];
    const toRemove: any[] = [];

    visibleMarkers.forEach((marker: any) => {
      if (map.getBounds().contains(marker.getPosition())) {
        newVisibleMarkers.push(marker);
      } else {
        newNonVisibleMarkers.push(marker);
        toRemove.push(marker);
        marker.setMap(null);
        marker.component.props.children[0].props.circle.setMap(null);
      }
    });

    nonVisibleMarkers.forEach((nMarker: any) => {
      if (map.getBounds().contains(nMarker.getPosition())) {
        newVisibleMarkers.push(nMarker);
        nMarker.setMap(map);
        toAdd.push(nMarker);
      } else {
        newNonVisibleMarkers.push(nMarker);
      }
    });

    if (!_.isEqual(newVisibleMarkers, visibleMarkersHook)) {
      visibleMarkers = newVisibleMarkers;
      nonVisibleMarkers = newNonVisibleMarkers;

      setVisibleMarkersHook([...visibleMarkers]);
      setNonVisibleMarkersHook([...nonVisibleMarkers]);

      if (toRemove.length > 0) {
        toRemove.forEach((marker) => {
          if (geofenceClusterMarkers.getMarkers().includes(marker)) {
            geofenceClusterMarkers.removeMarker(marker);
          }
        });
      }

      if (toAdd.length > 0) {
        toAdd.forEach((marker) => {
          geofenceClusterMarkers.addMarker(marker);
        });
      }
    }
    if (!_.isEmpty(geofenceClusterMarkers)) {
      const updatedClusters = geofenceClusterMarkers.getClusters();

      // removes all the circle from the map
      geofenceMarkers.forEach((marker: any) => {
        marker.component.props.children[0].props.circle.setMap(null);
      });

      //add new circles on map, only to single markers
      updatedClusters.forEach((cluster) => {
        if (cluster.markers?.length === 1) {
          cluster.markers[0].component.props.children[0].props.circle.setMap(
            map
          );
        }
      });
    }
  }

  //#endregion

  //#region cluster
  const geofenceRenderer = {
    render(__namedParameters: Cluster) {
      const clusterData = _.countBy(__namedParameters.markers, "status");
      return MarkerAsComponent({
        id: "geofenceCluster",
        googleMap: googleMap,
        lat: __namedParameters.position.lat(),
        lng: __namedParameters.position.lng(),
        map: map,
        onClick: () => {
          map.fitBounds(__namedParameters.bounds, 0);
          map.setTilt(55); // 3d
        },
        component: (
          <ClusterMarker
            data={Object.keys(clusterData).map((key) => ({
              status: key ?? "UNKNOWN",
              numbers: clusterData[key],
              color:
                geofenceCategories.find(
                  (item: GeofenceCategory) => item.name === key
                )?.color ?? "#0052BD",
            }))}
            hasChart={true}
            text={`${__namedParameters.markers?.length ?? ""}`}
          />
        ),
      });
    },
  };

  //#endregion

  //#region This methods are used by action component for fullScreen button
  function isFullscreen(element: any) {
    return (
      (document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement) === element
    );
  }

  function requestFullscreen(element: any) {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullScreen) {
      element.msRequestFullScreen();
    }
  }

  function exitFullscreen() {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  const fullsc = () => {
    const elementToSendFullscreen = map?.getDiv().firstChild;
    if (isFullscreen(elementToSendFullscreen)) {
      exitFullscreen();
    } else {
      requestFullscreen(elementToSendFullscreen);
    }
  };
  //#endregion

  //#region This methods are used for zoom e reposition button
  // This method is used by zoom + button for zooming map
  const plusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp + 1);
      }
    }
  };

  // This method is used by zoom - button for zooming map
  const minusClick = () => {
    if (map) {
      const zoomTemp = map.getZoom();
      if (zoomTemp) {
        map.setZoom(zoomTemp - 1);
      }
    }
  };

  const centerLocation = () => {
    map.panTo(currentPosition);
    map.setTilt(55);
  };
  //#endregion

  return (
    <>
      <BaseMap
        id={id}
        googleMapsApiKey={googleMapsApiKey}
        zoom={zoom}
        hasStreetView={hasStreetView}
        latitude={latitude}
        longitude={longitude}
        getMap={setMap}
        getIsMapIdle={setIsMapIdle}
        getMapIconsLoaded={setMapIconsLoaded}
        useDefaultPosition={!liveTrackingMarker}
      >
        {componentsOnMap}
      </BaseMap>
    </>
  );
};
