import ReactTooltip from "react-tooltip";
import "./Tooltip.css";

type TooltipProps = {
  id?: string;
  size?: "regular" | "small";
};

export const Tooltip = (props: TooltipProps) => {
  const { id, size = "small" } = props;
  const tooltipId = id ? id : "tooltip";
  return (
    <ReactTooltip
      id={tooltipId}
      delayShow={0}
      delayUpdate={0}
      delayHide={0}
      className={`tooltip-style__${size}`}
    />
  );
};
