import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import AnalogicReportRepository from "./analogicReportRepository";

export interface SensorData {
  uuid: string;
  vehicleId: number;
  driverId: number;
  address: string;
  date: number;
  ignitionKey: boolean;
  temperature: number;
  batteryLevel: string;
  humidity: number;
  latitude: number;
  longitude: number;
  routeId: number;
  routeType: string;
}

export const getAnalogicReportSensorDataAsync = createAsyncThunk(
  "analogicReport/sensorData/get",
  async (
    data: {
      beaconNamespace: string;
      entityId: string;
      queryParam: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const repository = new AnalogicReportRepository();
      const response = await repository.getSensorData(
        data.beaconNamespace,
        data.entityId,
        data.queryParam
      );
      const sensorData = _.get(
        response,
        Config.REPORTS_ANALOGIC_SENSOR_DATA_RESPONSE_PATH
      );
      return sensorData;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const analogicReportSensorDataAdapter: EntityAdapter<SensorData> =
  createEntityAdapter<SensorData>({
    selectId: (sensorSensorData: SensorData) => sensorSensorData.uuid,
  });

function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload
    ? analogicReportSensorDataAdapter.setAll(state, action.payload)
    : analogicReportSensorDataAdapter.setAll(state, []);
}

export const analogicReportSensorDataSlice = createSlice({
  name: "analogicReportSensorData",
  initialState: analogicReportSensorDataAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    setEmptyState: (state) => {
      analogicReportSensorDataAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAnalogicReportSensorDataAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getAnalogicReportSensorDataAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAnalogicReportSensorDataAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const analogicReportSensorDataSelector =
  analogicReportSensorDataAdapter.getSelectors<RootState>(
    (state) => state.analogicReportSensorData
  );

export const selectAnalogicReportSensorDataSliceStatus = (state: any) =>
  state.analogicReportSensorData.status;

export const selectAnalogicReportSensorDataSliceReasonCode = (state: any) =>
  state.analogicReportSensorData.reasonCode;

export default analogicReportSensorDataSlice.reducer;
