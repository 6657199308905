import HttpClient from "./HttpClient";

export default class UtilsService extends HttpClient {
  constructor() {
    super(true);
  }

  sendValidation(channel?: string, entity?: string, entityId?: string) {
    return this.instance.post(
      "/utils/entities/" +
        entity +
        "/" +
        entityId +
        "/channel/" +
        channel +
        "/validationcode"
    );
  }
}
