import { EntityId } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import PageFilters from "../../layout/PageFilters";
import { PageCounter } from "../../ui/Table/PageCounter";
import { Table } from "../../ui/Table/Table";
import { ToastNotification } from "../../utils/ToastNotification";
import { getQueryString } from "../../utils/Utils";
import { getDriversStatusesAsync } from "../driver/driversStatusSlice";
import {
  FleetView,
  fleetViewsEmptyState,
  fleetViewsSelectors,
  getFleetViewsAsync,
} from "../fleet/fleetViewsSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import { Vehicle, vehiclesSelectors } from "../vehicle/vehiclesSlice";
import { getVehiclesViewAsync } from "../vehicle/vehiclesStatusViewSlice";
import { vehiclesViewEmptyState } from "../vehicle/vehiclesViewSlice";
import "./Maintenance.css";
import { MaintenanceFilterBar } from "./MaintenanceFilterBar";
import { MaintenanceTableBuilder } from "./MaintenenceTableBuilder";
import {
  Deadline,
  deadlinesEmptyState,
  deadlinesSelectors,
  getDeadlinesAsync,
  selectDeadlinesSlicePage,
  selectDeadlinesSliceReasonCode,
  selectDeadlinesSliceStatus,
  selectDeadlinesSliceTotalElements,
} from "./deadlinesSlice";

interface MaintenanceProps {
  permissions: UserPermissions;
}

const context = "maintenanceActions";
interface TableData {
  columns: { label: string; field: string; sort: boolean }[] | undefined;
  rows: { [key: string]: any };
}

const tableData: TableData = {} as TableData;

const tableSchemaFix = [
  { name: "vehicle" },
  { name: "license" },
  { name: "group" },
  { name: "maintenanceDeadlines" },
  { name: "expire" },
  { name: "maintenanceStatus" },
];

export const Maintenance: React.FC<MaintenanceProps> = ({ permissions }) => {
  const navigate = useNavigate();
  const tableBuilderRef = useRef<MaintenanceTableBuilder>(
    new MaintenanceTableBuilder()
  );
  let tableBuilder = tableBuilderRef.current;

  const [queryParamsFromFilterBar, setQueryParamsFromFilterBar] =
    useState<string>("");
  const [resetPage, setResetPage] = useState<boolean>(false);

  const [preferencesContext]: [Preferences] = useContext(UserContext);

  // Deadline data
  dayjs.extend(relativeTime);
  dayjs.locale(preferencesContext.language ?? "en");

  //#region Deadline API
  let deadlinesSliceStatusIds: EntityId[] = useAppSelector((state) =>
    deadlinesSelectors.selectIds(state)
  );

  const deadlines: Deadline[] =
    deadlinesSliceStatusIds.map(
      (x) =>
        deadlinesSelectors.selectById(store.getState(), x) ?? ({} as Deadline)
    ) ?? ([] as Deadline[]);
  const deadlinesSliceStatus = useAppSelector(selectDeadlinesSliceStatus);

  const deadlinesSliceReasonCode = useAppSelector(
    selectDeadlinesSliceReasonCode
  );
  const deadlinesSlicePages = useAppSelector(selectDeadlinesSlicePage);
  const deadlinesSliceTotalElements = useAppSelector(
    selectDeadlinesSliceTotalElements
  );

  const isMaintenanceIdle = deadlinesSliceStatus === "idle";
  const [queryParamsChanged, setQueryParamsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (queryParamsFromFilterBar) {
      setQueryParamsChanged(true);
    }
  }, [queryParamsFromFilterBar]);

  useEffect(() => {
    if (queryParamsChanged && isMaintenanceIdle) {
      setQueryParamsChanged(false);
      setResetPage(true);
    } else {
      setResetPage(false);
    }
  }, [isMaintenanceIdle, queryParamsChanged]);
  //#endregion Deadline API

  //#region Deadline toast notification
  useEffect(() => {
    if (
      deadlinesSliceStatus === "failed" &&
      deadlinesSliceReasonCode ===
        GTFleetErrorCodes.DEADLINE_TO_BE_DELETED_NOT_FOUND
    ) {
      console.error(t("common.deadlineError"));
      ToastNotification({
        toastId: "deadlineError",
        status: "error",
        description: t("common.deadlineError"),
      });
    }

    if (
      deadlinesSliceStatus === "idle" &&
      deadlinesSliceReasonCode === GTFleetSuccessCodes.DELETE
    ) {
      ToastNotification({
        toastId: "deadlineSuccess",
        status: "success",
        description: t("common.deadlineSuccess"),
      });
    }

    if (
      deadlinesSliceStatus === "idle" &&
      deadlinesSliceReasonCode === GTFleetSuccessCodes.POST
    ) {
      ToastNotification({
        toastId: "deadlineMaintenanceSuccess",
        status: "success",
        description: t("common.deadlineMaintenanceSuccess"),
      });
    }

    if (
      deadlinesSliceStatus === "idle" &&
      deadlinesSliceReasonCode === GTFleetSuccessCodes.PATCH
    ) {
      ToastNotification({
        toastId: "deadlineUpdatedSuccess",
        status: "success",
        description: t("common.deadlineMaintenanceUpdated"),
      });
    }
  }, [deadlinesSliceStatus, deadlinesSliceReasonCode]);
  //#endregion Deadline toast notification

  // Chiamata API Drivers Status
  useEffect(() => {
    document.title = t("navigation.userMenu.maintenance");
    if (permissions.drivers.read) {
      store.dispatch(getDriversStatusesAsync());
    }
    if (permissions.fleets.read) {
      store.dispatch(getFleetViewsAsync());
    }
    if (permissions.vehicles.read) {
      store.dispatch(getVehiclesViewAsync());
    }

    /**
     * When the component is unmountend the related redux state should be cleaned.
     */
    return function cleanUp() {
      store.dispatch(fleetViewsEmptyState());
      store.dispatch(vehiclesViewEmptyState());
      store.dispatch(deadlinesEmptyState());
    };
  }, []);

  // loader for PageCount
  const isLoading = deadlinesSliceStatus === "loading";

  //#region Table
  tableData.columns = tableBuilder.setColumns(tableSchemaFix);

  if (
    deadlinesSliceStatus === "idle" &&
    tableData.columns &&
    tableData?.columns?.length > 0 &&
    deadlines.length
  ) {
    tableData.rows = deadlines.map((deadlineStatus: Deadline) => {
      let vehicle: Vehicle =
        vehiclesSelectors.selectById(
          store.getState(),
          deadlineStatus.vehicle ?? -1
        ) ?? ({} as Vehicle);
      let fleetView: FleetView =
        fleetViewsSelectors.selectById(store.getState(), vehicle.fleet ?? -1) ??
        ({} as FleetView);
      return tableBuilder.rowsBuilder(
        tableData.columns,
        context,
        {
          vehicle,
          fleetView,
          deadlineStatus,
        },
        navigate,
        preferencesContext,
        false,
        queryParamsFromFilterBar
      );
    });
  } else if (!deadlines.length) {
    tableData.rows = [];
  }
  //#endregion Table

  return (
    <>
      <PageFilters>
        <div className="col col-16">
          <MaintenanceFilterBar callback={setQueryParamsFromFilterBar} />
        </div>
      </PageFilters>
      {queryParamsFromFilterBar !== "" && tableData.rows?.length > 0 && (
        <PageContent>
          <div className="maintenance-table">
            <Table data={tableData}>
              <Table.Head hasTableSpace={true} />
              <Table.Body />
            </Table>
          </div>
          <div className="maintenance-pagination">
            <PageCounter
              isActionPerforming={isLoading}
              totalElements={deadlinesSliceTotalElements}
              resetPage={resetPage}
              disabled={!isMaintenanceIdle}
              numOfPages={deadlinesSlicePages}
              onClick={(id) => {
                const pageAndSize = getQueryString({
                  page: id - 1,
                  size: "10",
                });
                const finalQueryParams = queryParamsFromFilterBar
                  ? queryParamsFromFilterBar + pageAndSize.replace("?", "&")
                  : pageAndSize;
                store.dispatch(
                  getDeadlinesAsync({ queryParams: finalQueryParams })
                );
              }}
            />
          </div>
        </PageContent>
      )}
    </>
  );
};
