import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { vehiclesSchema } from "./vehicleNormalization";
import VehiclesStatusRepository from "./vehiclesStatusRepository";
import { getVehicleAsync, Sensor } from "./vehiclesStatusSlice";

//#region Type
export type GPSPositions = {
  latitude: number;
  longitude: number;
  gpsPositionTimestamp: string;
  address: string;
};

export const deviceStatusValues = {
  ONLINE: "ONLINE",
  OFFLINE: "OFFLINE",
  NO_SIGNAL: "NO_SIGNAL",
  UNKNOWN: "UNKNOWN",
};
export type DeviceStatusType = keyof typeof deviceStatusValues;

export const statusVehicleType = {
  MOVING: "MOVING",
  STOP: "STOP",
  PARKING: "PARKING",
  UNKNOWN: "UNKNOWN",
  OFFLINE: "OFFLINE",
};
export type StatusVehicleType = keyof typeof statusVehicleType;

export interface VehicleStatusView {
  id: number;
  deviceStatus: DeviceStatusType;
  dynamicFields?: {
    customerId: number;
    deviceId: number;
    direction: number;
    gpsPositions: GPSPositions[];
    ignitionKey: boolean;
    lastUpdate: Date;
    latitude: number;
    longitude: number;
    odometer: number;
    timestamp_t3: Date;
    timestamp_t2: Date;
    timestamp: Date;
    siren: boolean;
    speed: number;
    engineLock: boolean;
    address: string;
    fuelLevel: number;
    fuelLevelLiters: number;
    door: boolean;
    trailerTruck: boolean;
    temperature: number;
    vehicleStatus: StatusVehicleType;
    sos: boolean;
    sensors: Sensor[];
  };
}
//#endregion Type
export const getVehiclesViewAsync = createAsyncThunk(
  "vehicles/getVehiclesView",
  async (queryParams: string | undefined, ThunkAPI) => {
    try {
      const vehiclesStatusRepository = new VehiclesStatusRepository();
      const response = await vehiclesStatusRepository.getVehiclesViews(
        queryParams
      );
      // The value we return becomes the `fulfilled` action payload
      const vehicles = _.get(
        response,
        Config.VEHICLES_STATUS_VIEW_RESPONSE_PATH
      );
      const normalizedData = normalize(vehicles ?? [], vehiclesSchema);
      if (normalizedData?.entities && normalizedData?.entities?.vehicleStatus) {
        let vehiclesStatus: VehicleStatusView[] = [];
        normalizedData.result.forEach((element: number) => {
          const vehicleStatus = _.get(
            normalizedData.entities.vehicleStatus,
            element
          );
          vehiclesStatus.push(vehicleStatus);
        });
        normalizedData.entities.vehicleStatus = vehiclesStatus;
      }
      ThunkAPI.dispatch(setLastUpdate(Date.now()));
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
    }
  }
);

const vehiclesStatusViewAdapter = createEntityAdapter<VehicleStatusView>({
  selectId: (vehicleStatusView) => vehicleStatusView.id,
});

export const vehiclesStatusViewSlice = createSlice({
  name: "vehiclesStatusView",
  initialState: vehiclesStatusViewAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
    lastUpdate: 0,
  }),
  reducers: {
    vehiclesStatusViewEmptyState: (state) => {
      vehiclesStatusViewAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
      state.lastUpdate = 0;
    },
    setLastUpdate: (state, action: PayloadAction<number>) => {
      state.lastUpdate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      .addCase(
        getVehiclesViewAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = "";
        }
      )
      .addCase(getVehiclesViewAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        getVehiclesViewAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicleStatus
            ? vehiclesStatusViewAdapter.setAll(
                state,
                action.payload.vehicleStatus
              )
            : vehiclesStatusViewAdapter.setAll(state, []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      //#endregion Entity Reducers

      //#region External Entity Reducers
      .addCase(
        getVehicleAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          action.payload.vehicleStatus &&
            vehiclesStatusViewAdapter.upsertMany(
              state,
              action.payload.vehicleStatus as VehicleStatusView[]
            );
        }
      );
  },
});
export const vehiclesStatusViewSelectors =
  vehiclesStatusViewAdapter.getSelectors<RootState>(
    (state) => state.vehiclesStatusView
  );

export const selectVehiclesStatusViewSliceStatus = (state: any) =>
  state.vehiclesStatusView.status;
export const selectVehiclesStatusViewSliceReasonCode = (state: any) =>
  state.vehiclesStatusView.reasonCode;
export const selectVehiclesStatusViewSliceLastUpdate = (state: any) =>
  state.vehiclesStatusView.lastUpdate;
export const { vehiclesStatusViewEmptyState, setLastUpdate } =
  vehiclesStatusViewSlice.actions;

export default vehiclesStatusViewSlice.reducer;
