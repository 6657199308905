import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { tenantsSelectors } from "../../../features/tenants/tenantsSlice";
import { Button } from "../../Button/Button";
import { IconPhone } from "../../Icon/Line/Phone";
import { Logo } from "../../Logo/Logo";
import { ModalBody } from "../ModalBody";
import { ModalFooter } from "../ModalFooter";
import { ModalWrapper } from "../ModalWrapper";
import "./InfoModal.css";

export const InfoModal = ({ showInfoModal, onClose, infoModalType }) => {
  const { t } = useTranslation();
  const tenant = useAppSelector(tenantsSelectors.selectAll)[0];

  return (
    <div className="info-modal-container">
      <ModalWrapper open={showInfoModal} closeAction={onClose}>
        <ModalBody
          title={
            infoModalType === "privacy"
              ? t("customModals.infoModal.privacyPolicy.title")
              : infoModalType === "conditions"
              ? t("customModals.infoModal.termsCondition.title")
              : infoModalType === "credits"
              ? t("customModals.infoModal.credits.title")
              : infoModalType === "leftCredits"
              ? t("customModals.infoModal.leftCredits.title")
              : null
          }
          desc=""
          isScrollable={false}
        >
          {infoModalType === "privacy" && (
            <div className="info-modal-privacy-policy">
              <p>{t("customModals.infoModal.privacyPolicy.desc")}</p>

              <p>{t("customModals.infoModal.privacyPolicy.descParagraph")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.privacyPolicy.subtitleOne")}</b>
              </p>

              <p>{t("customModals.infoModal.privacyPolicy.paragraphOne")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.privacyPolicy.subtitleTwo")}</b>
              </p>

              <p>{t("customModals.infoModal.privacyPolicy.paragraphTwo")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.privacyPolicy.subtitleThree")}</b>
              </p>

              <p>{t("customModals.infoModal.privacyPolicy.paragraphThree")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.privacyPolicy.subtitleFour")}</b>
              </p>

              <p>{t("customModals.infoModal.privacyPolicy.paragraphFour")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.privacyPolicy.subtitleFive")}</b>
              </p>

              <p>{t("customModals.infoModal.privacyPolicy.paragraphFive")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.privacyPolicy.subtitleSix")}</b>
              </p>

              <p>{t("customModals.infoModal.privacyPolicy.paragraphSix")}</p>
            </div>
          )}{" "}
          {infoModalType === "conditions" && (
            <div className="info-modal-terms-conditions">
              <p>
                <b>{t("customModals.infoModal.termsCondition.gtFleet")}</b>
                {t("customModals.infoModal.termsCondition.desc")}
              </p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.termsCondition.subtitleOne")}</b>
              </p>

              <p>{t("customModals.infoModal.termsCondition.paragraphOne")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.termsCondition.subtitleTwo")}</b>
              </p>

              <p>{t("customModals.infoModal.termsCondition.paragraphTwo")}</p>

              <p className="subtitle">
                <b>
                  {t("customModals.infoModal.termsCondition.subtitleThree")}
                </b>
              </p>

              <p>{t("customModals.infoModal.termsCondition.paragraphThree")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.termsCondition.subtitleFour")}</b>
              </p>

              <p>{t("customModals.infoModal.termsCondition.paragraphFour")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.termsCondition.subtitleFive")}</b>
              </p>

              <p>{t("customModals.infoModal.termsCondition.paragraphFive")}</p>

              <p className="subtitle">
                <b>{t("customModals.infoModal.termsCondition.subtitleSix")}</b>
              </p>

              <p>{t("customModals.infoModal.termsCondition.paragraphSix")}</p>
            </div>
          )}{" "}
          {infoModalType === "credits" && (
            <>
              <div className="logo-macnil">
                <Logo type="macnil" />
              </div>
              <div className="info-modal-credits">
                <p>{t("customModals.infoModal.credits.paragraphOne")}</p>
                <p>{t("customModals.infoModal.credits.paragraphTwo")}</p>

                <div className="phone-number">
                  {t("customModals.infoModal.credits.phone")} +39 0802464245
                </div>
                <div className="e-mail">
                  <span>e-mail: </span>
                  <a
                    href="https://www.macnil.it/contatti/richiedi-info/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@macnil.it
                  </a>
                </div>
                <div className="link">
                  <span>
                    <a
                      href="https://www.macnil.it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      macnil.it
                    </a>
                  </span>
                  <span>|</span>
                  <span>
                    <a
                      href="https://gtalarm.it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      gtalarm.it
                    </a>
                  </span>
                </div>
              </div>
            </>
          )}{" "}
          {infoModalType === "leftCredits" && (
            <>
              <div className="logo-macnil">
                <Logo type="macnil" />
              </div>
              <div className="info-modal-credits">
                <p>{t("customModals.infoModal.leftCredits.paragraphOne")}</p>
                <p>{t("customModals.infoModal.leftCredits.paragraphTwo")}</p>
                <Button
                  aspect="secondary"
                  size="regular"
                  label={t("common.customerService")}
                  onClick={() => {
                    {
                      const fullName = localStorage.getItem("fullName") || "";
                      const message = encodeURIComponent(
                        t("common.whatsappCredit")
                          .replace("*fullName", fullName)
                          .replace("*companyName", tenant.name)
                      );
                      window.open(
                        "https://wa.me/+390802464245?text=" + message,
                        "_blank"
                      );
                    }
                  }}
                >
                  <IconPhone
                    className=""
                    size={14}
                    color="--global-colors-ink-light"
                  />
                </Button>
                <div className="phone-number">
                  {t("customModals.infoModal.leftCredits.phone")} +39 0802464245
                </div>
                <div className="e-mail">
                  <span>e-mail: </span>
                  <a
                    href="https://www.macnil.it/contatti/richiedi-info/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    info@macnil.it
                  </a>
                </div>
                <div className="link">
                  <span>
                    <a
                      href="https://www.macnil.it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      macnil.it
                    </a>
                  </span>
                  <span>|</span>
                  <span>
                    <a
                      href="https://gtalarm.it/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      gtalarm.it
                    </a>
                  </span>
                </div>
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter
          secondaryLabel={t(
            "customModals.infoModal.leftCredits.secondaryButton"
          )}
          secondaryAction={() => {
            onClose();
          }}
        />
      </ModalWrapper>
    </div>
  );
};

InfoModal.propTypes = {
  showInfoModal: PropTypes.bool,
  onClose: PropTypes.func,
  infoModalType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf(["privacy", "conditions", "credits"]),
  ]).isRequired,
};
