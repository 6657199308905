import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { HubSpotWidget } from "./HSChat";
import "./HSChat.css";
import { store } from "./app/store";
import LoginPage from "./features/LoginPage/LoginPage";
import Multiview from "./features/MultiViewPage/Multiview";
import SidebarPage from "./features/SidebarPage/SidebarPage";
import { getFilteredNotificationsPreviewAsync } from "./features/notification/notificationPreviewSlice";
import PreferencesManager from "./features/users/preference/PreferencesManager";
import {
  Preferences,
  preferencesEmptyState,
} from "./features/users/preference/preferencesSlice";
import UserContext from "./features/users/userContext";
import {
  deleteFbToken,
  getFbToken,
  onMessageListener,
} from "./firebase/push-notification";
import FirebaseService from "./utils/FirebaseService";
import { ToastNotification } from "./utils/ToastNotification";
import Validation from "./validation/Validation";

interface HubSpotCredential {
  email: string;
  token: string;
}

interface FirebaseCredential {
  username: string;
  firebaseToken: string;
}

function App() {
  let queryparams = new URLSearchParams(window.location.search);
  let isKeyParam = queryparams.has("key");

  const [hubSpotCredential, setHubSpotCredential] = useState({
    email: localStorage.getItem("tenantEmail"),
    token: localStorage.getItem("chatToken"),
  } as HubSpotCredential);
  const [firebaseCredential, setFirebaseCredential] = useState({
    username: localStorage.getItem("username"),
    firebaseToken: localStorage.getItem("firebaseToken"),
  } as FirebaseCredential);

  const [auth, setAuth] = useState(
    isKeyParam ||
      localStorage.length < 10 ||
      localStorage.getItem("jwtToken") === null ||
      localStorage.getItem("refreshToken") === null ||
      localStorage.getItem("tenantId") === null ||
      localStorage.getItem("preferencesId") === null
  );

  const firebaseService = new FirebaseService();
  const today = dayjs().utcOffset(0, true);
  const threeMonthsAgo = today.subtract(3, "months");

  const formattedToday = today.toISOString();
  const formattedThreeMonthsAgo = threeMonthsAgo.toISOString();

  const queryParams = `?sendDate=${encodeURIComponent(
    formattedThreeMonthsAgo
  )}&sendDate=${encodeURIComponent(formattedToday)}&size=1000&read=false`;

  const usernameFromLocalStorage = localStorage.getItem("username");

  const getTitleFromNotification = (text: string): string => {
    const httpsIndex = text.indexOf("https");
    const title = httpsIndex !== -1 ? text.slice(0, httpsIndex) : text;
    return title;
  };

  if (onMessageListener) {
    onMessageListener()
      .then((payload) => {
        if (payload && !_.isEmpty(payload)) {
          console.log("Notification payload: ", payload);
          let usernameTemp = "";
          if (!!payload?.data && !!payload?.data?.recipientAlias) {
            usernameTemp = payload.data.recipientAlias;
          } else if (
            !!payload?.notification &&
            !!payload?.notification?.recipientAlias
          ) {
            usernameTemp = payload.notification.recipientAlias;
          }
          if (_.isEqual(usernameTemp, usernameFromLocalStorage)) {
            ToastNotification({
              toastId: "notificationToast",
              status: "default",
              title:
                payload.data !== null
                  ? payload.data.title
                  : payload.notification.title,
              description:
                payload.data !== null
                  ? payload.data.body.includes("https")
                    ? getTitleFromNotification(payload.data.body)
                    : payload.data.body
                  : payload.notification.body,
              redirectToDashboard: !!payload.data.body.includes("https"),
            });
            store.dispatch(
              getFilteredNotificationsPreviewAsync({ queryParams: queryParams })
            );
            console.log(payload);
          }
        }
      })
      .catch((err) => console.log("failed: ", err));
  }

  const loginDone = () => {
    const currentEmail = localStorage.getItem("tenantEmail");
    const currentChatToken = localStorage.getItem("chatToken");

    if (
      currentChatToken &&
      currentEmail &&
      currentEmail !== hubSpotCredential.email
    ) {
      setHubSpotCredential({
        email: currentEmail,
        token: currentChatToken,
      } as HubSpotCredential);
    }

    if (
      ((_.isNull(firebaseCredential.firebaseToken) ||
        _.isEmpty(firebaseCredential.firebaseToken)) &&
        process.env.REACT_APP_DEPLOY_TARGET === "local") ||
      process.env.REACT_APP_DEPLOY_TARGET === "prod" ||
      process.env.REACT_APP_DEPLOY_TARGET === "pre-prod"
    ) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          getFbToken().then((firebaseToken) => {
            firebaseService.firebaseTokenRegister(firebaseToken!);

            setFirebaseCredential({
              username: localStorage.getItem("username"),
              firebaseToken: firebaseToken,
            } as FirebaseCredential);
          });
        } else {
          console.log("Notification permission denied.");
          ToastNotification({
            toastId: "notificationPermissionErrorToast",
            status: "error",
            title: "Notification Sending Error",
            description:
              "Please allow notification permission to receive push notifications",
          });
        }
      });
    }
    setAuth(false);
  };

  const logoutDone = () => {
    document.title = "GT Fleet";
    deleteFbToken();
    setHubSpotCredential({ email: "", token: "" } as HubSpotCredential);
    setFirebaseCredential({
      username: "",
      firebaseToken: "",
    } as FirebaseCredential);
    setAuth(true);
    store.dispatch(preferencesEmptyState());
  };

  let preferences = useState<Preferences>({} as Preferences);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/validation" element={<Validation />} />
        <Route
          path="/*"
          element={
            auth ? (
              <LoginPage loginDone={loginDone} />
            ) : (
              <UserContext.Provider value={preferences}>
                <div className="toast-wrapper">
                  <ToastContainer theme="colored" />
                </div>
                <PreferencesManager>
                  <Routes>
                    <Route
                      path="/*"
                      element={<SidebarPage logoutDone={logoutDone} />}
                    />
                    <Route path="/multiview/*" element={<Multiview />} />
                  </Routes>
                </PreferencesManager>
              </UserContext.Provider>
            )
          }
        />
      </Routes>

      <HubSpotWidget
        email={hubSpotCredential.email}
        token={hubSpotCredential.token}
        accountId={process.env.REACT_APP_HS_ACCOUNT_ID}
      />
    </BrowserRouter>
  );
}

export default App;
