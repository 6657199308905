import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { normalize } from "normalizr";
import { RootState } from "../app/store";
import { Config } from "../config/Config";
import { GTFleetSuccessCodes } from "../config/GTFleetSuccessCodes";
import { RecipientType } from "../features/messages/chatAliasSlice";
import { ChannelType } from "../features/notification/rule/notificationRuleAggregationSlice";
import AuthenticationService from "../utils/AuthenticationService";
import { getErrorCodes } from "../utils/Utils";
import UtilsService from "../utils/UtilsSlice";
import { validationSchema } from "./validationNormalization";

export const ValidationChannelEnum = {
  EMAIL: "EMAIL",
  PHONE: "PHONE",
};
export type ValidationChannelType = keyof typeof ValidationChannelEnum;

//#region Type
export type ValidationType = {
  validationDate: Date;
  validationCode: string;
  entityId: number;
  channelType: ValidationChannelType;
  entityType: RecipientType;
  recipient: [
    {
      type: ChannelType;
      address: string;
      externalMessageId: string;
      alias: string;
    }
  ];
};
//#endregion Type

//#region API
// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const sendValidationAsync = createAsyncThunk(
  "validation/sendValidation",
  async (
    data: { channel: string; entity: string; entityId: string },
    { rejectWithValue }
  ) => {
    try {
      const utilsService = new UtilsService();
      const response = await utilsService.sendValidation(
        data.channel,
        data.entity,
        data.entityId
      );
      const validation = _.get(response, Config.VALIDATION_RESPONSE_PATH);
      const normalizedData = normalize(validation, validationSchema);
      return normalizedData.entities;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);

export const validateAsync = createAsyncThunk(
  "validation/validate",
  async (
    data: {
      channel: string;
      entity: string;
      entityId: string;
      validationCode: string;
      language: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const authenticationService = new AuthenticationService();
      const response = await authenticationService.validate(
        data.channel,
        data.entity,
        data.entityId,
        data.validationCode,
        data.language
      );
      const validation = _.get(response, Config.VALIDATION_RESPONSE_PATH);
      return validation;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response.data);
    }
  }
);
//#endregion API

//#region Custom Functions
function setFilteredData(state: any, action: PayloadAction<any>) {
  action.payload?.validation
    ? validationsAdapter.setAll(state, action.payload.validation)
    : validationsAdapter.setAll(state, []);
}
//#endregiorn Custom Functions

//#region Slice
const validationsAdapter = createEntityAdapter<ValidationType>({
  selectId: (validations) => validations.entityId,
});

export const validationsSlice = createSlice({
  name: "validationsSlice",
  initialState: validationsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    validationsEmptyState: (state: any) => {
      validationsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
    emptyStatusAndReasonCode: (state: any) => {
      state.reasonCode = "";
      state.status = "idle";
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      //#region Entity Reducers
      // getRoutesHistoryAsync extra reducers
      .addCase(
        sendValidationAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )
      .addCase(sendValidationAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(sendValidationAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })
      .addCase(
        validateAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.PUT;
        }
      )
      .addCase(validateAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(
        validateAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          state.reasonCode = getErrorCodes(action.payload.message);
        }
      );
    //#endregion Entity Reducers
  },
});
//#endregion Slice

//#region Status
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const validationsSelectors = validationsAdapter.getSelectors<RootState>(
  (state) => state.validations
);

export const selectValidationsSliceStatus = (state: any) =>
  state.validations.status;
export const selectValidationsSliceReasonCode = (state: any) =>
  state.validations.reasonCode;
export const { validationsEmptyState, emptyStatusAndReasonCode } =
  validationsSlice.actions;

//#endregion Status

export default validationsSlice.reducer;
