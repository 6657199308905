import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { DropdownButton, ListItem } from "../../../../ui/Button/DropdownButton";
import { Tooltip } from "../../../../ui/Forms/Tooltip";
import { Check } from "../../../../ui/Indicators/Check";
import {
  AbstractTableBuilder,
  Row,
} from "../../../../ui/Table/utils/AbstractTableBuilder";
import { AddressBook } from "../../../address/addressBookSlice";

export class ContactListModalTableBuilder extends AbstractTableBuilder {
  rowsBuilder(
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    context: string,
    entries: {
      addressBook?: AddressBook;
    },
    navigate: NavigateFunction,
    writePermission: boolean = false
  ): any {
    let row: Row = { render: {} as Row };

    let addressBook: AddressBook = entries.addressBook ?? ({} as AddressBook);

    columns?.forEach((column) => {
      switch (column.field) {
        case "firstName":
          row.render.firstName =
            addressBook.firstName && addressBook.lastName
              ? addressBook.firstName + " " + addressBook.lastName
              : t("common.na");
          break;
        case "email":
          let emailContent = addressBook.email ? (
            <>{addressBook.email}</>
          ) : (
            <>{t("common.na")}</>
          );
          if (!addressBook.emailValidated) {
            emailContent = (
              <div className="contact-list-validation-check">
                <Tooltip />
                <span
                  data-for="tooltip"
                  data-tooltip-delay-hide={1000}
                  data-tip={t("validation.notValidated")}
                >
                  <Check
                    status="failed"
                    size="small"
                    onClick={() =>
                      navigate(
                        "/admin/notifications/validate-contact/" +
                          addressBook.id
                      )
                    }
                  />
                </span>
                <div className="contact-list-validation-text">
                  {emailContent}
                </div>
              </div>
            );
          } else {
            emailContent = (
              <div className="contact-list-validation-check">
                <Check status="success" size="small" />
                <div className="contact-list-validation-text">
                  {emailContent}
                </div>
              </div>
            );
          }
          row.render.email = <>{emailContent}</>;
          break;
        case "telephone":
          let telephoneContent = addressBook.telephone ? (
            <>{addressBook.telephone}</>
          ) : (
            <>{t("common.na")}</>
          );
          if (!addressBook.numberValidated) {
            telephoneContent = (
              <div className="contact-list-validation-check">
                <Tooltip />
                <span
                  data-for="tooltip"
                  data-tooltip-delay-hide={1000}
                  data-tip={t("validation.notValidated")}
                >
                  <Check
                    status="failed"
                    size="small"
                    onClick={() =>
                      navigate(
                        "/admin/notifications/validate-contact/" +
                          addressBook.id
                      )
                    }
                  />
                </span>
                <div className="contact-list-validation-text">
                  {telephoneContent}
                </div>
              </div>
            );
          } else {
            telephoneContent = (
              <div className="contact-list-validation-check">
                <Check status="success" size="small" />
                <div className="contact-list-validation-text">
                  {telephoneContent}
                </div>
              </div>
            );
          }
          row.render.telephone = <>{telephoneContent}</>;
          break;
        default:
          break;
      }
    });

    const addressBookDropDownList: ListItem[] = [
      {
        id: 0,
        title: t("admin.notifications.modal.edit"),
        onClick: () => {
          navigate("/admin/notifications/edit-contact/" + addressBook.id);
        },
      },
      {
        id: 1,
        title: t("admin.notifications.modal.valid"),
        onClick: () => {
          navigate("/admin/notifications/validate-contact/" + addressBook.id);
        },
      },
      {
        id: 2,
        title: t("admin.notifications.modal.delete"),
        onClick: () => {
          navigate("/admin/notifications/delete-contact/" + addressBook.id);
        },
      },
    ];
    if (!writePermission) {
      addressBookDropDownList.splice(0, 0);
    }

    row.render.action = (
      <DropdownButton
        aspect="ghost"
        size="small"
        list={addressBookDropDownList}
      />
    );
    return row;
  }
}
