import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import AnalogicReportRepository from "./analogicReportRepository";

export interface SensorSummary {
  uuid: string;
  namespace: string;
  name: string;
  date: Date;
  kilometersTraveled: number;
  parkingTime: number;
  numberEvent: number;
  avgTemperature: number;
  maxTemperature: number;
  minTemperature: number;
  avgHumidity: number;
  maxHumidity: number;
  minHumidity: number;
}

export const getAnalogicReportSummaryAsync = createAsyncThunk(
  "analogicReport/summary/get",
  async (
    data: {
      beaconNamespace: string;
      entityId: string;
      queryParam: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const repository = new AnalogicReportRepository();
      const response = await repository.getReportSummary(
        data.beaconNamespace,
        data.entityId,
        data.queryParam
      );
      const summary = _.get(
        response,
        Config.REPORTS_ANALOGIC_SUMMARY_RESPONSE_PATH
      );
      return summary;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const analogicReportSummaryAdapter: EntityAdapter<SensorSummary> =
  createEntityAdapter<SensorSummary>({
    selectId: (sensorSummary: SensorSummary) => sensorSummary.uuid,
  });

function setFilteredData(state: any, action: PayloadAction<any>) {
  action
    ? analogicReportSummaryAdapter.setAll(state, action.payload)
    : analogicReportSummaryAdapter.setAll(state, []);
}

export const analogicReportSummarySlice = createSlice({
  name: "analogicReportSummaries",
  initialState: analogicReportSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    setEmptyState: (state) => {
      analogicReportSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAnalogicReportSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getAnalogicReportSummaryAsync.pending, (state: any) => {
        state.status = "loading";
        analogicReportSummaryAdapter.setAll(state, []);
      })
      .addCase(getAnalogicReportSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const analogicReportSummarySelector =
  analogicReportSummaryAdapter.getSelectors<RootState>(
    (state) => state.analogicReportSummaries
  );

export const selectAnalogicReportSummarySliceStatus = (state: any) =>
  state.analogicReportSummaries.status;

export const selectAnalogicReportSummarySliceReasonCode = (state: any) =>
  state.analogicReportSummaries.reasonCode;

export default analogicReportSummarySlice.reducer;
