import { t } from "i18next";
import { NavigateFunction } from "react-router-dom";
import { Driver, driverStatusValues } from "../../features/driver/driversSlice";
import { DriverStatus } from "../../features/driver/driversStatusSlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import { Vehicle } from "../../features/vehicle/vehiclesSlice";
import { DropdownButton } from "../../ui/Button/DropdownButton";
import { LabelText } from "../../ui/LabelText/LabelText";
import { Table } from "../../ui/Table/Table";
import {
  AbstractTableBuilder,
  Row,
} from "../../ui/Table/utils/AbstractTableBuilder";
import "../../ui/Table/utils/TableBuilder.css";
import { ConvertTimeZone } from "../../utils/DateAndTimeUtils";
import { FleetView } from "../fleet/fleetViewsSlice";

export class AdminDriversTableBuilder extends AbstractTableBuilder {
  rowsBuilder = (
    columns: { label: string; field: string; sort: boolean }[] | undefined,
    entities: {
      vehicle?: Vehicle;
      driver?: Driver;
      driverStatus?: DriverStatus;
      fleetView?: FleetView;
    },
    preferencesContext: Preferences,
    navigate: NavigateFunction,
    writePermission: boolean = false
  ) => {
    let row: Row = { render: {} as Row };

    let vehicle: Vehicle = entities.vehicle ?? ({} as Vehicle);

    let driver: Driver = entities.driver ?? ({} as Driver);

    let driverStatus: DriverStatus =
      entities.driverStatus ?? ({} as DriverStatus);

    let fleetView: FleetView = entities.fleetView ?? ({} as FleetView);

    columns?.forEach((column) => {
      switch (column.field) {
        case "vehicle":
          row.vehicle = vehicle.alias ?? t("common.na");
          let expirationContent = (
            <LabelText
              label={vehicle.alias ?? t("common.na")}
              text={
                vehicle.brand
                  ? vehicle.brand + " " + vehicle.model
                  : t("common.na")
              }
            />
          );

          row.render.expiration = <>{expirationContent}</>;

          break;
        case "driver":
          row.driver = driver.id ?? t("common.na");
          row.render.driver = (
            <LabelText
              label={driver.id ? driver.id + "" : t("common.na")}
              text={
                driver.firstName
                  ? driver.firstName + " " + driver.lastName
                  : t("common.na")
              }
            />
          );
          break;
        case "driverId":
          row.driverId = driver.id ?? t("common.na");
          row.render.driverId = driver.id ?? t("common.na");
          break;
        case "group":
          row.group = fleetView.name ?? t("common.na");
          row.render.group = fleetView.name ?? t("common.na");
          break;
        case "tachograph":
          row.render.tachograph = driver.tachographCard ?? t("common.na");
          break;
        case "mac":
          row.render.mac = driver?.mac ?? t("common.na");
          break;
        case "driverStatus":
          row.render.driverStatus = (
            <Table.TextStatus
              text={t(`table.status.${driver.status}`) ?? t("common.na")}
            />
          );
          break;
        case "lastActivity":
          row.render.lastActivity = driverStatus?.dynamicFields?.lastUpdate
            ? ConvertTimeZone(
                driverStatus.dynamicFields?.lastUpdate,
                preferencesContext.timeZone ?? undefined,
                preferencesContext.localeFormat ?? undefined
              )
            : t("common.na");
          break;
        default:
          break;
      }
    });

    const driverDropDownList = [
      {
        id: 0,
        title: t("common.view"),
        onClick: () => navigate("/admin/drivers/view/" + driver.id),
      },
      {
        id: 1,
        title: t("common.edit"),
        onClick: () => navigate("/admin/drivers/edit/" + driver.id),
      },
      {
        id: 2,
        title: t("common.actionOption.deactivate"),
        onClick: () => {
          /*initial state is empty*/
        },
      },
    ];

    if (driver.status && driver.status === driverStatusValues.ACTIVE) {
      driverDropDownList[2].title = t("common.actionOption.deactivate");
      driverDropDownList[2].onClick = () => {
        navigate("/admin/drivers/deactivate/" + driver.id);
      };
    } else {
      driverDropDownList[2].title = t("common.actionOption.activate");
      driverDropDownList[2].onClick = () => {
        navigate("/admin/drivers/activate/" + driver.id);
      };
    }
    if (!writePermission) {
      // hide edit and activate/deactivate
      driverDropDownList.splice(1, 2);
    }
    row.render.action = (
      <DropdownButton aspect="ghost" size="small" list={driverDropDownList} />
    );

    return row;
  };
}
