import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../../app/store";
import { Config } from "../../../config/Config";
import { GTFleetSuccessCodes } from "../../../config/GTFleetSuccessCodes";
import AnalogicReportRepository from "./analogicReportRepository";

export interface MacroSummarySensor {
  uuid: string;
  kilometersTraveled: number;
  parkingTime: number;
  numberOfDrivers: number;
  numberOfAlerts: number;
}

export const getAnalogicReportMacroSummaryAsync = createAsyncThunk(
  "analogicReport/macroSummary/get",
  async (
    data: {
      entityId: string;
      queryParam: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const repository = new AnalogicReportRepository();
      const response = await repository.getReportMacroSummary(
        data.entityId,
        data.queryParam
      );
      const summary = _.get(
        response,
        Config.REPORTS_ANALOGIC_MACRO_SUMMARY_RESPONSE_PATH
      );
      return summary;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

export const analogicReportMacroSummaryAdapter: EntityAdapter<MacroSummarySensor> =
  createEntityAdapter<MacroSummarySensor>({
    selectId: (sensorMacroSummary: MacroSummarySensor) =>
      sensorMacroSummary.uuid,
  });

function setFilteredData(state: any, action: PayloadAction<any>) {
  action
    ? analogicReportMacroSummaryAdapter.upsertOne(state, action.payload)
    : analogicReportMacroSummaryAdapter.setAll(state, []);
}

export const analogicReportMacroSummarySlice = createSlice({
  name: "analogicReportSummaries",
  initialState: analogicReportMacroSummaryAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),
  reducers: {
    setEmptyState: (state) => {
      analogicReportMacroSummaryAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAnalogicReportMacroSummaryAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          setFilteredData(state, action);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )
      .addCase(getAnalogicReportMacroSummaryAsync.pending, (state: any) => {
        state.status = "loading";
      })
      .addCase(getAnalogicReportMacroSummaryAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      });
  },
});

export const analogicReportMacroSummarySelector =
  analogicReportMacroSummaryAdapter.getSelectors<RootState>(
    (state) => state.analogicReportMacroSummaries
  );

export const selectAnalogicReportMacroSummarySliceStatus = (state: any) =>
  state.analogicReportMacroSummaries.status;

export const selectAnalogicReportMacroSummarySliceReasonCode = (state: any) =>
  state.analogicReportMacroSummaries.reasonCode;

export default analogicReportMacroSummarySlice.reducer;
