import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { RootState } from "../../app/store";
import { Config } from "../../config/Config";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import { GTFleetSuccessCodes } from "../../config/GTFleetSuccessCodes";
import { getErrorCodes } from "../../utils/Utils";
import BeaconThresholdRepository from "./beaconThresholdRepository";

export type BeaconThreshold = {
  id: number;
  name: string;
  maxTemperature: number;
  minTemperature: number;
  maxHumidity: number;
  minHumidity: number;
};

export const getBeaconsThresholdsAsync = createAsyncThunk(
  "beaconThresholds/getThresholds",
  async (data: { queryParams?: string }, { dispatch, rejectWithValue }) => {
    try {
      const beaconThresholdRepository = new BeaconThresholdRepository();
      const response = await beaconThresholdRepository.getThresholds(
        data.queryParams
      );
      const beaconThresholds = _.get(response, Config.BEACONS_RESPONSE_PATH);

      return beaconThresholds;
    } catch (error: any) {
      if (!error.response) throw error;

      return rejectWithValue(error.response.status);
    }
  }
);

export const createBeaconThresholdAsync = createAsyncThunk(
  "beaconThresholds/createThreshold",
  async (data: { beaconThreshold: BeaconThreshold }, { rejectWithValue }) => {
    try {
      const beaconThresholdRepository = new BeaconThresholdRepository();
      const beaconThreshold = await beaconThresholdRepository.createThreshold(
        data.beaconThreshold
      );

      return beaconThreshold;
    } catch (error: any) {
      if (!error.response) throw error;

      return rejectWithValue(error.response);
    }
  }
);

export const updateBeaconThresholdAsync = createAsyncThunk(
  "beaconThresholds/updateThreshold",
  async (data: { beaconThreshold: BeaconThreshold }, { rejectWithValue }) => {
    try {
      const beaconThresholdRepository = new BeaconThresholdRepository();
      const response = await beaconThresholdRepository.updateThreshold(
        data.beaconThreshold
      );

      return response;
    } catch (error: any) {
      if (!error.response) throw error;

      return rejectWithValue(error.response);
    }
  }
);

export const deleteBeaconThresholdAsync = createAsyncThunk(
  "beaconThresholds/deleteThreshold",
  async (data: { thresholdId: number }, { rejectWithValue }) => {
    try {
      const beaconThresholdRepository = new BeaconThresholdRepository();
      await beaconThresholdRepository.deleteThreshold(data.thresholdId);
      return data.thresholdId;
    } catch (error: any) {
      if (!error.response) throw error;
      return rejectWithValue(error.response);
    }
  }
);

//#region Custom Functions

function handleFailedData(state: any, action: PayloadAction<any>) {
  let castAction: any = action;
  if (castAction?.payload?.data?.message) {
    state.reasonCode = getErrorCodes(castAction?.payload?.data?.message);
  }

  if (castAction?.payload?.status && castAction?.payload?.status === 500) {
    state.reasonCode = GTFleetErrorCodes.INTERNAL_SERVER_ERROR;
  }
}

//#endregion Custom Functions

const beaconThresholdsAdapter = createEntityAdapter<BeaconThreshold>({
  selectId: (beaconThreshold) => beaconThreshold.id,
});

export const beaconThresholdSlice = createSlice({
  name: "beaconThresholdSlice",
  initialState: beaconThresholdsAdapter.getInitialState({
    status: "idle",
    reasonCode: "",
  }),

  reducers: {
    beaconThresholdEmptyState: (state) => {
      beaconThresholdsAdapter.setAll(state, []);
      state.reasonCode = "";
      state.status = "idle";
    },
  },

  extraReducers: (builder) => {
    builder

      .addCase(
        getBeaconsThresholdsAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          beaconThresholdsAdapter.upsertMany(state, action.payload ?? []);
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.GET;
        }
      )

      .addCase(getBeaconsThresholdsAsync.pending, (state: any) => {
        state.status = "loading";
      })

      .addCase(getBeaconsThresholdsAsync.rejected, (state: any) => {
        state.status = "failed";
        state.reasonCode = "";
      })

      .addCase(
        createBeaconThresholdAsync.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          beaconThresholdsAdapter.upsertMany(
            state,
            action.payload.beaconThreshold ?? []
          );
          state.status = "idle";
          state.reasonCode = GTFleetSuccessCodes.POST;
        }
      )

      .addCase(createBeaconThresholdAsync.pending, (state: any) => {
        state.status = "loading";
      })

      .addCase(
        createBeaconThresholdAsync.rejected,
        (state: any, action: PayloadAction<any>) => {
          state.status = "failed";
          handleFailedData(state, action);
        }
      );
  },
});

export const beaconThresholdsSelectors =
  beaconThresholdsAdapter.getSelectors<RootState>(
    (state) => state.beaconThresholds
  );

export const selectBeaconThresholdsSliceStatus = (state: any) =>
  state.beaconThresholds.status;

export const selectBeaconThresholdsReasonCode = (state: any) =>
  state.beaconThresholds.reasonCode;

export const { beaconThresholdEmptyState } = beaconThresholdSlice.actions;
export default beaconThresholdSlice.reducer;
